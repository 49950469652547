import { VisaClassType } from "@codegen/schema";

export const mapMaybe = <T, F extends (x: T) => any>(
  x: T | undefined | null,
  f: F
): ReturnType<F> | undefined => {
  if (x === undefined || x === null) return undefined;
  return f(x);
};

export const visaClassMap: Record<VisaClassType, string> = {
  O_1A: "O-1A",
  EB_1A: "EB-1A",
  TN: "TN",
  H_1B: "H-1B",
  EB_2_NIW: "EB-2 NIW",
};
