import React, { createContext, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
type ReadOnlyContextType = {
  isReadOnly: boolean;
};
const ReadOnlyContext = createContext<ReadOnlyContextType>({
  isReadOnly: false,
});
export const useReadOnlyMode = () => useContext(ReadOnlyContext);
export const ReadOnlyModeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [searchParams] = useSearchParams();
  const [isReadOnly, setIsReadOnly] = useState(false);
  useEffect(() => {
    const readOnlyParam = searchParams.get("readOnly");
    setIsReadOnly(readOnlyParam === "true");
  }, [searchParams]);
  return (
    <ReadOnlyContext.Provider value={{ isReadOnly }}>
      {children}
    </ReadOnlyContext.Provider>
  );
};
