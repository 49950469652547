const PlymouthIcon = (props: { className?: string }) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 31 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M30.9804 12.5306C30.9804 6.02932 26.7681 0 18.1666 0H0.0196533V40.9663H6.40294V6.01752H17.1165C21.9777 6.01752 24.3729 8.7667 24.3729 12.7548C24.3729 12.7548 25.423 22.2648 6.40294 22.2648V23.7515C6.40294 23.7515 30.9804 29.4622 30.9804 12.5188V12.5306Z"
        fill="#0C76F3"
      />
    </svg>
  );
};

export default PlymouthIcon;
