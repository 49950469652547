import { Button } from "@components/button";
import { FormLabel } from "@components/form";
import { Input } from "@components/input";
import { useReadOnlyMode } from "@components/readOnlyModeProvider";
import Spinner from "@components/spinner";
import { ErrorMessage } from "@hookform/error-message";
import { zodResolver } from "@hookform/resolvers/zod";
import { CTAContainer } from "@pages/onboarding/shared/cta-container";
import { FormErrorMessage } from "@pages/onboarding/shared/error";
import { FieldsContainer } from "@pages/onboarding/shared/fields-container";
import { OnboardingContainer } from "@pages/onboarding/shared/onboarding-container";
import { OnboardingPageType } from "@pages/onboarding/types";
import { X } from "@phosphor-icons/react";
import { useDebounceCallback } from "@react-hook/debounce";
import * as React from "react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import { z } from "zod";

export const fileFormSchema = z.object({
  links: z.string().url().array().min(1, "Required"),
});

export type FileFormType = z.infer<typeof fileFormSchema>;
export const LinksForm: React.FC<
  OnboardingPageType & {
    title: string;
    subtitle: React.ReactNode;
    cardTitle: React.ReactNode;
  }
> = ({
  onBack,
  onSubmit,
  autoSave,
  onSkip,
  onSkipForNow,
  isSaving,
  title,
  subtitle,
  cardTitle,
  footnote,
  exhibit,
}) => {
  const { required, data } = exhibit;
  const { links }: { links?: string[] } = (data as FileFormType) ?? {
    links: [""],
  };

  const { isReadOnly } = useReadOnlyMode();

  const methods = useForm({
    defaultValues: {
      links,
    },
    resolver: zodResolver(fileFormSchema),
  });

  const { formState, control } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    // @ts-ignore
    name: "links",
  });

  const watchedValues = useWatch({
    control,
  });

  const handleSave = (data?: FileFormType) => {
    autoSave(data);
  };
  const handleSubmit = (data?: FileFormType) => {
    onSubmit(data);
  };

  const debouncedSave = useDebounceCallback(handleSave, 300);

  React.useEffect(() => {
    debouncedSave(watchedValues);
  }, [watchedValues, debouncedSave]);

  React.useEffect(() => {
    if (fields.length < 1) {
      append("");
    }
  }, [fields, append]);

  return (
    <FormProvider {...methods}>
      <OnboardingContainer
        title={title}
        subtitle={subtitle}
        cardTitle={cardTitle}
        progress={0}
        onSubmit={handleSubmit}
        exhibit={exhibit}
        name={`links-form`}
        footnote={footnote}
      >
        <FieldsContainer status={""}>
          <div className="">
            <div>
              <Button variant="link" className="pl-0" disabled={isReadOnly}>
                Links
              </Button>
            </div>
            <div className="flex flex-col gap-3">
              {fields.map((field, index) => (
                <div
                  key={field.id}
                  className="rounded-xl border border-dashed border-black/50 bg-onboarding p-3 relative"
                >
                  <div>
                    <FormLabel className="text-primary">
                      Link {index + 1}
                    </FormLabel>
                    <Controller
                      name={`links.${index}`}
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          disabled={isReadOnly}
                          className="w-full"
                          type="text"
                          placeholder="https://www.google.com"
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={formState.errors}
                      name={`links.${index}`}
                      render={({ message }) => (
                        <FormErrorMessage>{message}</FormErrorMessage>
                      )}
                    />
                  </div>
                  <button
                    disabled={isReadOnly}
                    onClick={() => remove(index)}
                    className="absolute top-0 right-0 p-2"
                  >
                    <X />
                  </button>
                </div>
              ))}
            </div>
            <Button
              type="button"
              variant={"link"}
              className="self-start text-accent px-0"
              onClick={() => append("")}
              disabled={isReadOnly}
            >
              Add more link
            </Button>
          </div>
          <ErrorMessage
            errors={formState.errors}
            name="links"
            render={({ message }) => (
              <FormErrorMessage>{message}</FormErrorMessage>
            )}
          />
        </FieldsContainer>
        <CTAContainer
          onBack={onBack}
          onSkip={required ? () => onSkipForNow?.() : onSkip}
        >
          <Button
            variant="accent"
            className="ml-auto text-md rounded-sm px-7 py-5"
            // disabled={!formState.isValid || formState.isValidating}
            type="submit"
          >
            {isSaving ? <Spinner /> : "Next"}
          </Button>
        </CTAContainer>
      </OnboardingContainer>
    </FormProvider>
  );
};

export const MajorPublicationWorkLinks: React.FC<OnboardingPageType> = (
  props
) => {
  const { exhibit, module } = props;
  const { name } = exhibit;
  const { name: moduleName } = module;

  return (
    <LinksForm
      {...props}
      subtitle={name}
      cardTitle={"Major Publication Work Links"}
      title={`${moduleName}${name ? ` - ${name}` : ""}`}
    />
  );
};
export const JournalArticleForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props;
  const { name } = exhibit;
  const { name: moduleName } = module;

  return (
    <LinksForm
      {...props}
      subtitle={name}
      cardTitle={"Journal Articles"}
      title={`${moduleName}${name ? ` - ${name}` : ""}`}
    />
  );
};

export const LinkToEventForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props;
  const { name } = exhibit;
  const { name: moduleName } = module;

  return (
    <LinksForm
      {...props}
      subtitle={""}
      cardTitle={"A link to the event where you participated as a judge."}
      title={`${moduleName}${name ? ` - ${name}` : ""}`}
    />
  );
};
