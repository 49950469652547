import {
  useGetUserDatumByIdQuery,
  useUserDatumUpdatesMutation,
} from "@codegen/index";
import { UpdateUserDatumByIdInput } from "@codegen/schema";
import { Button } from "@components/button";
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from "@components/form";
import CountrySelect from "@components/formCountrySelect";
import { Input } from "@components/input";
import Spinner from "@components/spinner";
import { ErrorMessage } from "@hookform/error-message";
import { zodResolver } from "@hookform/resolvers/zod";
import { CTAContainer } from "@pages/onboarding/shared/cta-container";
import { FormErrorMessage } from "@pages/onboarding/shared/error";
import { FieldsContainer } from "@pages/onboarding/shared/fields-container";
import { OnboardingContainer } from "@pages/onboarding/shared/onboarding-container";
import { OnboardingPageType } from "@pages/onboarding/types";
import { useLogError } from "@utils/error";
import React from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { z } from "zod";
import { FormLoading } from "./loading";
import { useDebounceCallback } from "@react-hook/debounce";
import { useReadOnlyMode } from "@components/readOnlyModeProvider";

const usAddressSchema = z.discriminatedUnion("doNotHaveUSAddress", [
  z.object({ doNotHaveUSAddress: z.literal(true) }),
  z.object({
    doNotHaveUSAddress: z.literal(false),
    street: z.string().min(1, { message: "Required" }),
    street2: z.string().optional(),
    city: z.string().min(1, { message: "Required" }),
    province: z.string().min(1, { message: "Required" }),
    country: z.string().min(1, { message: "Required" }),
    postalCode: z.string().min(1, { message: "Required" }),
  }),
]);

const foreignAddressSchema = z.object({
  doNotHaveUSAddress: z.literal(undefined),
  street: z.string().min(1, { message: "Required" }),
  street2: z.string().optional(),
  city: z.string().min(1, { message: "Required" }),
  province: z.string().min(1, { message: "Required" }),
  country: z.string().min(1, { message: "Required" }),
  postalCode: z.string().min(1, { message: "Required" }),
});

type USAddressValues = z.infer<typeof usAddressSchema>;
type ForeignAddressValues = z.infer<typeof foreignAddressSchema>;
type BaseAddressValues = z.infer<typeof foreignAddressSchema>;
type AddressFormPageType = {
  addressType: "us" | "foreign";
  title: string;
  subtitle: string;
  cardTitle: string;
  addresses?: BaseAddressValues[];
  refetch: () => void;
};
export const AddressForm: React.FC<
  OnboardingPageType & AddressFormPageType
> = ({
  onSubmit,
  autoSave,
  onBack,
  contact,
  addressType,
  title,
  subtitle,
  cardTitle,
  exhibit,
  refetch,
  addresses = [],
  onSkipForNow,
}) => {
  const isUSAddress = addressType === "us";
  const addressSchema = isUSAddress ? usAddressSchema : foreignAddressSchema;
  const logError = useLogError();
  const [updateUserData, { loading: saving }] = useUserDatumUpdatesMutation();
  const { isReadOnly } = useReadOnlyMode();

  const { usAddress, foreignAddress } = React.useMemo(() => {
    const usAddressObj = addresses?.find(
      (addr: BaseAddressValues) => addr.country === "US"
    );
    const foreignAddressObj = addresses?.find(
      (addr: BaseAddressValues) => addr.country !== "US"
    );
    return {
      usAddress: usAddressObj,
      foreignAddress: foreignAddressObj,
    };
  }, [addresses]);

  const defaultValues = React.useMemo(() => {
    return {
      ...(isUSAddress ? usAddress : foreignAddress),
      doNotHaveUSAddress: undefined,
    };
  }, [isUSAddress, usAddress, foreignAddress]);

  const methods = useForm({
    defaultValues,
    resolver: zodResolver(addressSchema),
  });
  const { register, formState, control, setValue, watch, trigger } = methods;
  const doNotHaveUSAddress = isUSAddress
    ? watch("doNotHaveUSAddress")
    : undefined;
  const selectedCountry = watch("country");

  const watchedValues = useWatch({
    control,
  });

  const handleSave = React.useCallback(
    async (data: USAddressValues | ForeignAddressValues) => {
      try {
        const doesNotHaveUSAddress = data.doNotHaveUSAddress === true;
        let newAddress = !doesNotHaveUSAddress
          ? {
              ...data,
              createdAt: new Date().toISOString(),
            }
          : undefined;

        if (newAddress && newAddress.hasOwnProperty("doNotHaveUSAddress")) {
          delete newAddress.doNotHaveUSAddress;
        }
        let newAddresses = newAddress ? [newAddress, ...addresses] : addresses;
        let payload = {
          addresses: JSON.stringify(newAddresses),
        } as UpdateUserDatumByIdInput["userDatumPatch"];

        if (newAddress) {
          if (isUSAddress) {
            payload = {
              ...payload,
              streetNumberStreetNameUsResidentialAddress: newAddress.street,
              cityUsResidentialAddress: newAddress.city,
              stateUsResidentialAddress: newAddress.province,
              zipCodeUsResidentialAddress: newAddress.postalCode,
            };
          } else {
            payload = {
              ...payload,
              streetNumberStreetNameForeignAddress: newAddress.street,
              cityForeignAddress: newAddress.city,
              provinceForeignAddress: newAddress.province,
              postalCodeForeignAddress: newAddress.postalCode,
              countryForeignAddress: newAddress.country,
            } as UpdateUserDatumByIdInput["userDatumPatch"];
          }
        }

        const { errors: updateUserError } = await updateUserData({
          variables: {
            input: {
              id: contact.id,
              clientMutationId: "",
              userDatumPatch: {
                ...payload,
              },
            },
          },
        });
        if (updateUserError != null) {
          throw updateUserError;
        }
        refetch();
        autoSave(data);
      } catch (exception) {
        logError(exception, {
          message: "Error saving user address data",
        });
      }
    },
    [contact, addressType, updateUserData, onSubmit, refetch, addresses]
  );

  const debouncedSave = useDebounceCallback(handleSave, 300);

  React.useEffect(() => {
    debouncedSave(watchedValues);
  }, [watchedValues, debouncedSave]);

  const handleSubmit = React.useCallback(
    async (data: USAddressValues | ForeignAddressValues) => {
      try {
        const doesNotHaveUSAddress = data.doNotHaveUSAddress === true;
        let newAddress = !doesNotHaveUSAddress
          ? {
              ...data,
              createdAt: new Date().toISOString(),
            }
          : undefined;

        if (newAddress && newAddress.hasOwnProperty("doNotHaveUSAddress")) {
          delete newAddress.doNotHaveUSAddress;
        }
        let newAddresses = newAddress ? [newAddress, ...addresses] : addresses;
        let payload = {
          addresses: JSON.stringify(newAddresses),
        } as UpdateUserDatumByIdInput["userDatumPatch"];

        if (newAddress) {
          if (isUSAddress) {
            payload = {
              ...payload,
              streetNumberStreetNameUsResidentialAddress: newAddress.street,
              cityUsResidentialAddress: newAddress.city,
              stateUsResidentialAddress: newAddress.province,
              zipCodeUsResidentialAddress: newAddress.postalCode,
            };
          } else {
            payload = {
              ...payload,
              streetNumberStreetNameForeignAddress: newAddress.street,
              cityForeignAddress: newAddress.city,
              provinceForeignAddress: newAddress.province,
              postalCodeForeignAddress: newAddress.postalCode,
              countryForeignAddress: newAddress.country,
            } as UpdateUserDatumByIdInput["userDatumPatch"];
          }
        }

        const { errors: updateUserError } = await updateUserData({
          variables: {
            input: {
              id: contact.id,
              clientMutationId: "",
              userDatumPatch: {
                ...payload,
              },
            },
          },
        });
        if (updateUserError != null) {
          throw updateUserError;
        }
        refetch();
        onSubmit(data);
      } catch (exception) {
        logError(exception, {
          message: "Error saving user address data",
        });
      }
    },
    [contact, addressType, updateUserData, onSubmit, refetch, addresses]
  );

  React.useEffect(() => {
    if (doNotHaveUSAddress && isUSAddress) {
      trigger();
    }
  }, [doNotHaveUSAddress, trigger, isUSAddress]);

  return (
    <FormProvider {...methods}>
      <OnboardingContainer
        title={title}
        subtitle={subtitle}
        cardTitle={cardTitle}
        progress={0}
        onSubmit={handleSubmit}
        exhibit={exhibit}
        name={`${addressType}-address-form`}
      >
        <FieldsContainer status={""}>
          <FormItem>
            <FormControl>
              <Input
                key={`${addressType}-street-${exhibit.id}`}
                {...register("street")}
                className="h-11"
                placeholder="123 Main St."
                disabled={doNotHaveUSAddress || isReadOnly}
              />
            </FormControl>
            <ErrorMessage
              errors={formState.errors}
              name="street"
              render={({ message }) => (
                <FormErrorMessage>{message}</FormErrorMessage>
              )}
            />
          </FormItem>
          <FormItem>
            <FormControl>
              <Input
                {...register("street2")}
                className="h-11"
                placeholder="Apt 2"
                disabled={doNotHaveUSAddress || isReadOnly}
              />
            </FormControl>
            <ErrorMessage
              errors={formState.errors}
              name="street2"
              render={({ message }) => (
                <FormErrorMessage>{message}</FormErrorMessage>
              )}
            />
          </FormItem>
          <FormItem>
            <FormControl>
              <Input
                {...register("city")}
                className="h-11"
                placeholder="City"
                disabled={doNotHaveUSAddress || isReadOnly}
              />
            </FormControl>
            <ErrorMessage
              errors={formState.errors}
              name="city"
              render={({ message }) => (
                <FormErrorMessage>{message}</FormErrorMessage>
              )}
            />
          </FormItem>
          <div className="flex gap-3">
            <FormItem className="w-1/2">
              <FormControl>
                <Input
                  {...register("province")}
                  className="h-11 w-full"
                  placeholder={isUSAddress ? "State" : "Province"}
                  disabled={doNotHaveUSAddress || isReadOnly}
                />
              </FormControl>
              <ErrorMessage
                errors={formState.errors}
                name="province"
                render={({ message }) => (
                  <FormErrorMessage>{message}</FormErrorMessage>
                )}
              />
            </FormItem>
            <FormItem className="w-1/2">
              <CountrySelect
                field={{ value: selectedCountry ?? "" }}
                onSelect={(x: string) => setValue("country", x)}
                disabled={doNotHaveUSAddress || isReadOnly}
              />
              <ErrorMessage
                errors={formState.errors}
                name="country"
                render={({ message }) => (
                  <FormErrorMessage>{message}</FormErrorMessage>
                )}
              />
            </FormItem>
          </div>
          <FormItem>
            <FormControl>
              <Input
                {...register("postalCode")}
                className="h-11"
                placeholder="Postal code"
                disabled={doNotHaveUSAddress || isReadOnly}
              />
            </FormControl>
            <ErrorMessage
              errors={formState.errors}
              name="postalCode"
              render={({ message }) => (
                <FormErrorMessage>{message}</FormErrorMessage>
              )}
            />
          </FormItem>
          {addressType === "us" ? (
            <FormItem>
              <FormItem>
                <FormLabel
                  className="flex items-center gap-2 text-md text-primary"
                  htmlFor="doNotHaveUSAddress"
                >
                  <input
                    id="doNotHaveUSAddress"
                    {...register("doNotHaveUSAddress")}
                    placeholder="I do not have a US address"
                    type="checkbox"
                    disabled={isReadOnly}
                  />{" "}
                  I do not have a U.S. address
                </FormLabel>
                <FormMessage />
              </FormItem>
            </FormItem>
          ) : undefined}
          <p className="text-sm text-muted-foreground">
            *We need this information for your immigration forms.
          </p>
        </FieldsContainer>

        <CTAContainer onBack={onBack} onSkip={() => onSkipForNow?.()}>
          <Button
            variant="accent"
            className="ml-auto text-md rounded-sm px-7 py-5"
            disabled={saving || formState.isValidating}
            type="submit"
          >
            {saving ? <Spinner /> : "Next"}
          </Button>
        </CTAContainer>
      </OnboardingContainer>
    </FormProvider>
  );
};

const AddressFromController: React.FC<
  OnboardingPageType & Omit<AddressFormPageType, "refetch">
> = (props) => {
  const {
    data: userData,
    refetch,
    loading,
  } = useGetUserDatumByIdQuery({
    variables: {
      id: props.contact.id,
    },
  });

  const addresses =
    typeof userData?.userDatumById?.addresses === "string"
      ? JSON.parse(userData?.userDatumById?.addresses)
      : [];

  if (loading || !userData) {
    return <FormLoading />;
  }

  return <AddressForm {...props} addresses={addresses} refetch={refetch} />;
};

export const ForeignAddressForm: React.FC<OnboardingPageType> = (props) => {
  return (
    <AddressFromController
      addressType="foreign"
      {...props}
      title={`What’s your "home" address outside the U.S.?`}
      subtitle="This should be an address that you consider your permanent address outside the U.S.*"
      cardTitle="Address outside of the U.S."
    />
  );
};

export const USAddressForm: React.FC<OnboardingPageType> = (props) => {
  return (
    <AddressFromController
      addressType="us"
      {...props}
      title="Do you have a U.S. address?"
      subtitle="If so, what is it? (If not, no worries!)"
      cardTitle="US address"
    />
  );
};
