import { WorkflowModule } from "@codegen/enums";
import { LoadingTasks } from "@pages/onboarding/shared/loading";
import { useBeneficiaryWorkflowContext } from "@pages/onboarding/workflows/provider";
import {
  BookOpenUser,
  Briefcase,
  CheckFat,
  Clipboard,
  CurrencyCircleDollar,
  FileText,
  IdentificationBadge,
  Lightbulb,
  Newspaper,
  PenNib,
  RocketLaunch,
  Scales,
  Square,
  Stamp,
  Trophy,
  UserCircle,
} from "@phosphor-icons/react";
import { cn } from "@utils/cn";
import { StrategyModule } from "@utils/types";
import * as changeCase from "change-case";
import * as React from "react";
import { Link } from "react-router-dom";

type ModuleListType = {
  id: string;
  title: string;
  subtitle: string;
  icon: typeof UserCircle;
  type: StrategyModule["type"];
};

const copy: ModuleListType[] = [
  {
    id: "1",
    type: "PERSONAL",
    title: "Personal Information",
    subtitle: "Provide your basic personal details.",
    icon: IdentificationBadge,
  },
  {
    id: "2",
    type: "IDENTIFICATION",
    title: "Identification Documents",
    subtitle: "Submit your passport and visa details.",
    icon: Stamp,
  },
  {
    id: "3",
    type: "EMPLOYMENT",
    title: "Professional Details",
    subtitle: "Enter your employment history.",
    icon: Briefcase,
  },
  {
    id: "4",
    type: "FILING_TYPE",
    title: "Filing Type",
    subtitle:
      "Specify your filing type: Change of Status or Consular Processing.",
    icon: Clipboard,
  },
  {
    id: "5",
    type: "HIGH_REMUNERATION",
    title: "High Remuneration",
    subtitle: "Submit details of past, current, and future high-paying jobs.",
    icon: CurrencyCircleDollar,
  },
  {
    id: "6",
    type: "CRITICAL_ROLE",
    title: "Critical Role at Distinguished Organization",
    subtitle: `Share evidence of a critical  role in organizations with a stellar reputation.`,
    icon: RocketLaunch,
  },
  {
    id: "7",
    type: "MEMBERSHIP",
    title: "Memberships",
    subtitle: "Share your memberships in exclusive professional associations.",
    icon: BookOpenUser,
  },
  {
    id: "8",
    type: "AUTHORSHIP",
    title: "Authorship",
    subtitle:
      "Submit evidence of your contributions as an author of significant publications.",
    icon: FileText,
  },
  {
    id: "9",
    type: "JUDGING",
    title: "Judging",
    subtitle: "Show evidence of your judging roles and expertise.",
    icon: Scales,
  },
  {
    id: "10",
    type: "AWARDS",
    title: "Awards",
    subtitle: "Share your notable awards and recognitions.",
    icon: Trophy,
  },
  {
    id: "11",
    type: "ORIGINAL_CONTRIBUTION",
    title: "Original Contributions",
    subtitle: "Showcase your groundbreaking work and innovations.",
    icon: Lightbulb,
  },
  {
    id: "12",
    type: "PRESS",
    title: "Press",
    subtitle: "Share press links or publications about you.",
    icon: Newspaper,
  },
  {
    id: "13",
    type: "EXPERT_LETTERS",
    title: "Expert Letters",
    subtitle: "Provide letters of recommendation from experts.",
    icon: PenNib,
  },
];

const Item = ({ status, type }: StrategyModule) => {
  const placeholder = copy.find((i) => i.type === type);
  const Icon = placeholder?.icon;
  const title = placeholder?.title;
  const subtitle = placeholder?.subtitle;
  const isCompleted = status === "completed";
  const isInprgress = status === "in_progress";

  return (
    <Link
      to={`/onboarding/beneficiary/${changeCase.kebabCase(type)}`}
      className={cn(
        "flex gap-3 items-center border rounded-sm p-3 bg-card hover:bg-gray-50 hover:cursor-pointer",
        isCompleted && `border shadow border-blue-600`,
        isInprgress && "border-yellow-300 border shadow-xl"
      )}
    >
      {isCompleted ? (
        <CheckFat weight="fill" size={24} className={`text-blue-600`} />
      ) : (
        <Square weight="regular" size={24} className="text-gray-300" />
      )}
      {Icon && (
        <Icon
          weight="duotone"
          size={40}
          className={cn("text-accent", isCompleted && `text-blue-600`)}
        />
      )}
      <div className="flex justify-between w-full items-center">
        <div className="">
          <div className="font-semibold text-primary">{title}</div>
          <div className="text-secondary-foreground">{subtitle}</div>
        </div>
        <div
          className={cn(
            "text-gray-400",
            isCompleted && `text-blue-600 font-semibold`
          )}
        >
          {changeCase.capitalCase(status)}
        </div>
      </div>
    </Link>
  );
};

export const OnboardingFollowup = () => {
  const { onboarding, refetchOnboarding } = useBeneficiaryWorkflowContext();

  React.useEffect(() => {
    refetchOnboarding();
  }, []);

  const modules: WorkflowModule[] = onboarding?.modules
    ? JSON.parse(onboarding.modules)
    : [];

  return (
    <div className="">
      <div className="flex flex-col h-screen overflow-scroll w-full relative">
        <div className="md:flex gap-8">
          <div className="flex-1 mx-auto max-w-3xl py-10 px-3">
            <h1 className="text-4xl mb-3">
              Please complete the following tasks
            </h1>
            <p className="mb-6 text-primary">
              Complete your onboarding by providing essential details across key
              modules. Each section is designed to capture crucial information
              to support your petition.
            </p>

            {modules.length > 0 ? (
              <ul className="flex flex-col gap-6">
                {modules
                  .filter((m) => m.type !== "MEMO_STARTER")
                  .filter((m) => ["pending", "in_progress"].includes(m.status))
                  .map((item: WorkflowModule) => (
                    <Item key={item.id} {...item} />
                  ))}
              </ul>
            ) : (
              <LoadingTasks count={5} />
            )}
          </div>
          <div className="hidden md:flex w-[350px] shadow p-6 items-center justify-center flex-col h-screen sticky top-0">
            <div className="absolute inset-0 bg-gradient-to-br from-sky-100 via-rose-100 to-lime-100 blur-md"></div>
            <div className="relative z-10 flex flex-col gap-2 items-center">
              <img
                className="rounded-full w-12 h-12 shadow-md"
                src="https://ca.slack-edge.com/T052H6ADCN7-U052HA6Q6AW-bd19ebc72343-512"
                title="Picture of Lisa"
              />
              <h1 className="text-lg font-semibold">Visa question?</h1>
              <h2 className="text-lg mb-5">Lisa has an answer for you.</h2>
              {/* <p className="text-primary">Lisa can answer any question you have about your petition.</p> */}
              <a
                href="mailto:operations@plymouthstreet.com"
                className="font-semibold bg-accent text-accent-foreground px-5 py-2 rounded-sm text-sm hover:bg-blue-500"
              >
                Ask Lisa
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
