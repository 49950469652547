import {
  useAllModuleTypesQuery,
  useAllUserCaseStrategyQuery,
  useGetUserByIdLazyQuery,
  useUpdateCaseStrategyByIdMutation,
} from "@codegen/index";
import {
  CaseStrategy,
  EntityTypes,
  ExhibitOwnerType,
  GetUserByIdQuery,
  UpdateCaseStrategyByIdInput,
  VisaClassType,
} from "@codegen/schema";
import BuilderNavigator from "@components/adminPageNavigator";
import { Button } from "@components/button";
import CompanySelector from "@components/companySelector";
import { PlymouthSelect, SelectItem } from "@components/creator/components";
import ModuleCard from "@components/creator/moduleCard";
import { CreatorModule } from "@components/creator/types";
import EntitiesCreatorModal from "@components/entitiesCreator";
import ErrorBoundary from "@components/error-boundary";
import { Input } from "@components/input";
import Spinner from "@components/spinner";
import UserSelector from "@components/userSelector";
import VisaClassSelector from "@components/visaClassSelector";
import { CaretDown, CaretUp, Info, Plus } from "@phosphor-icons/react";
import { useDebounceCallback } from "@react-hook/debounce";
import { useThrottleCallback } from "@react-hook/throttle";
import * as Sentry from "@sentry/react";
import { api } from "@utils/api";
import { Archetype, moduleTemplatesByArchetype } from "@utils/archetypes";
import { cn } from "@utils/cn";
import { useLogError } from "@utils/error";
import { useJwt } from "@utils/hooks";
import { PetitionCreatorPayload } from "@utils/types";
import * as changeCase from "change-case";
import { format } from "date-fns-tz";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
const PetitionCreator = () => {
  const token = useJwt();
  const nav = useNavigate();
  const [searchParams] = useSearchParams();
  const visaTypeParam = searchParams.get("visaType");
  const petitionerIdParam = searchParams.get("petitionerId");
  const beneficiaryIdParam = searchParams.get("beneficiaryId");
  const archetypeParam = searchParams.get("archetype");
  const caseStrategyIdParam = searchParams.get("caseStrategyId");
  const isCaseStrategyBuildParam = searchParams.get("isCaseStrategy");
  const isCaseStrategyBuild = isCaseStrategyBuildParam === "true";
  const [selectedUser, setSelectedUser] = useState<
    GetUserByIdQuery["userById"] | undefined
  >();
  const { data: caseStrategyData, refetch } = useAllUserCaseStrategyQuery();

  const [headerHidden, setHeaderHidden] = useState<boolean>(false);
  const [metadata, setMetadata] = useState<{
    filingType: string;
    fieldOfEndeavor: string;
    proposedUSSalary: string;
    proposedUSRole: string;
    worksiteLocation: string;
  }>({
    filingType: "",
    fieldOfEndeavor: "",
    proposedUSSalary: "",
    proposedUSRole: "",
    worksiteLocation: "",
  });
  const [petitionInfo, setPetitionInfo] = useState({
    beneficiaryId: "",
    petitionerId: petitionerIdParam,
    visaClassId: visaTypeParam,
    archetype: archetypeParam,
    metadata,
  });

  const logError = useLogError();
  const [modules, setModules] = useState<CreatorModule[]>([]);
  const [createState, setCreateState] = useState<"idle" | "loading" | "error">(
    "idle"
  );
  const [selectedStrategy, setSelectedStrategy] = useState<
    | Pick<
        CaseStrategy,
        | "id"
        | "nodeId"
        | "userId"
        | "userEmail"
        | "strategy"
        | "label"
        | "updatedAt"
      >
    | undefined
  >();

  const invalidPetition = useMemo(() => {
    const base =
      modules.length === 0 ||
      petitionInfo.beneficiaryId === "" ||
      petitionInfo.petitionerId === "" ||
      petitionInfo.visaClassId === "";

    if (base) return true;

    const nullOrEmpty = (x: string | undefined) => x == null || x.trim() === "";

    for (const module of modules) {
      if (module.type === "conclusion") continue;
      if (module.type === "memo-starter") continue;

      if (module.type === "authorship") {
        if (module.autogen) {
          if (
            [
              nullOrEmpty(module.data?.google_scholar_url as string),
              nullOrEmpty(module.data?.num_articles as string),
            ].some((x) => x)
          )
            return true;
        }
      }

      if (!module?.exhibits?.length) return false;

      for (const exhibit of module.exhibits) {
        if (exhibit.autogen) {
          if (exhibit.type === "press-article") {
            if (nullOrEmpty(exhibit.data?.url as string)) return true;
          }

          if (exhibit.type === "distinguished-reputation") {
            if (nullOrEmpty(exhibit.data?.company as string)) return true;
          }

          if (exhibit.type === "patent") {
            if (
              nullOrEmpty(exhibit.data?.key as string) ||
              nullOrEmpty(exhibit.data?.value as string)
            )
              return true;
          }
        }
      }
    }

    return false;
  }, [modules, petitionInfo]);

  const doCreatePetition = useCallback(async () => {
    if (invalidPetition) return;

    setCreateState("loading");

    const id = await api.petitionCreator.createPetition(
      {
        beneficiaryId: petitionInfo.beneficiaryId,
        petitionerId: petitionInfo.petitionerId,
        visaClass: petitionInfo.visaClassId as VisaClassType,
        metadata: JSON.stringify({
          ...metadata,
          proposedUsRole: metadata.proposedUSRole,
          proposedRoleLocation: metadata.worksiteLocation,
        }),
        modules,
      } as PetitionCreatorPayload,
      token
    );

    if (id == null) {
      setCreateState("error");
      return;
    }
    setCreateState("idle");
    nav(`/admin/petition-builder/${id}`);
  }, [
    invalidPetition,
    petitionInfo.beneficiaryId,
    petitionInfo.petitionerId,
    petitionInfo.visaClassId,
    modules,
    token,
    nav,
    metadata,
  ]);

  const [
    updateCaseStrategy,
    { error: updateCaseStrategyError, loading: savingCaseStrategy },
  ] = useUpdateCaseStrategyByIdMutation();
  const saveCaseStrategy = useThrottleCallback(
    async (
      e?:
        | React.MouseEvent<HTMLButtonElement>
        | React.FocusEvent<HTMLInputElement>
    ) => {
      e?.preventDefault();
      try {
        if (selectedStrategy) {
          const strategyJSON = JSON.parse(selectedStrategy.strategy ?? "{}");
          const patch = {
            ...strategyJSON,
            ...metadata,
            beneficiaryId: petitionInfo.beneficiaryId,
            petitionerId: petitionInfo.petitionerId,
            visaClass: petitionInfo.visaClassId as VisaClassType,
            modules: modules,
          };
          console.log("patch", patch);
          const response = await updateCaseStrategy({
            variables: {
              input: {
                id: selectedStrategy.id,
                caseStrategyPatch: {
                  userId: petitionInfo.beneficiaryId
                    ? petitionInfo.beneficiaryId
                    : null,
                  strategy: JSON.stringify(patch),
                },
              } as UpdateCaseStrategyByIdInput,
            },
          });

          if (response.errors) {
            throw response.errors;
          }

          await refetch();
          if (response.data?.updateCaseStrategyById?.caseStrategy) {
            setSelectedStrategy(
              response.data.updateCaseStrategyById.caseStrategy
            );
          }
        }
      } catch (error) {
        Sentry.captureException(error, {
          extra: {
            petitionInfo,
          },
        });
      }
    },
    300
  );
  const onStrategySelected = useCallback(
    (selectedStrategyId: string) => {
      try {
        const newSelectedStrategy =
          caseStrategyData?.allCaseStrategies?.nodes?.find(
            (item) => item?.id === parseInt(selectedStrategyId)
          );
        if (newSelectedStrategy) {
          const strategy = JSON.parse(newSelectedStrategy?.strategy ?? "{}");
          const {
            modules,
            petitionerId,
            beneficiaryId,
            visaClass,
            proposedUSSalary,
            fieldOfEndeavor,
            worksiteLocation,
            filingType,
          } = strategy;
          setSelectedStrategy(newSelectedStrategy);
          if (modules) {
            setModules(modules);
            setPetitionInfo({
              ...petitionInfo,
              ...{
                petitionerId,
                beneficiaryId,
                visaClassId: visaClass,
              },
            });
            setMetadata({
              filingType,
              fieldOfEndeavor,
              proposedUSSalary,
              worksiteLocation,
            });
          } else {
            throw "Case strategy has no modules";
          }
        }
      } catch (error) {
        logError(error, {
          extra: {
            selectedStrategyId,
          },
        });
      }
    },
    [caseStrategyData?.allCaseStrategies?.nodes, setModules]
  );

  useEffect(() => {
    if (isCaseStrategyBuild) {
      const interval = setInterval(() => {
        saveCaseStrategy();
      }, 5000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [isCaseStrategyBuild, saveCaseStrategy]);

  useEffect(() => {
    if (caseStrategyIdParam) {
      onStrategySelected(caseStrategyIdParam);
    }
  }, [caseStrategyIdParam, onStrategySelected]);

  const { data, loading } = useAllModuleTypesQuery();
  const [userQuery] = useGetUserByIdLazyQuery();

  const moduleOptions = useMemo(() => {
    if (loading) return <Spinner />;

    let orderedModules = [];
    for (const type of data?.allModuleTypes?.nodes ?? []) {
      if (type == null) continue;

      if (
        [
          "employer-documents",
          "immigration-history",
          "memo-starter",
          "conclusion",
        ].includes(type.id)
      )
        continue;
      orderedModules.push(type);
    }

    orderedModules.push({ id: "conclusion", inputs: "[]" });
    orderedModules = [{ id: "memo-starter", input: "[]" }, ...orderedModules];

    const res = [];

    for (const type of orderedModules) {
      if (type == null) continue;

      const currCount = modules.filter((m) => m.type === type.id).length;

      const bgStyle =
        currCount > 0
          ? "bg-purple-800 text-white border-none"
          : "bg-transparent text-gray-400";

      res.push(
        <div
          key={type.id}
          className={
            "text-sm whitespace-nowrap hover:cursor-pointer flex flex-row gap-x-2 items-center justify-between rounded-full border border-1 px-2 py-1 " +
            bgStyle
          }
          onClick={() => {
            saveCaseStrategyModules([
              ...modules,
              {
                type: type.id,
                data: {},
                exhibits: [],
                visible: true,
                autogen: false,
                entities: [],
              },
            ]);
          }}
        >
          <div>{type.id}</div>
          {currCount > 0 ? currCount : <Plus />}
        </div>
      );
    }

    return <>{res}</>;
  }, [data?.allModuleTypes?.nodes, loading, modules, setSelectedStrategy]);

  const saveCaseStrategyModules = (modules: CreatorModule[]) => {
    setModules(modules);
    setTimeout(() => {
      saveCaseStrategy();
    }, 0);
  };

  const doModuleChange = useDebounceCallback(
    (idx: number, module: CreatorModule) => {
      const newModules = [...modules];
      newModules[idx] = module;
      saveCaseStrategyModules(newModules);
    },
    200
  );

  const doDeleteModule = useCallback(
    (idx: number) => {
      const newModules = [...modules];
      newModules.splice(idx, 1);
      saveCaseStrategyModules(newModules);
    },
    [modules]
  );

  const doUpdatePetitionInfo = useCallback(
    (
      info: Partial<{
        beneficiaryId: string;
        petitionerId: string;
        visaClassId: string;
        archetype: string;
      }>
    ) => {
      setPetitionInfo({ ...petitionInfo, ...info });
    },
    [petitionInfo]
  );

  const doSelectUser = useCallback(
    async (userId: string) => {
      const { data: userData } = await userQuery({
        variables: {
          userId,
        },
      });
      if (userData?.userById) {
        setSelectedUser(userData.userById);
      }
      if (userData?.userById?.archetype == null) {
        setPetitionInfo({ ...petitionInfo, beneficiaryId: userId });
        return;
      }
      const archetype = userData.userById.archetype
        ? userData.userById.archetype
        : (archetypeParam as Archetype);

      if (!Object.keys(moduleTemplatesByArchetype).includes(archetype)) {
        setPetitionInfo({ ...petitionInfo, archetype, beneficiaryId: userId });
        return;
      }

      if (modules.length === 0) {
        const newModules = [];
        for (const module of moduleTemplatesByArchetype[
          archetype as Archetype
        ]) {
          const schema =
            (data?.allModuleTypes?.nodes ?? []).find(
              (x) => x?.id === module.type
            )?.inputs ?? "[]";

          newModules.push({
            ...module,
            data: {},
            exhibits: [...module.exhibits],
            visible: true,
            schema: JSON.parse(schema),
            autogen: false,
            entities: [],
          });
        }
        setModules([
          {
            type: "memo-starter",
            data: {},
            exhibits: [],
            visible: false,
            schema: [],
            autogen: true,
            entities: [],
          },
          ...newModules,
          {
            type: "conclusion",
            data: {},
            exhibits: [],
            visible: false,
            schema: [],
            autogen: true,
            entities: [],
          },
        ]);
      }

      setPetitionInfo({ ...petitionInfo, archetype, beneficiaryId: userId });
    },
    [
      data?.allModuleTypes?.nodes,
      modules.length,
      petitionInfo,
      userQuery,
      setSelectedUser,
    ]
  );

  const [entityCreatorModalOpen, setEntityCreatorModalOpen] = useState(false);
  const [selectedEntityType, setSelectedEntityType] = useState<
    EntityTypes.Company | EntityTypes.User | undefined
  >(EntityTypes.User);

  const doClickCreateUser = useCallback(() => {
    setSelectedEntityType(EntityTypes.User);
    setEntityCreatorModalOpen(true);
  }, []);

  const doClickCreateCompany = useCallback(() => {
    setSelectedEntityType(EntityTypes.Company);
    setEntityCreatorModalOpen(true);
  }, []);

  const doClickCreateEntity = useCallback(() => {
    setSelectedEntityType(undefined);
    setEntityCreatorModalOpen(true);
  }, []);

  const doSelectVisaClass = useCallback(
    (id: string) => {
      setPetitionInfo({ ...petitionInfo, visaClassId: id });
      console.log(id);

      if (id === VisaClassType.Tn && modules.length === 0) {
        setModules([
          {
            type: "tn-support-letter-offer-of-employment",
            data: {},
            exhibits: [
              {
                type: "support-letter",
                ownedBy: ExhibitOwnerType.Beneficiary,
                autogen: false,
                visible: true,
                data: {},
              },
              {
                type: "offer-letter",
                ownedBy: ExhibitOwnerType.Beneficiary,
                autogen: false,
                visible: true,
                data: {},
              },
            ],
            visible: true,
            autogen: false,
            entities: [],
          },
          {
            type: "tn-occupation-list",
            data: {},
            exhibits: [
              {
                type: "tn-occupation-list",
                ownedBy: ExhibitOwnerType.Plymouth,
                autogen: false,
                visible: false,
                data: {},
              },
              {
                type: "occupational-outlook-handbook",
                ownedBy: ExhibitOwnerType.Plymouth,
                autogen: false,
                visible: false,
                data: {},
              },
            ],
            visible: true,
            autogen: false,
            entities: [],
          },
          {
            type: "tn-beneficiary-education-docs",
            data: {},
            exhibits: [
              {
                type: "transcript",
                ownedBy: ExhibitOwnerType.Beneficiary,
                autogen: false,
                visible: true,
                data: {},
              },
              {
                type: "cv",
                ownedBy: ExhibitOwnerType.Beneficiary,
                autogen: false,
                visible: true,
                data: {},
              },
              {
                type: "proof-of-enrollment",
                ownedBy: ExhibitOwnerType.Beneficiary,
                autogen: false,
                visible: true,
                data: {},
              },
            ],
            visible: true,
            autogen: false,
            entities: [],
          },
        ]);
      }
    },
    [modules.length, petitionInfo]
  );
  const [refreshEntities, setRefreshEntities] = useState<boolean>(false);

  useEffect(() => {
    if (
      beneficiaryIdParam &&
      petitionInfo.beneficiaryId !== beneficiaryIdParam
    ) {
      doSelectUser(beneficiaryIdParam);
    }
  }, [beneficiaryIdParam, petitionInfo.beneficiaryId, doSelectUser]);

  return (
    <div className="max-h-screen flex flex-col bg-gray-50 p-4 overflow-scroll">
      {entityCreatorModalOpen && (
        <EntitiesCreatorModal
          onClose={() => setEntityCreatorModalOpen(false)}
          onSubmit={() => {
            setRefreshEntities(true);
            setEntityCreatorModalOpen(false);
          }}
          entityType={selectedEntityType}
        />
      )}
      {<BuilderNavigator />}
      <div className="h-full flex flex-row items-center border-b py-2 px-3 justify-between">
        <h1 className="font-semibold">
          {isCaseStrategyBuild ? "Build case strategy" : "New Petition"}
        </h1>
        <Button variant="link" onClick={() => setHeaderHidden((x) => !x)}>
          {headerHidden ? <CaretDown /> : <CaretUp />}
        </Button>
      </div>
      {!headerHidden && (
        <>
          <div className="w-full h-full flex flex-col items-center justify-center border-b py-2">
            <div className="w-1/2 flex flex-col gap-y-1">
              <div className="w-full flex flex-row justify-between items-center py-2 border-b">
                <div className="text-gray-400">Visa Type</div>
                <VisaClassSelector
                  disabled={isCaseStrategyBuild}
                  selectedType={petitionInfo.visaClassId}
                  onSelect={(x) => doSelectVisaClass(x)}
                />
              </div>
              <div className="w-full flex flex-row justify-between items-center border-b py-2">
                <div className="text-gray-400">Beneficiary</div>
                <div className="flex flex-row items-center gap-x-2 ">
                  <Button variant="tab" onClick={doClickCreateUser} size={"sm"}>
                    <Plus size={14} />
                  </Button>
                  <UserSelector
                    disabled={isCaseStrategyBuild}
                    selectedId={petitionInfo.beneficiaryId}
                    onSelect={(x) => doSelectUser(x)}
                  />
                </div>
              </div>
              <div className="w-full flex flex-row justify-between items-center py-2">
                <div className="text-gray-400">Petitioner</div>
                <div className="flex flex-row items-center gap-x-2">
                  <Button
                    variant="tab"
                    onClick={doClickCreateCompany}
                    size={"sm"}
                  >
                    <Plus size={14} />
                  </Button>
                  <CompanySelector
                    selectedId={petitionInfo.petitionerId}
                    onSelect={(x) =>
                      doUpdatePetitionInfo({
                        petitionerId: x,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="h-full flex flex-col items-center justify-center p-3 border-b">
        <div className="w-2/3 flex flex-col gap-y-2">
          <h2 className="text-sm text-gray-400">
            Modules&nbsp;
            {selectedUser ? (
              <>
                for{" "}
                <b>
                  {selectedUser?.fullName} (
                  {selectedUser?.archetype
                    ? changeCase.sentenceCase(selectedUser.archetype)
                    : "unknown archetype"}
                  )
                </b>
              </>
            ) : undefined}
          </h2>
          <div className="flex flex-row gap-2 flex-wrap">{moduleOptions}</div>
        </div>
      </div>

      <div className="flex items-center justify-center p-3">
        <div
          className={cn(
            "items-center",
            selectedStrategy && selectedStrategy?.strategy ? "w-full" : "w-2/3"
          )}
        >
          {modules.length === 0 && <h1>No modules yet</h1>}
          <div className="flex flex-col gap-y-5">
            <div className="grid grid-cols-5 gap-x-3 gap-y-2">
              <div>
                <label
                  className="text-xs uppercase font-semibold text-gray-400"
                  htmlFor="filingType"
                >
                  Filing Type
                </label>
                <PlymouthSelect
                  name="filingType"
                  placeholder="Select filing type"
                  onChange={(value: string) => {
                    setMetadata({
                      ...metadata,
                      filingType: value,
                    });
                    saveCaseStrategy();
                  }}
                  value={metadata.filingType}
                >
                  <SelectItem value="change_of_status">
                    Change of Status
                  </SelectItem>
                  <SelectItem value="consular_processing">
                    Consular Processing
                  </SelectItem>
                </PlymouthSelect>
              </div>
              <div>
                <label
                  className="text-xs uppercase font-semibold text-gray-400"
                  htmlFor="fieldOfEndeavor"
                >
                  Field of Endeavor
                </label>
                <Input
                  id="fieldOfEndeavor"
                  placeholder="Field of Endeavor"
                  value={metadata.fieldOfEndeavor}
                  onChange={(e) =>
                    setMetadata({
                      ...metadata,
                      fieldOfEndeavor: e.target.value,
                    })
                  }
                  onBlur={saveCaseStrategy}
                />
              </div>
              <div>
                <label
                  className="text-xs uppercase font-semibold text-gray-400"
                  htmlFor="proposedUSRole"
                >
                  Proposed U.S. Role
                </label>
                <Input
                  id="proposedUSRole"
                  placeholder="Proposed U.S. Role"
                  value={metadata.proposedUSRole}
                  onChange={(e) =>
                    setMetadata({
                      ...metadata,
                      proposedUSRole: e.target.value,
                    })
                  }
                  onBlur={saveCaseStrategy}
                />
              </div>
              <div>
                <label
                  className="text-xs uppercase font-semibold text-gray-400"
                  htmlFor="proposedUSSalary"
                >
                  Proposed U.S. Salary
                </label>
                <Input
                  id="proposedUSSalary"
                  placeholder="Proposed U.S. Salary"
                  value={metadata.proposedUSSalary}
                  onChange={(e) =>
                    setMetadata({
                      ...metadata,
                      proposedUSSalary: e.target.value,
                    })
                  }
                  onBlur={saveCaseStrategy}
                />
              </div>
              <div>
                <label
                  className="text-xs uppercase font-semibold text-gray-400"
                  htmlFor="worksiteLocation"
                >
                  Worksite Location
                </label>
                <Input
                  id="worksiteLocation"
                  placeholder="Worksite Location"
                  value={metadata.worksiteLocation}
                  onChange={(e) =>
                    setMetadata({
                      ...metadata,
                      worksiteLocation: e.target.value,
                    })
                  }
                  onBlur={saveCaseStrategy}
                />
              </div>
            </div>
            {modules.map((m, idx) => (
              <ErrorBoundary
                key={`card-boundary-${m.type}-${idx}-${selectedStrategy?.id}`}
                ErrorComponent={<div>Error loading {m.type} module</div>}
              >
                <ModuleCard
                  key={`card-${m.type}-${idx}-${selectedStrategy?.id}`}
                  cardKey={`card-${m.type}-${idx}-${selectedStrategy?.id}`}
                  onDelete={() => doDeleteModule(idx)}
                  onModuleChange={(m) => doModuleChange(idx, m)}
                  module={m}
                  enableNotes={isCaseStrategyBuild}
                  doClickCreateEntity={doClickCreateEntity}
                  refreshEntities={refreshEntities}
                  onRefreshComplete={() => setRefreshEntities(false)}
                  caseStrategy={selectedStrategy}
                />
              </ErrorBoundary>
            ))}
            <div className="flex flex-row gap-x-1 items-center">
              {isCaseStrategyBuild ? (
                <div>
                  <Button
                    className="my-2"
                    onClick={saveCaseStrategy}
                    disabled={savingCaseStrategy}
                  >
                    {savingCaseStrategy ? (
                      <Spinner />
                    ) : petitionInfo.beneficiaryId ? (
                      `Save case strategy for ${petitionInfo.beneficiaryId}`
                    ) : (
                      "Missing beneficiary"
                    )}
                  </Button>
                  {selectedStrategy ? (
                    <p className="text-sm text-gray-500 italic font-light flex items-center gap-1">
                      <Info size={20} />
                      Last updated at:{" "}
                      <b>
                        {format(
                          selectedStrategy?.updatedAt,
                          "MM/dd/yyyy hh:mm a z"
                        )}
                      </b>
                    </p>
                  ) : null}
                  {updateCaseStrategyError && (
                    <div className="text-red-500">
                      Error saving strategy {updateCaseStrategyError.message}
                    </div>
                  )}
                </div>
              ) : (
                <div className="flex justify-between gap-3 w-full">
                  <div>
                    <Button
                      disabled={createState === "loading" || invalidPetition}
                      className="bg-white border border-purple-800 text-purple-800 hover:bg-purple-800 hover:text-white"
                      onClick={doCreatePetition}
                    >
                      Create
                    </Button>
                    {createState === "loading" && <Spinner />}
                    {createState === "error" && (
                      <div className="text-red-500">
                        Error creating petition
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PetitionCreator;
