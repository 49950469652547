import { useDeleteCompanyDocumentMutation } from "@codegen/index";
import { CompanyDocumentType } from "@codegen/schema";
import Spinner from "@components/spinner";
import { UploadFile, UploadFileStatus } from "@components/uploader/types";
import Uploader from "@components/uploader/uploader";
import { OnboardingPageType } from "@pages/onboarding/types";
import { api } from "@utils/api";
import { useLogError } from "@utils/error";
import { useJwt } from "@utils/hooks";
import React from "react";
import toast from "react-hot-toast";
import { z } from "zod";

export const fileFormSchema = z.object({
  files: z.string().array().min(1, "Please provide at least one file"),
});

export type FileFormType = z.infer<typeof fileFormSchema>;

export const CompanyDocumentUploader: React.FC<
  Pick<OnboardingPageType, "company"> & {
    fileType: CompanyDocumentType;
    data: UploadFile[];
    loading: boolean;
    refetch: () => void;
    disabled?: boolean;
  }
> = ({ company, data, refetch, fileType, disabled }) => {
  const token = useJwt();
  const logError = useLogError();
  const [deleteCompanyDocument] = useDeleteCompanyDocumentMutation();

  const handleDelete = async (file: UploadFileStatus) => {
    try {
      if (file.id) {
        await deleteCompanyDocument({
          variables: {
            input: {
              clientMutationId: `delete-company-document-${file.id}`,
              companyId: company?.value ?? "",
              fileId: file.id,
            },
          },
        });
      }
      await refetch();
    } catch (e) {
      logError(e, { companyId: company?.value, fileId: file.id });
      toast.error("An error occurred while deleting the file");
    }
  };

  const onFileUpload = React.useCallback(
    async (file: File) => {
      try {
        if (company?.value) {
          const data = await api.files.uploadCompanyDoc(
            file,
            token,
            company?.value ?? "",
            fileType
          );
          if (!data.success || !data.id)
            throw new Error("An error occurred while uploading the file");
          const { id } = data;
          const newFile = {
            id,
            name: file.name,
            success: data.success,
            fileId: id,
          };
          await refetch();
          return newFile;
        } else {
          throw new Error("Company ID is required");
        }
      } catch (exception) {
        logError(exception, {
          companyId: company?.value,
          fileType: file.type,
        });
        return {
          success: false,
          name: file.name,
          type: file.type,
          link: "",
        };
      }
    },
    [company?.value, token, fileType, refetch]
  );

  return (
    <div>
      {data == null && <Spinner />}
      {data != null && (
        <Uploader
          key={`${company?.value}-${fileType}`}
          multiple
          onFileUpload={onFileUpload}
          defaultFiles={data.map((x) => ({
            id: x.id ?? "",
            name: x.name ?? "",
          }))}
          allowDelete
          disabled={disabled}
          onDeleteFile={handleDelete}
        />
      )}
    </div>
  );
};
