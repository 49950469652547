import {
  BlockquoteFigure,
  CharacterCount,
  Color,
  Column,
  // emojiSuggestion,
  Columns,
  Document,
  Dropcursor,
  // Emoji,
  Figcaption,
  // FileHandler,
  // Focus,
  FontFamily,
  FontSize,
  Heading,
  Highlight,
  HorizontalRule,
  // ImageBlock,
  Link,
  Placeholder,
  Selection,
  SlashCommand,
  StarterKit,
  Subscript,
  Superscript,
  Table,
  // TableOfContent,
  TableCell,
  TableHeader,
  TableRow,
  // TextAlign,
  TextStyle,
  TrailingNode,
  Typography,
  Underline,
} from ".";
import { ImageUpload } from "./ImageUpload";
// import { TableOfContentNode } from "./TableOfContentNode";
import History from "@tiptap/extension-history";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";

import { Extension } from "@tiptap/core";
import CustomAIWriter from "./CustomAiWriter";

const TextAlign = Extension.create({
  name: "textAlign",
  addOptions() {
    return {
      types: [],
      alignments: ["left", "center", "right", "justify"],
      defaultAlignment: "justify",
    };
  },
  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          textAlign: {
            default: this.options.defaultAlignment,
            parseHTML: (element) =>
              element.style.textAlign || this.options.defaultAlignment,
            renderHTML: (attributes) => {
              // if (attributes.textAlign === this.options.defaultAlignment) {
              //     return {};
              // }
              return { style: `text-align: ${attributes.textAlign}` };
            },
          },
        },
      },
    ];
  },
  addCommands() {
    return {
      setTextAlign:
        (alignment) =>
        ({ commands }) => {
          if (!this.options.alignments.includes(alignment)) {
            return false;
          }
          // @ts-ignore temp fix
          return this.options.types.every((type) =>
            commands.updateAttributes(type, { textAlign: alignment })
          );
        },
      unsetTextAlign:
        () =>
        ({ commands }) => {
          // @ts-ignore temp fix
          return this.options.types.every((type) =>
            commands.resetAttributes(type, "textAlign")
          );
        },
    };
  },
  addKeyboardShortcuts() {
    return {
      "Mod-Shift-l": () => this.editor.commands.setTextAlign("left"),
      "Mod-Shift-e": () => this.editor.commands.setTextAlign("center"),
      "Mod-Shift-r": () => this.editor.commands.setTextAlign("right"),
      "Mod-Shift-j": () => this.editor.commands.setTextAlign("justify"),
    };
  },
});

interface ExtensionKitProps {
  id?: string;
  nodeType?: "exhibit" | "module";
  defaultAlignment?: string;
}

const ExtensionKit = (props: ExtensionKitProps) => {
  const res = [
    Document,
    Columns,
    TaskList,
    TaskItem.configure({
      nested: true,
    }),

    // AiImage.configure({
    //   authorId: props?.userId,
    //   authorName: props?.userName,
    // }),
    Column,
    Selection,
    History,
    Heading.configure({
      levels: [1, 2, 3, 4, 5, 6],
    }),
    HorizontalRule,
    StarterKit.configure({
      document: false,
      dropcursor: false,
      heading: false,
      horizontalRule: false,
      blockquote: false,
      history: false,
      codeBlock: false,
    }),
    // CodeBlockLowlight.configure({
    //   lowlight,
    //   defaultLanguage: null,
    // }),
    TextStyle,
    FontSize,
    FontFamily,
    Color,
    TrailingNode,
    Link.configure({
      openOnClick: true,
    }),
    Highlight.configure({ multicolor: true }),
    Underline,
    CharacterCount.configure({ limit: 50000 }),
    // TableOfContent,
    // TableOfContentNode,
    ImageUpload.configure({}),
    // ImageBlock,
    // FileHandler.configure({
    //   allowedMimeTypes: ["image/png", "image/jpeg", "image/gif", "image/webp"],
    //   onDrop: (currentEditor, files, pos) => {
    //     // files.forEach(async () => {
    //     //   const url = await API.uploadImage();

    //     //   currentEditor.chain().setImageBlockAt({ pos, src: url }).focus().run();
    //     // });

    //     console.log("onDrop", currentEditor, files, pos);
    //   },
    //   onPaste: (currentEditor, files) => {
    //     console.log("onPaste", currentEditor, files);
    //   },
    // }),
    // Emoji.configure({
    //   enableEmoticons: true,
    //   suggestion: emojiSuggestion,
    // }),
    TextAlign.extend({
      addKeyboardShortcuts() {
        return {};
      },
    }).configure({
      types: ["heading", "paragraph"],
      defaultAlignment: props.defaultAlignment,
    }),
    Subscript,
    Superscript,
    Table,
    TableCell,
    TableHeader,
    TableRow,
    Typography,
    Placeholder.configure({
      includeChildren: true,
      showOnlyCurrent: false,
      placeholder: () => "",
    }),
    SlashCommand,
    // Focus,
    Figcaption,
    BlockquoteFigure,
    Dropcursor.configure({
      width: 2,
      class: "ProseMirror-dropcursor border-black",
    }),
  ];

  res.push(
    CustomAIWriter.configure({
      id: props.id,
      type: props.nodeType,
    })
  );
  return res;
};

export default ExtensionKit;
