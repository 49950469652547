import carlos from "@assets/images/carlos.jpeg";
import flatiron from "@assets/images/flatiron.png";
import goldengate from "@assets/images/goldengate.png";
import seattle from "@assets/images/seattle.png";
import yosemite from "@assets/images/yosemite.png";
import { useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { ContactCard } from "../../components/welcome/ContactCard";
import { WelcomeLayout } from "../../components/welcome/WelcomeLayout";
import { WelcomeSlide } from "../../components/welcome/WelcomeSlides";

const ROOT_PATH = "/welcome";

const SLIDES = [
  {
    path: "",
    title: "Take your first step into the land of opportunity.",
    description:
      "<b>Welcome to Plymouth Street</b> - This is your Plymouth Portal. We're here to make your immigration journey seamless and efficient. Upload evidence, confirm details, and track your O-1 Visa petition with our expert support. \n\n I have a few tips to help you get started with your onboarding process.",
    image: flatiron,
    imagePosition: "right" as const,
  },
  {
    path: "security",
    title:
      "All uploaded documents and personal details are secure and confidential.",
    description:
      "We understand that some requests require uploading sensitive documents. These documents are only viewed by the Plymouth Street team and USCIS.",
    image: goldengate,
    imagePosition: "left" as const,
  },
  {
    path: "tasks",
    title: "Easily track and handle your case from start to finish.",
    description:
      'Clicking <b>"Next"</b> signals your readiness for review. To revisit a section later, use <b>"Next – Skip for now"</b>, which saves your progress but indicates ongoing onboarding.',
    image: yosemite,
    imagePosition: "right" as const,
  },
  // {
  //   path: "petition-status",
  //   title: "Easily check on your petition status, any time.",
  //   description:
  //     'Throughout this process, you may check your current petition status at the top of your dashboard at any time. Until you\'ve completed your onboarding and provided all the documents and information we need, it will remain in the "Onboarding" state.',
  //   image: "/assets/images/petition-tracker.png",
  //   imagePosition: "right" as const,
  // },
  {
    path: "expert-letters",
    title: "Choosing Someone to Sign Your Expert Letter.",
    description:
      "During onboarding, you'll select a signatory for an expert letter supporting your visa petition. Our team drafts these letters for your review before they are sent for signature. Guidance on choosing signatories is provided in each module. Use the attached language when reaching out. Once confirmed, enter their details and your relationship in the portal.",
    image: seattle,
    imagePosition: "right" as const,
  },
];

function WelcomePage({ index }: { index: number }) {
  const navigate = useNavigate();
  const [showContact, setShowContact] = useState(false);

  const handleNext = () => {
    if (index < SLIDES.length - 1) {
      const nextPath = SLIDES[index + 1].path;
      navigate(`${ROOT_PATH}/${nextPath}`);
    } else {
      setShowContact(true);
    }
  };

  const handleStart = () => {
    navigate("/onboarding");
  };

  const renderAccessory = () => {
    if (index === 0) {
      return (
        <div className="flex items-center gap-4">
          <img
            src="https://ca.slack-edge.com/T052H6ADCN7-U052HA6Q6AW-bd19ebc72343-512"
            alt="Lisa"
            className="w-[84px] h-[84px] rounded-full object-cover"
          />
          <div>
            <div className="text-[22px] font-medium leading-[150%] text-nevada-950">
              Lisa
            </div>
            <div className="text-base font-medium leading-[150%] text-nevada-500">
              Founder, Plymouth Street
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="relative">
      <WelcomeLayout
        image={SLIDES[index].image}
        imagePosition={SLIDES[index].imagePosition}
      >
        <WelcomeSlide
          title={SLIDES[index].title}
          description={SLIDES[index].description}
          accessory={renderAccessory()}
        >
          <div className="mt-8">
            <button
              onClick={handleNext}
              className="w-full bg-blue-600 text-white py-3 px-6 rounded-lg hover:bg-blue-700 transition-colors"
            >
              {index === 0
                ? "START TUTORIAL"
                : index < SLIDES.length - 1
                ? "NEXT"
                : "EXIT TUTORIAL"}
            </button>
          </div>
        </WelcomeSlide>
      </WelcomeLayout>

      {showContact && (
        <div className="fixed inset-0 bg-white">
          <ContactCard
            name="Carlos"
            email="carlos@plymouthstreet.com"
            image={carlos}
            onExit={handleStart}
          />
        </div>
      )}
    </div>
  );
}

export function Welcome() {
  return (
    <Routes>
      <Route path="/" element={<WelcomePage index={0} />} />
      <Route path="/security" element={<WelcomePage index={1} />} />
      <Route path="/tasks" element={<WelcomePage index={2} />} />
      {/* <Route path="/petition-status" element={<WelcomePage index={3} />} /> */}
      <Route path="/expert-letters" element={<WelcomePage index={3} />} />
    </Routes>
  );
}
