import { useAllCompanyDocsQuery } from "@codegen/index";
import { CompanyDocumentType } from "@codegen/schema";
import { Button } from "@components/button";
import Spinner from "@components/spinner";
import { EmployerOnboardingContainer } from "@pages/onboarding/employer/forms/employer-onboarding-container";
import { CompanyDocumentUploader } from "@pages/onboarding/shared/company-document-upload";
import { CTAContainer } from "@pages/onboarding/shared/cta-container";
import { useJwt } from "@utils/hooks";
import * as React from "react";
import { useMemo, useState } from "react";
import { useEmployerOnboarding } from "../../employer-onboarding";
import { useReadOnlyMode } from "@components/readOnlyModeProvider";

export const EmployerFundingDocumentsForm = () => {
  const { onSubmit, company, onBack, onSkip, onSave } = useEmployerOnboarding();
  const { isReadOnly } = useReadOnlyMode();
  const [fundingDocUploaded, setFundingDocUploaded] = useState(false);

  const token = useJwt();

  const { data, loading, refetch } = useAllCompanyDocsQuery({
    variables: {
      companyId: company?.value ?? "",
    },
  });

  const employerFundingDoc = useMemo(() => {
    if (data == null || data.allCompanyDocuments?.nodes == null) {
      return;
    }

    const res = [];

    for (const doc of data.allCompanyDocuments.nodes) {
      if (doc?.fileByFileId?.id == null || doc?.fileByFileId?.deleted) {
        continue;
      }

      if (
        [
          CompanyDocumentType.FundingDocument,
          CompanyDocumentType.BankStatement,
          CompanyDocumentType.SafeDocumentation,
          CompanyDocumentType.TermSheet,
        ].includes(doc.type)
      ) {
        res.push({
          id: doc.fileByFileId.id ?? "",
          name: doc.fileByFileId.name,
        });
      }
    }
    return res;
  }, [data]);

  React.useEffect(() => {
    if (
      employerFundingDoc &&
      data !== null &&
      data?.allCompanyDocuments?.nodes !== null
    ) {
      onSave(employerFundingDoc?.map((doc) => doc.id));
    }
  }, [employerFundingDoc, onSave, data]);

  React.useEffect(() => {
    if (employerFundingDoc == null) return;
    if (employerFundingDoc.length > 0) setFundingDocUploaded(true);
  }, [employerFundingDoc]);

  const isValid = employerFundingDoc != null && employerFundingDoc.length > 0;

  return (
    <EmployerOnboardingContainer
      title="Employer Funding Documents"
      subtitle="As part of the application, we include evidence that the company can cover expenses for business operations for the duration of the visa. Please upload any one of the following:"
      cardTitle={"Please upload any of the following:"}
      progress={0}
      name="employer_funding_docs"
    >
      <div className="flex flex-col gap-y-4 w-[500px]">
        <div className="flex flex-col gap-y-4 w-[500px]">
          <div>
            <ul className="mb-3 list-disc px-4 gap-2">
              <li>U.S. Company Corporate Bank Statement</li>
              <li>Termsheet / Funding Evidence</li>
              <li>SAFE</li>
              <li>Existing Customer Contracts</li>
            </ul>

            {employerFundingDoc == null && <Spinner />}
            {employerFundingDoc != null && !loading && (
              <CompanyDocumentUploader
                key={`employer-funding-doc`}
                fileType={CompanyDocumentType.FundingDocument}
                data={employerFundingDoc}
                loading={loading}
                refetch={refetch}
                disabled={isReadOnly}
                company={company}
              />
            )}
          </div>
        </div>
      </div>
      <CTAContainer onBack={onBack} onSkip={onSkip}>
        <Button
          variant="accent"
          className="ml-auto text-md rounded-sm px-7 py-5"
          disabled={!isValid}
          onClick={() => onSubmit(employerFundingDoc?.map((doc) => doc.id))}
        >
          Next
        </Button>
      </CTAContainer>
    </EmployerOnboardingContainer>
  );
};
