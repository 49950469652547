import { EditorProvider } from "@tiptap/react";
import { useDebounce } from "@utils/hooks";
import React, { useEffect, useMemo, useState } from "react";
import ExtensionKit from "./extensions/extension-kit";
import { useBlockEditor as useRichTextEditor } from "./hooks";
import { TextMenu } from "./menus";

export const RichTextEditor = (props: {
  initialContent: string;
  onSaveContent?: (content: string) => void;
  onClose?: () => void;
  includeMenu?: boolean;
  editable?: boolean;
  defaultAlignment?: "justify" | "center" | "left" | "right";
  aiEditorSettings?: {
    id: string;
    nodeType: "exhibit" | "module";
  };
}) => {
  const { editor } = useRichTextEditor(props);
  const [content, setContent] = useState(props.initialContent);
  const deboucedContent = useDebounce(content, 1000);
  // Default to true
  const includeMenu =
    typeof props.includeMenu === "boolean" ? props.includeMenu : true;

  useEffect(() => {
    if (props.onSaveContent) {
      props.onSaveContent(deboucedContent);
    }
  }, [deboucedContent, props]);

  const memoizedTextMenu = useMemo(() => {
    return <TextMenu onClose={props.onClose} />;
  }, [props.onClose]);

  if (!editor) {
    return null;
  }

  const extensionSettings = {
    defaultAlignment: props.defaultAlignment,
  };

  return (
    <EditorProvider
      extensions={[
        ...ExtensionKit(
          props.aiEditorSettings
            ? { ...props.aiEditorSettings, ...extensionSettings }
            : extensionSettings
        ),
      ]}
      content={props.initialContent}
      slotBefore={includeMenu ? memoizedTextMenu : undefined}
      onUpdate={({ editor }) => {
        if (props.onSaveContent) {
          setContent(editor.getHTML());
        }
      }}
      editable={props.editable}
    />
  );
};

const MemoizedRichTextEditor = React.memo(RichTextEditor);
export default MemoizedRichTextEditor;
