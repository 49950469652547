import { Button } from "@components/button";
import {
  CaretDown,
  CaretUp,
  Eye,
  EyeSlash,
  MagicWand,
  Pencil,
  Plus,
  Trash,
} from "@phosphor-icons/react";
import { cn } from "@utils/cn";
import React, { useCallback, useMemo } from "react";

import { CaseStrategy, EntityTypes, ExhibitOwnerType } from "@codegen/schema";
import { Combobox } from "@components/combobox";
import EntityObjectSelector from "@components/entitySelector";
import { Input } from "@components/input";
import { Label } from "@components/label";
import { Textarea } from "@components/textArea";
import { useDebounceCallback } from "@react-hook/debounce";
import { ownerMap } from "@utils/archetypes";
import { CreatorModuleType, moduleExhibitTypeMap } from "@utils/parsers";
import * as changeCase from "change-case";
import { moduleDefaultNotes, useAllExhibitTypesMap } from "./notes";
import { CreatorExhibit, CreatorModule } from "./types";

const PressExhibitAutogenInputs = (props: {
  onDataChange: (key: string, value: string) => void;
}) => {
  return (
    <div>
      <div className="flex flex-col gap-y-1">
        <h1 className="text-sm font-semibold text-gray-400">
          Press Article Link
        </h1>
        <Input onChange={(e) => props.onDataChange("url", e.target.value)} />
      </div>
    </div>
  );
};

const PatentExhibitAutogenInputs = (props: {
  onDataChange: (key: string, value: string) => void;
}) => {
  const { onDataChange } = props;

  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex flex-col gap-y-1">
        <h1 className="text-sm font-semibold text-gray-400">Input Type</h1>
        <Combobox
          placeholder="Select an input type"
          options={[
            {
              label: "Google Scholar Patent URL",
              value: "google_patent_url",
            },
            {
              label: "Patent Publication Number",
              value: "patent_id",
            },
          ]}
          onSelect={(value) => onDataChange("key", value)}
          className="w-full"
        />
      </div>

      <Input
        placeholder="Enter the patent URL or publication number"
        onChange={(e) => onDataChange("value", e.target.value)}
        className="w-full"
      />
    </div>
  );
};

const CompensationBenchmarkExhibitAutogenInputs = () => {
  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex flex-col gap-y-1">
        <h1 className="text-sm text-gray-400">
          Compensation benchmark is based on the entities selected above. Please
          ensure that the entities are correct before proceeding.
        </h1>
      </div>
    </div>
  );
};

const ExhibitCard = (props: {
  exhibit: CreatorExhibit;
  module?: CreatorModule;
  onDelete: () => void;
  onExhibitChange?: (e: CreatorExhibit) => void;
}) => {
  const { data: allExhibitTypes, loading: allExhibitTypesLoading } =
    useAllExhibitTypesMap();
  const doSetDataKey = useDebounceCallback((key: string, value: string) => {
    const newData = { ...props.exhibit.data, [key]: value };
    props.onExhibitChange?.({
      ...props.exhibit,
      data: newData,
    });
  }, 200);

  const exhibitAutogenMap: Record<string, JSX.Element> = useMemo(() => {
    return {
      "press-article": (
        <PressExhibitAutogenInputs onDataChange={doSetDataKey} />
      ),
      patent: <PatentExhibitAutogenInputs onDataChange={doSetDataKey} />,
      "compensation-benchmark": <CompensationBenchmarkExhibitAutogenInputs />,
    };
  }, [doSetDataKey]);

  return (
    <div className="w-full border border-1 rounded-lg">
      <div className="flex flex-row justify-between items-center border-b border-purple-400/10 py-2 px-3">
        <h1 className="text-md text-purple-800 px-2 py-1 rounded-sm">
          {props.exhibit.type}&nbsp;({props.exhibit.ownedBy})
        </h1>

        <Button
          variant="destructive"
          size={"icon"}
          onClick={props.onDelete}
          className="bg-transparent shadow-none text-purple-800 hover:text-white"
        >
          <Trash size={16} />
        </Button>
        <div className="ml-auto flex flex-row gap-x-1">
          <div
            className={cn(
              "flex font-bold text-md h-8 px-3 items-center rounded-sm hover:cursor-pointer",
              props.exhibit.visible ? "bg-gray-100" : "hover:bg-gray-100"
            )}
            onClick={() => {
              props.onExhibitChange?.({
                ...props.exhibit,
                visible: !props.exhibit.visible,
              });
            }}
          >
            {props.exhibit.visible ? (
              <Eye size={16} weight="bold" />
            ) : (
              <EyeSlash size={16} weight="bold" />
            )}
          </div>

          {Object.keys(exhibitAutogenMap).includes(props.exhibit.type) && (
            <div
              className={cn(
                "flex font-bold text-md h-8 px-3 items-center rounded-sm hover:cursor-pointer",
                props.exhibit.autogen ? "bg-gray-100" : "hover:bg-gray-100"
              )}
              onClick={() => {
                props.onExhibitChange?.({
                  ...props.exhibit,
                  autogen: !props.exhibit.autogen,
                });
              }}
            >
              <MagicWand size={16} />
            </div>
          )}
        </div>
      </div>
      <div
        onBlur={(e) => {
          props.onExhibitChange?.({
            ...props.exhibit,
            name: (e.target as HTMLDivElement).textContent ?? "",
          });
        }}
        contentEditable
        className="flex flex-grow gap-x-2 px-5 py-3 items-center"
      >
        {props.exhibit.name || allExhibitTypes[props.exhibit.type] || (
          <p className="text-gray-400 flex gap-2 items-center">
            Enter exhibit name
          </p>
        )}{" "}
        <Pencil className="text-gray-400" size={16} />
      </div>
      {Object.keys(exhibitAutogenMap).includes(props.exhibit.type) &&
        props.exhibit.autogen && (
          <div className="px-4 py-2">
            {exhibitAutogenMap[props.exhibit.type]}
          </div>
        )}
    </div>
  );
};

const AuthorshipModuleAutogenInputs = (props: {
  onDataChange: (key: string, value: string) => void;
}) => {
  return (
    <div className="flex flex-col gap-y-2 px-3 py-2">
      <div className="flex flex-col gap-y-1">
        <h1 className="text-sm font-semibold text-gray-400">Link</h1>
        <Input
          onChange={(e) =>
            props.onDataChange("google_scholar_url", e.target.value)
          }
        />
      </div>
      <div className="flex flex-col gap-y-1">
        <h1 className="text-sm font-semibold text-gray-400">
          Number of Articles
        </h1>
        <Input
          type="number"
          onChange={(e) => props.onDataChange("num_articles", e.target.value)}
        />
      </div>
    </div>
  );
};

const ModuleCard = (props: {
  module: CreatorModule;
  onDelete: () => void;
  onModuleChange?: (m: CreatorModule) => void;
  enableNotes?: boolean;
  cardKey?: string;
  doClickCreateEntity?: () => void;
  refreshEntities?: boolean;
  onRefreshComplete?: () => void;
  caseStrategy?:
    | Pick<
        CaseStrategy,
        | "userId"
        | "id"
        | "nodeId"
        | "userEmail"
        | "strategy"
        | "label"
        | "updatedAt"
      >
    | undefined;
}) => {
  const [collapsed, setCollapsed] = React.useState<boolean>(false);
  const defaultNotes: string | undefined =
    moduleDefaultNotes[props.module.type];
  const notes = props.module.data?.notes;
  const moduleNotes =
    typeof notes === "string" && notes.trim() ? notes : defaultNotes;

  const [manualNotes, setManualNotes] = React.useState<string>(moduleNotes);

  const { refreshEntities, onRefreshComplete } = props;
  const doAddExhibit = useDebounceCallback((exhibit: CreatorExhibit) => {
    const newExhibits = [...props.module.exhibits, { ...exhibit }];
    props.onModuleChange?.({
      ...props.module,
      exhibits: newExhibits,
    });
  }, 200);

  const doSetDataKey = useDebounceCallback((key: string, value: string) => {
    const newData = { ...props.module.data, [key]: value };
    props.onModuleChange?.({
      ...props.module,
      data: newData,
    });
  }, 200);

  const autogenModuleMap: Record<string, JSX.Element> = useMemo(() => {
    return {
      authorship: <AuthorshipModuleAutogenInputs onDataChange={doSetDataKey} />,
    };
  }, [doSetDataKey]);

  const exhibitOpts = useMemo(() => {
    const res = [];
    if (
      !Object.keys(moduleExhibitTypeMap).includes(
        props.module.type as CreatorModuleType
      )
    )
      return;

    const allowedExhibitOptions =
      moduleExhibitTypeMap[props.module.type as CreatorModuleType];

    for (const exhibit of allowedExhibitOptions ?? []) {
      const currCount = props.module.exhibits?.filter(
        (e) => e.type === exhibit
      ).length;

      const bgStyle =
        currCount > 0
          ? "bg-purple-800 text-white border-none"
          : "bg-transparent text-gray-400";

      res.push(
        <div
          key={`${props.module.type}-${exhibit}-opt`}
          className={
            "w-fit text-sm hover:cursor-pointer flex flex-row gap-x-2 items-center justify-between rounded-full border border-1 px-2 py-1 " +
            bgStyle
          }
          onClick={() => {
            const defaultOwner =
              ownerMap.get(exhibit) ?? ExhibitOwnerType.Plymouth;
            doAddExhibit({
              type: exhibit,
              ownedBy: defaultOwner,
              visible: defaultOwner === ExhibitOwnerType.Beneficiary,
              autogen: exhibit in ["press-article"],
              data: {},
            });
          }}
        >
          <div>
            {props.module.type === "memo-starter" && exhibit === "expert-letter"
              ? "AO Letter"
              : exhibit}
          </div>
          {currCount > 0 ? currCount : <Plus />}
        </div>
      );
    }

    return res;
  }, [doAddExhibit, props.module.exhibits, props.module.type]);

  const doDeleteExhibit = useCallback(
    (idx: number) => {
      const newExhibits = [...props.module.exhibits];
      newExhibits.splice(idx, 1);
      props.onModuleChange?.({
        ...props.module,
        exhibits: newExhibits,
      });
    },
    [props]
  );

  const exhibitList = useMemo(() => {
    const res = [];
    for (const exhibit of props.module.exhibits) {
      const idx = props.module.exhibits.indexOf(exhibit);

      res.push(
        <div
          className="flex flex-col last:border-none py-2 px-4"
          key={`${props.module.type}-${exhibit.type}-${idx}`}
        >
          <ExhibitCard
            module={props.module}
            exhibit={exhibit}
            onDelete={() => doDeleteExhibit(idx)}
            onExhibitChange={(e) => {
              const newExhibits = [...(props.module.exhibits ?? [])];
              newExhibits[idx] = e;
              props.onModuleChange?.({
                ...props.module,
                exhibits: newExhibits,
              });
            }}
          />
        </div>
      );
    }
    return res;
  }, [doDeleteExhibit, props]);

  const entities = useMemo(() => {
    const onEntityChange = (e: { id: string; name: string; type: string }) => {
      const newEntities = [...(props.module.entities ?? [])];
      const idx = newEntities.findIndex((x) => x.type === e.type);
      if (idx === -1) {
        newEntities.push({ type: e.type, id: e.id, name: e.name });
      } else {
        newEntities[idx] = { type: e.type, id: e.id, name: e.name };
      }
      props.onModuleChange?.({
        ...props.module,
        entities: newEntities,
      });
    };

    const moduleTypeToEntityMap: Record<string, JSX.Element> = {
      "critical-role": (
        <div className="flex flex-col gap-y-2">
          <div className="flex flex-col gap-y-1">
            <h1 className="text-xs font-semibold text-gray-400">Company</h1>
            <EntityObjectSelector
              type={EntityTypes.Company}
              onSelect={(e) =>
                onEntityChange({
                  id: e.id,
                  name: e.name,
                  type: EntityTypes.Company,
                })
              }
              selectedId={
                props.module.entities?.find(
                  (x) => x.type === EntityTypes.Company
                )?.id ?? ""
              }
              refreshed={refreshEntities}
              resetRefreshed={onRefreshComplete}
            />
          </div>
          <div className="flex flex-col gap-y-1">
            <h1 className="text-xs font-semibold text-gray-400">Role</h1>
            <EntityObjectSelector
              type={EntityTypes.Role}
              onSelect={(e) =>
                onEntityChange({
                  id: e.id,
                  name: e.name,
                  type: EntityTypes.Role,
                })
              }
              selectedId={
                props.module.entities.find((x) => x.type === EntityTypes.Role)
                  ?.id ?? ""
              }
              refreshed={refreshEntities}
              resetRefreshed={onRefreshComplete}
            />
          </div>
        </div>
      ),
      membership: (
        <div className="flex flex-col gap-y-2">
          <div className="flex flex-col gap-y-1">
            <h1 className="text-xs font-semibold text-gray-400">Membership</h1>
            <EntityObjectSelector
              type={EntityTypes.Membership}
              onSelect={(e) =>
                onEntityChange({
                  id: e.id,
                  name: e.name,
                  type: EntityTypes.Membership,
                })
              }
              selectedId={
                props.module.entities.find(
                  (x) => x.type === EntityTypes.Membership
                )?.id ?? ""
              }
              refreshed={refreshEntities}
              resetRefreshed={onRefreshComplete}
            />
          </div>
        </div>
      ),
      "high-remuneration": (
        <div className="flex flex-col gap-y-2">
          <div className="flex flex-col gap-y-1">
            <h1 className="text-xs font-semibold text-gray-400">Company</h1>
            <EntityObjectSelector
              type={EntityTypes.Company}
              onSelect={(e) =>
                onEntityChange({
                  id: e.id,
                  name: e.name,
                  type: EntityTypes.Company,
                })
              }
              selectedId={
                props.module?.entities?.find(
                  (x) => x.type === EntityTypes.Company
                )?.id ?? ""
              }
              refreshed={refreshEntities}
              resetRefreshed={onRefreshComplete}
            />
          </div>
          <div className="flex flex-col gap-y-1">
            <h1 className="text-xs font-semibold text-gray-400">Role</h1>
            <EntityObjectSelector
              type={EntityTypes.Role}
              onSelect={(e) =>
                onEntityChange({
                  id: e.id,
                  name: e.name,
                  type: EntityTypes.Role,
                })
              }
              selectedId={
                props.module.entities.find((x) => x.type === EntityTypes.Role)
                  ?.id ?? ""
              }
              refreshed={refreshEntities}
              resetRefreshed={onRefreshComplete}
            />
          </div>
          <div className="flex flex-col gap-y-1">
            <h1 className="text-xs font-semibold text-gray-400">Location</h1>
            <EntityObjectSelector
              type={EntityTypes.Location}
              onSelect={(e) =>
                onEntityChange({
                  id: e.id,
                  name: e.name,
                  type: EntityTypes.Location,
                })
              }
              selectedId={
                props.module.entities.find(
                  (x) => x.type === EntityTypes.Location
                )?.id ?? ""
              }
              refreshed={refreshEntities}
              resetRefreshed={onRefreshComplete}
            />
          </div>
        </div>
      ),
      judging: (
        <div className="flex flex-col gap-y-2">
          <div className="flex flex-col gap-y-1">
            <h1 className="text-xs font-semibold text-gray-400">
              Judging Entity
            </h1>
            <EntityObjectSelector
              type={EntityTypes.JudgingEntity}
              onSelect={(e) =>
                onEntityChange({
                  id: e.id,
                  name: e.name,
                  type: EntityTypes.JudgingEntity,
                })
              }
              selectedId={
                props.module.entities.find(
                  (x) => x.type === EntityTypes.JudgingEntity
                )?.id ?? ""
              }
              refreshed={refreshEntities}
              resetRefreshed={onRefreshComplete}
            />
          </div>
        </div>
      ),
      awards: (
        <div className="flex flex-col gap-y-2">
          <div className="flex flex-col gap-y-1">
            <h1 className="text-xs font-semibold text-gray-400">VC</h1>
            <EntityObjectSelector
              type={EntityTypes.Vc}
              onSelect={(e) =>
                onEntityChange({
                  id: e.id,
                  name: e.name,
                  type: EntityTypes.Vc,
                })
              }
              selectedId={
                props.module.entities.find((x) => x.type === EntityTypes.Vc)
                  ?.id ?? ""
              }
              refreshed={refreshEntities}
              resetRefreshed={onRefreshComplete}
            />
          </div>
          <div className="flex flex-col gap-y-1">
            <h1 className="text-xs font-semibold text-gray-400">Award</h1>
            <EntityObjectSelector
              type={EntityTypes.Award}
              onSelect={(e) =>
                onEntityChange({
                  id: e.id,
                  name: e.name,
                  type: EntityTypes.Award,
                })
              }
              selectedId={
                props.module.entities.find((x) => x.type === EntityTypes.Award)
                  ?.id ?? ""
              }
              refreshed={refreshEntities}
              resetRefreshed={onRefreshComplete}
            />
          </div>
        </div>
      ),
      "original-contribution": (
        <div className="flex flex-col gap-y-2">
          <div className="flex flex-col gap-y-1">
            <h1 className="text-xs font-semibold text-gray-400">Company</h1>
            <EntityObjectSelector
              type={EntityTypes.Company}
              onSelect={(e) =>
                onEntityChange({
                  id: e.id,
                  name: e.name,
                  type: EntityTypes.Company,
                })
              }
              selectedId={
                props.module.entities.find(
                  (x) => x.type === EntityTypes.Company
                )?.id ?? ""
              }
              refreshed={refreshEntities}
              resetRefreshed={onRefreshComplete}
            />
          </div>
          <div className="flex flex-col gap-y-1">
            <h1 className="text-xs font-semibold text-gray-400">Role</h1>
            <EntityObjectSelector
              type={EntityTypes.Role}
              onSelect={(e) =>
                onEntityChange({
                  id: e.id,
                  name: e.name,
                  type: EntityTypes.Role,
                })
              }
              selectedId={
                props.module.entities.find((x) => x.type === EntityTypes.Role)
                  ?.id ?? ""
              }
              refreshed={refreshEntities}
              resetRefreshed={onRefreshComplete}
            />
          </div>
        </div>
      ),
    };

    if (moduleTypeToEntityMap[props.module.type] == null) return;

    return (
      <div className="flex flex-col gap-2 px-5 py-3 border-b border-purple-400/10">
        <div className="flex flex-row justify-between w-full">
          <div className="flex gap-3 items-center">
            <h2 className="text-gray-400">Entities</h2>
            <Button
              variant="tab"
              onClick={props.doClickCreateEntity}
              size={"sm"}
            >
              <Plus size={14} />
            </Button>
          </div>
        </div>
        {moduleTypeToEntityMap[props.module.type]}
      </div>
    );
  }, [props, refreshEntities]);

  const caseStrategy = props.caseStrategy;
  const strategy = caseStrategy?.strategy
    ? JSON.parse(caseStrategy.strategy)
    : null;
  const moduleStrategy = strategy?.modules?.find(
    (m: any) => m.type === props.module.type
  );

  return (
    <div
      className={cn("grid gap-2 grid-cols-2 border rounded-lg bg-white py-3")}
    >
      <div
        className={cn(
          "border border-1 rounded-lg ",
          strategy ? "col-span-1" : "col-span-2"
        )}
      >
        <div className="flex flex-row justify-between items-center border-b border-purple-400/10 py-2 px-3">
          <div className="text-lg text-purple-800 px-2 py-1 rounded-sm flex flex-row gap-x-1 items-center">
            <h1 className="bg-transparent border-none outline-none">
              {props.module.name ?? props.module.type}
            </h1>
            <Button
              variant="destructive"
              size={"icon"}
              onClick={props.onDelete}
              className="bg-transparent shadow-none text-purple-800 hover:text-white"
            >
              <Trash size={16} />
            </Button>
          </div>

          <div className="flex flex-row gap-x-1 items-center">
            <div
              className={cn(
                "flex font-bold text-md h-8 px-3 items-center rounded-sm hover:cursor-pointer",
                props.module.visible ? "bg-gray-100" : "hover:bg-gray-100"
              )}
              onClick={() => {
                props.onModuleChange?.({
                  ...props.module,
                  visible: !props.module.visible,
                });
              }}
            >
              {props.module.visible ? (
                <Eye size={18} weight="bold" />
              ) : (
                <EyeSlash size={18} weight="bold" />
              )}
            </div>
            {(Object.keys(autogenModuleMap).includes(props.module.type) ||
              ["memo-starter", "conclusion"].includes(props.module.type)) && (
              <div
                className={cn(
                  "flex font-bold text-md h-8 px-3 items-center rounded-sm hover:cursor-pointer",
                  props.module.autogen ? "bg-gray-100" : "hover:bg-gray-100"
                )}
                onClick={() => {
                  props.onModuleChange?.({
                    ...props.module,
                    autogen: !props.module.autogen,
                  });
                }}
              >
                <MagicWand size={18} />
              </div>
            )}

            <Button
              variant="link"
              className="hover:bg-gray-100 rounded-sm"
              onClick={() => setCollapsed((x) => !x)}
            >
              {collapsed ? <CaretDown size={12} /> : <CaretUp size={12} />}
            </Button>
          </div>
        </div>

        {!collapsed && (
          <>
            {entities}
            {props.module.autogen &&
              Object.keys(autogenModuleMap).includes(props.module.type) && (
                <>{autogenModuleMap[props.module.type]}</>
              )}

            {exhibitOpts && !props.module.autogen && (
              <>
                <div className="flex flex-wrap gap-2 p-3 border-b border-purple-400/10">
                  {exhibitOpts}
                </div>
                {exhibitList}
              </>
            )}
          </>
        )}
      </div>
      {caseStrategy && strategy && (
        <div
          className={cn(
            "border border-1 rounded-lg px-5 py-3 mr-3 col-span-1 bg-slate-50"
          )}
        >
          <div className="flex flex-row justify-between items-center border-b border-purple-400/10 py-2">
            <h1 className="text-lg text-purple-800">
              Auto-generated notes for {props.module.type}
            </h1>
          </div>
          <div className="py-2">
            {props.module.type === "memo-starter" ? (
              <div className="flex flex-col gap-y-2 text-gray-500">
                <dl className="grid grid-cols-3 text-sm border-b mb-3">
                  <dt className="col-span-1 text-sm border-b py-2 font-semibold">
                    Field of Endeavor
                  </dt>
                  <dd className="col-span-2  border-b py-2">
                    {strategy.originalPayload?.fieldOfEndeavor ?? "(Not found)"}
                  </dd>
                  <dt className="col-span-1 text-sm border-b py-2 font-semibold">
                    Filing type
                  </dt>
                  <dd className="col-span-2  border-b py-2">
                    {strategy.originalPayload.filingType || "(Not found)"}
                  </dd>
                  <dt className="col-span-1 text-sm border-b py-2 font-semibold">
                    Proposed US Role
                  </dt>
                  <dd className="col-span-2  border-b py-2">
                    {strategy.proposedUSRole || "(Not found)"}
                  </dd>
                  <dt className="col-span-1 text-sm border-b py-2 font-semibold">
                    Proposed US salary
                  </dt>
                  <dd className="col-span-2  border-b py-2">
                    {strategy.proposedUSSalary || "(Not found)"}
                  </dd>
                  <dt className="col-span-1 text-sm py-2 font-semibold">
                    Worksite location
                  </dt>
                  <dd className="col-span-2  py-2">
                    {strategy.worksiteLocation || "(Not found)"}
                  </dd>
                </dl>
                <h2 className="text-sm uppercase font-semibold text-slate-500">
                  Summary
                </h2>
                <p className="">
                  {strategy.note ?? <small>(Not found)</small>}
                </p>
                <hr />
                <h2 className="text-sm uppercase font-bold text-slate-500">
                  Confidence: {strategy.confidence}
                </h2>
                <p className="">
                  {strategy.confidenceReasoning ?? <small>(Not found)</small>}
                </p>
              </div>
            ) : (
              <div className="flex flex-col gap-y-2 text-gray-500">
                <h2 className="text-sm uppercase font-semibold text-slate-500">
                  Notes
                </h2>
                <p className="">
                  {moduleStrategy?.note ?? moduleStrategy?.notes ?? (
                    <small>(Not found)</small>
                  )}
                </p>
                <h2 className="text-sm uppercase font-bold text-slate-500">
                  Confidence: {moduleStrategy?.confidence}
                </h2>
                <p className="">
                  {moduleStrategy?.confidenceReasoning ?? (
                    <small>(Not found)</small>
                  )}
                </p>
                <hr />
                <h2 className="text-sm uppercase font-semibold text-slate-500">
                  Key points
                </h2>
                {moduleStrategy?.data?.exhibitExtractedData?.map((dt: any) => (
                  <dl
                    key={dt.id}
                    className="text-gray-500 grid grid-cols-3 border-b pb-2 gap-x-3"
                  >
                    <dt className="text-gray-700 col-span-1 text-sm font-semibold">
                      {changeCase.sentenceCase(dt.type)}
                    </dt>
                    <dd className="col-span-2 text-sm">
                      <span className="inline-block mb-[3px]">
                        {dt.extractedValue || dt.value || (
                          <span>(Not found)</span>
                        )}
                      </span>
                      <br />
                      <div className="">
                        <span className="font-semibold">Confidence:</span>{" "}
                        <span>{dt.confidence}</span>
                        <br />
                        <span>
                          <span className="font-semibold">Reason:</span>{" "}
                          {dt.confidenceReasoning}
                        </span>
                      </div>
                    </dd>
                  </dl>
                )) ?? <small className="text-gray-400">(Not found)</small>}
              </div>
            )}
          </div>
          <div className="">
            <Label
              key={`moduleNoteLabel-${props.module.type}${props.cardKey}`}
              htmlFor={`moduleNote${props.module.type}${props.cardKey}`}
              className="text-lg text-purple-800 py-2 block"
            >
              Manual notes
            </Label>
            <Textarea
              rows={10}
              key={`moduleNoteInput-${props.module.type}${props.cardKey}`}
              id={`moduleNote${props.module.type}${props.cardKey}`}
              className="bg-white"
              value={manualNotes}
              onChange={(e) => setManualNotes(e.target.value)}
              onBlur={() => doSetDataKey("notes", manualNotes)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const MemoizedModuleCard = React.memo(ModuleCard);
export default MemoizedModuleCard;
