import { StatsigClient } from "@statsig/js-client";
import { useLogError } from "@utils/error";
import { useAccountId, useContact } from "@utils/hooks";
import { PlymouthUser, Team } from "@utils/types";
import * as React from "react";
const logError = useLogError();
const STATSIG_CLIENT_KEY = import.meta.env.VITE_STATSIG_CLIENT_KEY;
const USER_NODE_ENV = import.meta.env.VITE_NODE_ENV;
const getClient = (
  contact?: PlymouthUser,
  team?: Team,
  loggedInUserId?: string,
  isAdmin?: boolean
) => {
  const instance = new StatsigClient(
    STATSIG_CLIENT_KEY,
    {
      userID: contact?.id,
      email: contact?.email,
      custom: {
        loggedInUserId,
        isAdmin,
        teamId: team?.value,
      },
    },
    {
      environment: { tier: USER_NODE_ENV },
    }
  );
  return instance;
};
export function useAsyncClient(): {
  isLoading: boolean;
  statsigClient?: StatsigClient;
} {
  const [isLoading, setIsLoading] = React.useState(true);
  const { contact, loading: isContactLoading, team } = useContact();
  const [loggedInUserId, isAdmin] = useAccountId();

  const statsigClient = React.useMemo(() => {
    const client = getClient(contact, team, loggedInUserId, isAdmin);
    client
      .initializeAsync()
      .then(() => {
        setIsLoading(false);
      })
      .catch((err: any) => {
        logError(err);
        setIsLoading(false);
      });
    return client;
  }, [contact, setIsLoading]);

  return { statsigClient, isLoading: isLoading || isContactLoading };
}
