import { useFileByIdQuery } from "@codegen/index";
import { X } from "@phosphor-icons/react";
import { Button } from "./button";
import FileViewer from "./fileViewer";
import Modal from "./modal";
import Spinner from "./spinner";

const FileViewerModal = (props: { fileId: string; onClose: () => void }) => {
  const { onClose } = props;
  const { data, loading } = useFileByIdQuery({
    variables: {
      id: props.fileId,
    },
  });

  return (
    <Modal className="h-full" onClose={props.onClose}>
      <div className="w-[700px] h-full flex flex-col justify-center">
        <div className="flex justify-between flex-col gap-y-4">
          <div className="w-full flex flex-row">
            <h1 className="text-xl">
              {data?.fileById?.name ?? `File Id: ${props.fileId}`}
            </h1>
            <Button
              className="ml-auto"
              variant={"link"}
              size={"sm"}
              onClick={onClose}
            >
              <X size={16} />
            </Button>
          </div>
        </div>

        {loading && <Spinner />}
        {data?.fileById != null && (
          <FileViewer fileId={props.fileId} mimeType={data.fileById.mimeType} />
        )}
      </div>
    </Modal>
  );
};

export default FileViewerModal;
