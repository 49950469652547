import { WorkflowModuleType } from "@codegen/enums";
import { Button } from "@components/button";
import { CTAContainer } from "@pages/onboarding/shared/cta-container";
import { OnboardingContainer } from "@pages/onboarding/shared/onboarding-container";
import { OnboardingPageType } from "@pages/onboarding/types";
import {
  AuthorshipMajorPublicationDescription,
  AwardDescription,
  CriticalRoleDescription,
  HighEnumerationDescription,
  JudgingDescription,
  MembershipDocumentationDescription,
  OriginalContributionEvidenceDescription,
  PressArticleDescription,
} from "@pages/onboarding/workflows/descriptions";
import * as changeCase from "change-case";

const copyMap: Partial<{
  [key in WorkflowModuleType]: React.ReactNode;
}> = {
  HIGH_REMUNERATION: HighEnumerationDescription,
  AUTHORSHIP: AuthorshipMajorPublicationDescription,
  AWARDS: AwardDescription,
  CRITICAL_ROLE: CriticalRoleDescription,
  EXPERT_LETTERS: (
    <p className="mb-3">
      Please share the best individuals to sign the following letters. If you
      have several individuals in mind, we recommend selecting the individual
      who will credit you for your extraordinary work, and who has personal
      insight into your professional experience and career achievements.
    </p>
  ),
  JUDGING: JudgingDescription,
  MEMBERSHIP: MembershipDocumentationDescription,
  ORIGINAL_CONTRIBUTION: OriginalContributionEvidenceDescription,
  PRESS: PressArticleDescription,
};

export const OnboardingControllerCover: React.FC<OnboardingPageType> = ({
  exhibit,
  module,
  onBack,
  onSkip,
}) => {
  const { type: moduleType } = module;
  const { name } = exhibit;
  const description = copyMap[moduleType];
  const title = changeCase.capitalCase(moduleType);
  return (
    <OnboardingContainer
      title={title}
      subtitle={name}
      progress={0}
      name={`${moduleType}-cover-page`}
    >
      {description}
      <CTAContainer onBack={onBack}>
        <Button
          variant="accent"
          className="ml-auto text-md rounded-sm px-7 py-5"
          type="button"
          onClick={onSkip}
        >
          Next
        </Button>
      </CTAContainer>
    </OnboardingContainer>
  );
};
