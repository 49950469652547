import { Button } from "@components/button";
import { CTAContainer } from "@pages/onboarding/shared/cta-container";
import { OnboardingContainer } from "@pages/onboarding/shared/onboarding-container";
import { OnboardingPageType } from "@pages/onboarding/types";
import { useLogError } from "@utils/error";
import React from "react";

export const NotFoundPage: React.FC<OnboardingPageType> = ({
  exhibit,
  module,
  onBack,
  onSkip,
}) => {
  const { type: moduleType } = module ?? {};
  const { name, type } = exhibit ?? {};
  const logError = useLogError();

  React.useEffect(() => {
    logError("Page not found", {
      name,
      moduleType,
      exhibitType: type,
      path: window.location.pathname,
    });
  }, []);

  return (
    <OnboardingContainer
      title={`:(`}
      subtitle={`Opps something went wrong.`}
      progress={0}
      name={`not-found-form`}
    >
      <div className="">
        <div className="flex flex-col gap-3">
          <p className="">
            Don't worry, engineering is trying to fix it for you!
          </p>
          <p>Please pray for them &#128591;</p>
          <p>
            You can move along by clicking <b>Next</b> below
          </p>
        </div>
      </div>
      <CTAContainer onBack={onBack}>
        <Button
          variant="accent"
          className="ml-auto text-md rounded-sm px-7 py-5"
          type="button"
          onClick={onSkip}
        >
          Next
        </Button>
      </CTAContainer>
    </OnboardingContainer>
  );
};
