import { useUpdatePetitionMutation } from "@codegen/index";
import { PetitionFragment, UpdatePetitionByIdInput } from "@codegen/schema";
import { Button } from "@components/button";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@components/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { CTAContainer } from "@pages/onboarding/shared/cta-container";
import { FieldsContainer } from "@pages/onboarding/shared/fields-container";
import { useLogError } from "@utils/error";
import * as changeCase from "change-case";
import React, { useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { z } from "zod";
import { useEmployerOnboarding } from "../../employer-onboarding";
import { EmployerOnboardingContainer } from "./employer-onboarding-container";
import { useDebounceCallback } from "@react-hook/debounce";
import { useReadOnlyMode } from "@components/readOnlyModeProvider";

const immigrationQuestionsSchema = z.object({
  hasFiledImmigrantPetition: z.enum(["Yes", "No"]),
  hasFiledNonImmigrantPetition: z.enum(["Yes", "No"]),
  basisForClassification: z.enum(["New Employer", "Change of Employer"]),
});

type ImmigrationQuestionsType = z.infer<typeof immigrationQuestionsSchema>;

type ImmigrationQuestionsProps = {
  petition: PetitionFragment;
  onSubmit: (data: ImmigrationQuestionsType) => void;
  onSave: (data: ImmigrationQuestionsType) => void;
  onBack: () => void;
};

export const ImmigrationQuestionsForm: React.FC<ImmigrationQuestionsProps> = ({
  petition,
  onSubmit,
  onBack,
  onSave,
}) => {
  const { company, onSkip } = useEmployerOnboarding();
  const { isReadOnly } = useReadOnlyMode();
  const existingMetadata = petition.metadata
    ? JSON.parse(petition.metadata)
    : {};
  const form = useForm<ImmigrationQuestionsType>({
    resolver: zodResolver(immigrationQuestionsSchema),
    defaultValues: {
      hasFiledImmigrantPetition: existingMetadata.hasFiledImmigrantPetition,
      hasFiledNonImmigrantPetition:
        existingMetadata.hasFiledNonImmigrantPetition,
      basisForClassification: existingMetadata.basisForClassification,
    },
  });

  const [updatePetitionMutation] = useUpdatePetitionMutation();
  const [saveState, setSaveState] = useState<"saving" | "error" | "idle">(
    "idle"
  );

  const watchedValues = useWatch({
    control: form.control,
  });

  const logError = useLogError();

  const handleSubmit = async (data: ImmigrationQuestionsType) => {
    try {
      setSaveState("saving");

      // Get existing metadata and merge with new data
      const newMetadata = {
        ...existingMetadata,
        hasFiledImmigrantPetition: data.hasFiledImmigrantPetition,
        hasFiledNonImmigrantPetition: data.hasFiledNonImmigrantPetition,
        basisForClassification: data.basisForClassification,
      };

      const { errors } = await updatePetitionMutation({
        variables: {
          input: {
            id: petition.id,
            petitionPatch: {
              metadata: JSON.stringify(newMetadata),
            },
          } as UpdatePetitionByIdInput,
        },
      });

      if (errors) {
        throw errors[0];
      }

      setSaveState("idle");
      onSubmit(data);
    } catch (error) {
      logError(error);
      setSaveState("error");
    }
  };

  const handleSave = async (data: ImmigrationQuestionsType) => {
    try {
      setSaveState("saving");

      // Get existing metadata and merge with new data
      const newMetadata = {
        ...existingMetadata,
        hasFiledImmigrantPetition: data.hasFiledImmigrantPetition,
        hasFiledNonImmigrantPetition: data.hasFiledNonImmigrantPetition,
        basisForClassification: data.basisForClassification,
      };

      const { errors } = await updatePetitionMutation({
        variables: {
          input: {
            id: petition.id,
            petitionPatch: {
              metadata: JSON.stringify(newMetadata),
            },
          } as UpdatePetitionByIdInput,
        },
      });

      if (errors) {
        throw errors[0];
      }

      onSave(data);
      setSaveState("idle");
    } catch (error) {
      logError(error);
      setSaveState("error");
    }
  };

  const debouncedSave = useDebounceCallback(handleSave, 300);

  React.useEffect(() => {
    debouncedSave(watchedValues);
  }, [watchedValues, debouncedSave]);

  const RadioInput = ({
    name,
    label,
    value,
    disabled,
  }: {
    name:
      | "hasFiledImmigrantPetition"
      | "hasFiledNonImmigrantPetition"
      | "basisForClassification";
    label: string;
    value: string;
    disabled?: boolean;
  }) => {
    const id = React.useId();
    return (
      <div className="flex items-center gap-2">
        <FormControl className="flex items-center gap-2">
          <input
            id={id}
            {...form.register(name)}
            disabled={disabled}
            type="radio"
            value={value}
            className="w-4 h-4"
          />
        </FormControl>
        <FormLabel className="text-primary" htmlFor={id}>
          {label}
        </FormLabel>
      </div>
    );
  };

  return (
    <EmployerOnboardingContainer
      title={`Immigration Questions`}
      subtitle={`Please answer the following immigration-related questions`}
      form={form}
      onSubmit={form.handleSubmit(handleSubmit)}
      name="immigration_questions"
      progress={0}
    >
      <FieldsContainer status="idle" className="space-y-3">
        <FormField
          control={form.control}
          name="hasFiledImmigrantPetition"
          render={({ field }) => (
            <FormItem className="space-y-3">
              <FormLabel>
                Have you ever filed for an <b>immigrant (green card)</b>{" "}
                petition for <b>{petition.userByBeneficiaryId?.fullName}</b>?
              </FormLabel>
              <RadioInput
                name="hasFiledImmigrantPetition"
                label="Yes"
                value="Yes"
                disabled={isReadOnly}
              />
              <RadioInput
                name="hasFiledImmigrantPetition"
                label="No"
                value="No"
                disabled={isReadOnly}
              />
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="hasFiledNonImmigrantPetition"
          render={() => (
            <FormItem className="space-y-3">
              <FormLabel>
                Have you ever previously filed a <b>NON-immigrant</b> petition
                for <b>{petition.userByBeneficiaryId?.fullName}</b>?
              </FormLabel>
              <RadioInput
                name="hasFiledNonImmigrantPetition"
                label="Yes"
                value="Yes"
                disabled={isReadOnly}
              />
              <RadioInput
                name="hasFiledNonImmigrantPetition"
                label="No"
                value="No"
                disabled={isReadOnly}
              />
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="basisForClassification"
          render={({ field }) => (
            <FormItem className="space-y-3">
              <FormLabel>
                What is the basis for classification of your company?
              </FormLabel>
              <div className="space-y-2">
                <RadioInput
                  name="basisForClassification"
                  label="New Employer"
                  value="New Employer"
                  disabled={isReadOnly}
                />
                <p className="text-sm text-gray-500 pl-6">
                  The individual is either outside the U.S. and holds no visa
                  classification, OR if s/he is inside the U.S. but in a
                  different non-immigrant status (such as F1, J1, TN, etc.).
                </p>
              </div>
              <div className="space-y-2">
                <RadioInput
                  name="basisForClassification"
                  label="Change of Employer"
                  value="Change of Employer"
                  disabled={isReadOnly}
                />
                <p className="text-sm text-gray-500 pl-6">
                  This individual is already working on a{" "}
                  {changeCase.trainCase(petition.visaClass)} for another
                  employer and will NOT work for that employer once they begin
                  working for {company.label}
                </p>
              </div>
              <FormMessage />
            </FormItem>
          )}
        />
      </FieldsContainer>
      <CTAContainer onBack={onBack} onSkip={onSkip}>
        <Button
          className="ml-auto text-md rounded-sm px-7 py-5"
          variant="accent"
          type="submit"
        >
          Next
        </Button>
      </CTAContainer>
    </EmployerOnboardingContainer>
  );
};
