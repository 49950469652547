import { ImmigrationDocumentType } from "@codegen/schema";
import { OnboardingPageType } from "@pages/onboarding/types";
import React from "react";
import { z } from "zod";
import { ImmigrationDocumentForm } from "./file-form";
export const passportFormSchema = z.object({
  passports: z.string().min(1, "Please upload your passport"),
});

export type PassportFormType = z.infer<typeof passportFormSchema>;
export const PassportForm: React.FC<OnboardingPageType> = (props) => {
  return (
    <ImmigrationDocumentForm
      {...props}
      title={"Identification information"}
      subtitle={
        "Upload a copy of your passport. If you have multiple, select only one for identity verification."
      }
      cardTitle={"Upload passport"}
      name={`passport-form`}
      fileType={ImmigrationDocumentType.Passport}
    />
  );
};

export default PassportForm;
