import { WorkflowModule } from "@codegen/enums";
import { Petition } from "@codegen/schema";
import { TaskProgress } from "@components/ProcessTracker";
import { LoadingTasks } from "@pages/onboarding/shared/loading";
import { useBeneficiaryWorkflowContext } from "@pages/onboarding/workflows/provider";
import {
  BookOpenUser,
  Briefcase,
  CheckFat,
  Clipboard,
  CurrencyCircleDollar,
  FileText,
  IdentificationBadge,
  Info,
  Lightbulb,
  Newspaper,
  PenNib,
  RocketLaunch,
  Scales,
  Square,
  Stamp,
  Trophy,
  UserCircle,
} from "@phosphor-icons/react";
import { cn } from "@utils/cn";
import { StrategyModule } from "@utils/types";
import * as changeCase from "change-case";
import * as React from "react";
import { Link, useLocation } from "react-router-dom";

type ModuleListType = {
  id: string;
  title: string;
  subtitle: string;
  icon: typeof UserCircle;
  type: StrategyModule["type"];
};

const copy: ModuleListType[] = [
  {
    id: "1",
    type: "PERSONAL",
    title: "Personal Information",
    subtitle: "Provide your basic personal details.",
    icon: IdentificationBadge,
  },
  {
    id: "2",
    type: "IDENTIFICATION",
    title: "Identification Documents",
    subtitle: "Submit your passport and visa details.",
    icon: Stamp,
  },
  {
    id: "3",
    type: "EMPLOYMENT",
    title: "Professional Details",
    subtitle: "Enter your employment history.",
    icon: Briefcase,
  },
  {
    id: "4",
    type: "FILING_TYPE",
    title: "Filing Type",
    subtitle:
      "Specify your filing type: Change of Status or Consular Processing.",
    icon: Clipboard,
  },
  {
    id: "5",
    type: "HIGH_REMUNERATION",
    title: "High Remuneration",
    subtitle: "Submit details of past, current, and future high-paying jobs.",
    icon: CurrencyCircleDollar,
  },
  {
    id: "6",
    type: "CRITICAL_ROLE",
    title: "Critical Role at Distinguished Organization",
    subtitle: `Share evidence of a critical  role in organizations with a stellar reputation.`,
    icon: RocketLaunch,
  },
  {
    id: "7",
    type: "MEMBERSHIP",
    title: "Memberships",
    subtitle: "Share your memberships in exclusive professional associations.",
    icon: BookOpenUser,
  },
  {
    id: "8",
    type: "AUTHORSHIP",
    title: "Authorship",
    subtitle:
      "Submit evidence of your contributions as an author of significant publications.",
    icon: FileText,
  },
  {
    id: "9",
    type: "JUDGING",
    title: "Judging",
    subtitle: "Show evidence of your judging roles and expertise.",
    icon: Scales,
  },
  {
    id: "10",
    type: "AWARDS",
    title: "Awards",
    subtitle: "Share your notable awards and recognitions.",
    icon: Trophy,
  },
  {
    id: "11",
    type: "ORIGINAL_CONTRIBUTION",
    title: "Original Contributions",
    subtitle: "Showcase your groundbreaking work and innovations.",
    icon: Lightbulb,
  },
  {
    id: "12",
    type: "PRESS",
    title: "Press",
    subtitle: "Share press links or publications about you.",
    icon: Newspaper,
  },
  {
    id: "13",
    type: "EXPERT_LETTERS",
    title: "Expert Letters",
    subtitle: "Provide letters of recommendation from experts.",
    icon: PenNib,
  },
];

const Item = ({ status, type, id, exhibits }: StrategyModule) => {
  const placeholder = copy.find((i) => i.type === type);

  const location = useLocation();

  if (!placeholder) {
    return null;
  }

  if (exhibits.length === 0) {
    return null;
  }

  const Icon = placeholder?.icon;
  const title = placeholder?.title;
  const subtitle = placeholder?.subtitle;
  const isCompleted = status === "completed";
  const isInprgress = status === "in_progress";

  return (
    <Link
      to={{
        pathname: `/onboarding/beneficiary/${changeCase.kebabCase(type)}`,
        search: location.search ? location.search + `&id=${id}` : `?id=${id}`,
      }}
      className={cn(
        "flex gap-3 items-center border rounded-sm p-3 bg-card hover:bg-gray-50 hover:cursor-pointer animate-in slide-in-from-bottom-9 duration-300",
        isCompleted && `border shadow border-blue-600`,
        isInprgress && "border-yellow-300 border shadow-xl"
      )}
    >
      {isCompleted ? (
        <CheckFat weight="fill" size={24} className={`text-blue-600`} />
      ) : (
        <Square weight="regular" size={24} className="text-gray-300" />
      )}
      {Icon && (
        <Icon
          weight="duotone"
          size={40}
          className={cn("text-accent", isCompleted && `text-blue-600`)}
        />
      )}
      <div className="flex justify-between w-full items-center">
        <div className="">
          <div className="font-semibold text-primary">{title}</div>
          <div className="text-secondary-foreground">{subtitle}</div>
        </div>
        <div
          className={cn(
            "text-gray-400",
            isCompleted && `text-blue-600 font-semibold`
          )}
        >
          {changeCase.capitalCase(status)}
        </div>
      </div>
    </Link>
  );
};

const buildMyTaskProgress = (modules: WorkflowModule[]): TaskProgress[] => {
  // Group PERSONAL, IDENTIFICATION, EMPLOYMENT, FILING_TYPE to one task
  const personalModules = modules.filter(
    (module) =>
      module.type === "PERSONAL" ||
      module.type === "IDENTIFICATION" ||
      module.type === "EMPLOYMENT" ||
      module.type === "FILING_TYPE"
  );

  const totalPersonalExhibits = personalModules.reduce(
    (acc, module) => acc + module.exhibits.length,
    0
  );

  const completedPersonalExhibits = personalModules.reduce(
    (acc, module) =>
      acc +
      module.exhibits.filter((exhibit) => exhibit.status === "completed")
        .length,
    0
  );

  const personalTask: TaskProgress = {
    title: "Personal Details",
    completed: completedPersonalExhibits,
    total: totalPersonalExhibits,
    status: personalModules.every((module) => module.status === "completed")
      ? "Done"
      : "Pending",
    statusStyle: "percentage",
  };

  const expertLetterModules = modules.filter(
    (module) => module.type === "EXPERT_LETTERS"
  );

  const totalExpertLetterExhibits = expertLetterModules.reduce(
    (acc, module) => acc + module.exhibits.length,
    0
  );

  const completedExpertLetterExhibits = expertLetterModules.reduce(
    (acc, module) =>
      acc +
      module.exhibits.filter((exhibit) => exhibit.status === "completed")
        .length,
    0
  );

  const expertLetterTask: TaskProgress = {
    title: "Expert Letter Information",
    completed: completedExpertLetterExhibits,
    total: totalExpertLetterExhibits,
    status:
      completedExpertLetterExhibits === totalExpertLetterExhibits
        ? "Done"
        : "Pending",
    statusStyle: "fraction",
  };

  const uploadCaseEvidenceModules = modules.filter(
    (module) =>
      module.type !== "PERSONAL" &&
      module.type !== "IDENTIFICATION" &&
      module.type !== "EMPLOYMENT" &&
      module.type !== "FILING_TYPE" &&
      module.type !== "EXPERT_LETTERS"
  );

  const totalUploadCaseEvidenceExhibits = uploadCaseEvidenceModules.reduce(
    (acc, module) =>
      acc + module.exhibits.filter((exhibit) => exhibit.required).length,
    0
  );

  const completedUploadCaseEvidenceExhibits = uploadCaseEvidenceModules.reduce(
    (acc, module) =>
      acc +
      module.exhibits.filter(
        (exhibit) => exhibit.required && exhibit.status === "completed"
      ).length,
    0
  );
  const uploadCaseEvidenceTask: TaskProgress = {
    title: "Upload Case Evidence",
    completed: completedUploadCaseEvidenceExhibits,
    total: totalUploadCaseEvidenceExhibits,
    status:
      completedUploadCaseEvidenceExhibits === totalUploadCaseEvidenceExhibits
        ? "Done"
        : "Pending",
    statusStyle: "percentage",
  };

  return [personalTask, expertLetterTask, uploadCaseEvidenceTask];
};

const buildPlymouthTaskProgress = (
  petition: Petition | null,
  modules: WorkflowModule[]
): TaskProgress[] => {
  console.log(petition);
  // const petitionStatus: "KICK-OFF" | "LETTERS" | "FORMS" | "COMPILE" =
  //   (petition?.projectManagementStatus as
  //     | "KICK-OFF"
  //     | "LETTERS"
  //     | "FORMS"
  //     | "COMPILE") ?? "KICK-OFF";

  const petitionStatus = "LETTERS";

  return [
    {
      title: "Draft Forms",
      completed:
        petitionStatus === "KICK-OFF"
          ? 0
          : petitionStatus === "FORMS"
          ? 50
          : 100,
      total: 100,
      status:
        petitionStatus === "KICK-OFF"
          ? "Not Started"
          : petitionStatus === "FORMS"
          ? "In Progress"
          : "Shared with you",
      statusStyle: "hidden",
    } as TaskProgress,
    {
      title: "Draft Letters",
      completed:
        petitionStatus === "KICK-OFF" || petitionStatus === "FORMS"
          ? 0
          : petitionStatus === "LETTERS"
          ? 50
          : 100,
      total: 100,
      status:
        petitionStatus === "KICK-OFF" || petitionStatus === "FORMS"
          ? "Not Started"
          : petitionStatus === "LETTERS"
          ? "In Progress"
          : "Shared with you",
      statusStyle: "hidden",
    } as TaskProgress,
    {
      title: "Compile Petition",
      completed:
        petitionStatus === "KICK-OFF" ||
        petitionStatus === "FORMS" ||
        petitionStatus === "LETTERS"
          ? 0
          : 50,
      total: 100,
      status:
        petitionStatus === "KICK-OFF" ||
        petitionStatus === "FORMS" ||
        petitionStatus === "LETTERS"
          ? "Not Started"
          : petitionStatus === "COMPILE"
          ? "In Progress"
          : "Shared with you",
      statusStyle: "hidden",
    } as TaskProgress,
  ];
};

const ChecklistPage = () => {
  const { onboarding, refetchOnboarding, petition } =
    useBeneficiaryWorkflowContext();
  React.useEffect(() => {
    refetchOnboarding();
  }, []);

  const modules = React.useMemo(
    () => (onboarding?.modules ? JSON.parse(onboarding.modules) : []),
    [onboarding?.modules]
  );

  const yourTasks: TaskProgress[] = React.useMemo(
    () => buildMyTaskProgress(modules),
    [modules]
  );

  const plymouthTasks: TaskProgress[] = React.useMemo(
    () => buildPlymouthTaskProgress(petition, modules),
    [petition, modules]
  );

  return (
    <div className="">
      <div className="flex flex-col h-screen overflow-scroll w-full relative">
        <div className="md:flex gap-8">
          <div className="flex-1 mx-auto max-w-3xl py-10 px-3">
            <h1 className="text-3xl mb-3">Onboarding checklist</h1>
            <p className="mb-6 text-primary">
              Complete your onboarding by providing essential details across key
              modules. Each section is designed to capture crucial information
              to support your petition.
            </p>
            {modules.length > 0 ? (
              <ul className="flex flex-col gap-6">
                {modules
                  .map((item: WorkflowModule) => (
                    <Item key={item.id} {...item} />
                  ))
                  .filter((item: React.ReactNode) => Boolean(item))}
              </ul>
            ) : (
              <LoadingTasks count={6} />
            )}
          </div>
          <div className="hidden md:flex w-[350px] shadow p-6 items-center justify-center flex-col h-screen sticky top-0">
            <div className="absolute inset-0 bg-gradient-to-br from-sky-100 via-rose-100 to-lime-100 blur-md"></div>
            <div className="relative z-10 flex flex-col gap-y-20">
              <div className="flex flex-col gap-y-2 items-center">
                <Info size={20} className="text-accent" />
                <p className="text-center">
                  {" "}
                  All of your uploaded files will be comprehensively reviewed by
                  our legal operations team. Please upload any similar files to
                  the documents requested.
                </p>
              </div>
              <div className="flex flex-col gap-2 items-center">
                <img
                  className="rounded-full w-12 h-12 shadow-md"
                  src="https://ca.slack-edge.com/T052H6ADCN7-U052HA6Q6AW-bd19ebc72343-512"
                  title="Picture of Lisa"
                />
                <h1 className="text-lg font-semibold">Visa question?</h1>
                <h2 className="text-lg mb-5">Lisa has an answer for you.</h2>
                {/* <p className="text-primary">Lisa can answer any question you have about your petition.</p> */}
                <a
                  href="mailto:operations@plymouthstreet.com"
                  className="font-semibold bg-accent text-accent-foreground px-5 py-2 rounded-sm text-sm hover:bg-blue-500"
                >
                  Ask Lisa
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChecklistPage;
