import { api } from "@utils/api";
import { useJwt } from "@utils/hooks";
import { useState } from "react";
import Markdown from "react-markdown";
import useAsyncEffect from "use-async-effect";
import Spinner from "./spinner";

const rawMimeTypes = ["text/plain", "text/html", "text/markdown"];

const FileViewer = (props: { fileId: string; mimeType: string }) => {
  const { fileId, mimeType } = props;
  const [linkOk, setLinkOk] = useState<boolean>(true);
  const [link, setLink] = useState<string | null>(null);
  const [rawText, setRawText] = useState<string | null>(null);

  const token = useJwt();

  useAsyncEffect(async () => {
    if (token == null) return;
    if (fileId == null) return;

    const url = await api.files.getFileUrl(fileId, token);
    if (url == null) return;
    setLink(url);

    try {
      const res = await fetch(url, {
        method: "GET",
      });

      if (res.ok) {
        setLinkOk(true);
        if (rawMimeTypes.includes(mimeType)) {
          const text = await res.text();
          setRawText(text);
        }
      } else {
        console.error("error fetching file");
        setLinkOk(false);
      }
    } catch (e) {
      console.error(e);
      setLinkOk(false);
    }
  }, [token]);

  return (
    <div className="w-full h-full text-sm">
      {link == null ? (
        <Spinner />
      ) : linkOk ? (
        <div className="flex w-full h-full items-center">
          {mimeType === "application/pdf" ? (
            <iframe
              key={link}
              className="w-full h-full pb-10"
              src={link}
              style={{ border: "none" }}
            />
          ) : ["image/png", "image/jpeg", "image/jpg"].includes(
              mimeType ?? ""
            ) ? (
            <img className="max-w-full max-h-full" src={link} />
          ) : rawMimeTypes.includes(mimeType) ? (
            <Markdown className="prose p-4 h-full w-full overflow-scroll">
              {rawText ?? "Failed to load file contents"}
            </Markdown>
          ) : (
            <div>
              <a className="text-accent underline" href={link} download>
                Download File
              </a>
            </div>
          )}
        </div>
      ) : (
        <div className="w-full h-full">Failed to load file</div>
      )}
    </div>
  );
};

export default FileViewer;
