import { UnexpectedErrorPage } from "@pages/onboarding/shared/unexpected-error";
import * as Sentry from "@sentry/react";
import React, { Component } from "react";

class ErrorBoundary extends Component<{
  onSkip?: () => void;
  children: React.ReactNode;
  exitPath?: string;
  onBack?: () => void;
  ErrorComponent?: React.ReactNode;
}> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Uncaught error:", error, errorInfo);
    Sentry.captureException(error, {
      extra: {
        errorInfo,
      },
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        this.props.ErrorComponent ?? (
          <UnexpectedErrorPage
            onSkip={this.props.onSkip}
            exitPath={this.props.exitPath}
            onBack={this.props.onBack}
          />
        )
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
