import { WorkflowAwardExhibit } from "@codegen/enums";
import { useAllUserDocumentsByTypeQuery } from "@codegen/index";
import { IndividualDocumentType } from "@codegen/schema";
import { Button } from "@components/button";
import { FormControl, FormItem, FormLabel } from "@components/form";
import { useReadOnlyMode } from "@components/readOnlyModeProvider";
import Spinner from "@components/spinner";
import { Textarea } from "@components/textArea";
import Uploader from "@components/uploader/uploader";
import { ErrorMessage } from "@hookform/error-message";
import { zodResolver } from "@hookform/resolvers/zod";
import { CTAContainer } from "@pages/onboarding/shared/cta-container";
import { FormErrorMessage } from "@pages/onboarding/shared/error";
import { FieldsContainer } from "@pages/onboarding/shared/fields-container";
import { OnboardingContainer } from "@pages/onboarding/shared/onboarding-container";
import { WaitingSplash } from "@pages/onboarding/shared/waiting";
import { OnboardingPageType } from "@pages/onboarding/types";
import { useDebounceCallback } from "@react-hook/debounce";
import { api } from "@utils/api";
import { useLogError } from "@utils/error";
import { useJwt } from "@utils/hooks";
import * as changeCase from "change-case";
import * as React from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { z } from "zod";

export const fileFormSchema = z.object({
  description: z.string().min(1, "Required"),
  files: z.string().array().min(1, "Required"),
});

export type FileFormWithDescriptionType = z.infer<typeof fileFormSchema>;
export const FileWithLinksForm: React.FC<
  OnboardingPageType & {
    fileType: IndividualDocumentType;
    title: string;
    subtitle: React.ReactNode;
    cardTitle: React.ReactNode;
    descriptionLabel: React.ReactNode;
    userDocuments?:
      | {
          documentType: IndividualDocumentType;
          fileByFileId: { id: string; name: string } | null;
        }[]
      | null;
  }
> = ({
  contact,
  onBack,
  onSubmit,
  autoSave,
  onSkip,
  isSaving,
  fileType,
  title,
  subtitle,
  cardTitle,
  footnote,
  descriptionLabel,
  exhibit,
  userDocuments,
  onSkipForNow,
}) => {
  const { required, data } = exhibit;
  const token = useJwt();
  const logError = useLogError();
  const { description }: { description?: string; files?: string[] } =
    (data as FileFormWithDescriptionType) ?? {
      description: "",
    };
  const existingFiles =
    userDocuments?.map((node) => node?.fileByFileId).filter((n) => !!n) ?? [];

  const { isReadOnly } = useReadOnlyMode();
  const methods = useForm({
    defaultValues: {
      description: description,
      files: existingFiles?.map((f) => f.id),
    },
    resolver: zodResolver(fileFormSchema),
  });

  const handleSave = (data?: FileFormWithDescriptionType) => {
    if (data?.description && data?.files) {
      autoSave(data);
    }
  };

  const handleSubmit = (data?: FileFormWithDescriptionType) => {
    onSubmit(data);
  };

  const { formState, register, getValues, setValue, control } = methods;

  const watchedValues = useWatch({
    control,
  });

  const debouncedSave = useDebounceCallback(handleSave, 300);

  React.useEffect(() => {
    debouncedSave(watchedValues);
  }, [watchedValues, debouncedSave]);

  const onFileUpload = React.useCallback(
    async (file: File) => {
      try {
        const data = await api.files.uploadIndividualDoc(
          file,
          token,
          contact.id,
          fileType
        );

        const { id } = data;
        const files = getValues("files");
        setValue("files", [...files, id]);

        return {
          success: data.success,
          link: "",
          fileId: id,
        };
      } catch (exception) {
        logError(exception);
        return {
          success: false,
          link: "",
        };
      }
    },
    [contact.id, token]
  );

  return (
    <FormProvider {...methods}>
      <OnboardingContainer
        title={title}
        subtitle={subtitle}
        cardTitle={cardTitle}
        progress={0}
        onSubmit={handleSubmit}
        exhibit={exhibit}
        name={`${changeCase.kebabCase(fileType)}-form`}
        footnote={footnote}
      >
        <FieldsContainer status={""}>
          <div>
            {existingFiles == null && <Spinner />}
            {existingFiles != null && (
              <Uploader
                multiple
                onFileUpload={onFileUpload}
                disabled={isReadOnly}
                defaultFiles={existingFiles.map((x) => ({
                  id: x.id ?? "",
                  name: x.name ?? "",
                }))}
              />
            )}
            <ErrorMessage
              errors={formState.errors}
              name="files"
              render={({ message }) => (
                <FormErrorMessage>{message}</FormErrorMessage>
              )}
            />
          </div>
          <FormItem className="mt-3">
            <FormLabel>{descriptionLabel}</FormLabel>
            <FormControl>
              <Textarea
                {...register("description")}
                disabled={isReadOnly}
                className="bg-white"
                placeholder="Enter text here..."
                rows={6}
              />
            </FormControl>
            <ErrorMessage
              errors={formState.errors}
              name="description"
              render={({ message }) => (
                <FormErrorMessage>{message}</FormErrorMessage>
              )}
            />
          </FormItem>
        </FieldsContainer>
        <CTAContainer
          onBack={onBack}
          onSkip={required ? () => onSkipForNow?.() : onSkip}
        >
          <Button
            variant="accent"
            className="ml-auto text-md rounded-sm px-7 py-5"
            // disabled={!formState.isValid || formState.isValidating}
            type="submit"
          >
            {isSaving ? <Spinner /> : "Next"}
          </Button>
        </CTAContainer>
      </OnboardingContainer>
    </FormProvider>
  );
};

export const FileWithLinksFormController: React.FC<
  OnboardingPageType & {
    fileType: IndividualDocumentType;
    title: string;
    subtitle: React.ReactNode;
    cardTitle: React.ReactNode;
    descriptionLabel: React.ReactNode;
  }
> = (props) => {
  const { exhibit, module, contact, fileType } = props;
  const { data: userData, loading } = useAllUserDocumentsByTypeQuery({
    variables: {
      userId: contact.id,
      documentType: fileType,
    },
  });

  if (loading) {
    return <WaitingSplash />;
  }

  return (
    <FileWithLinksForm
      {...props}
      userDocuments={userData?.allUserDocuments?.nodes?.filter((n) => !!n)}
    />
  );
};

export const AwardDocumentsForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props;
  const { data, name, description, award } = exhibit as WorkflowAwardExhibit;
  const { name: moduleName } = module;
  return (
    <FileWithLinksFormController
      {...props}
      subtitle={name}
      cardTitle={
        "Please upload evidence of your receipt of the award below. This could be a formal email, letter, weblink showing your acceptance, or a photo of your award. "
      }
      title={`${moduleName}${award ? ` - ${award.awardName}` : ""}`}
      fileType={IndividualDocumentType.AwardEvidence}
      descriptionLabel={`Can you tell us about the award and why it’s a big deal in your field?`}
    />
  );
};
