import { ReactNode } from "react";

interface WelcomeLayoutProps {
  children: ReactNode;
  image?: string;
  imagePosition?: "right" | "left";
}

export function WelcomeLayout({
  children,
  image,
  imagePosition = "right",
}: WelcomeLayoutProps) {
  return (
    <div className="flex min-h-screen w-full">
      {/* SVG Mask definitions */}
      <svg width="0" height="0" className="absolute">
        <defs>
          <clipPath id="curved-edge-right" clipPathUnits="objectBoundingBox">
            <path d="M0 0 H1 V1 H0 C0.1 0.75 0.1 0.25 0 0" />
          </clipPath>
          <clipPath id="curved-edge-left" clipPathUnits="objectBoundingBox">
            <path d="M1 0 H0 V1 H1 C0.9 0.75 0.9 0.25 1 0" />
          </clipPath>
        </defs>
      </svg>

      {/* White background that extends beyond content */}
      <div
        className={`absolute top-0 bottom-0 bg-white ${
          imagePosition === "right"
            ? "left-0 right-[40%]"
            : "left-[40%] right-0"
        }`}
      />

      {/* Content section */}
      <div
        className={`relative flex min-h-screen ${
          imagePosition === "right" ? "w-[45%]" : "w-[45%] order-last"
        }`}
      >
        <div className="w-full flex items-center p-12">
          <div className="max-w-xl">{children}</div>
        </div>
      </div>

      {/* Image section */}
      {image && (
        <div
          className={`flex-1 bg-cover bg-center bg-no-repeat ${
            imagePosition === "right"
              ? "[clip-path:url(#curved-edge-right)]"
              : "[clip-path:url(#curved-edge-left)]"
          }`}
          style={{ backgroundImage: `url(${image})` }}
        />
      )}
    </div>
  );
}
