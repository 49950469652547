import { Combobox } from "./combobox";

const monthValues = [
  { label: "January", value: "january" },
  { label: "February", value: "february" },
  { label: "March", value: "jarch" },
  { label: "April", value: "april" },
  { label: "May", value: "may" },
  { label: "June", value: "june" },
  { label: "July", value: "july" },
  { label: "August", value: "august" },
  { label: "September", value: "september" },
  { label: "October", value: "october" },
  { label: "November", value: "november" },
  { label: "December", value: "december" },
];

export const MonthSelector = (props: {
  selectedValue?: string;
  onSelect?: (month: string) => void;
  className?: string;
  disabled?: boolean;
}) => {
  const { selectedValue, onSelect, disabled, className } = props;

  return (
    <Combobox
      options={monthValues}
      defaultValue={selectedValue ?? ""}
      onSelect={(e) => onSelect?.(e)}
      placeholder="Select month"
      className={className}
      disabled={disabled}
    />
  );
};

const getYears = (from: number, to: number) => {
  let current = from;
  let years = [];
  while (current <= to) {
    years.push({ label: current.toString(), value: current.toString() });
    current++;
  }

  return years;
};
const yearValues = getYears(1950, new Date().getFullYear());

export const YearSelector = (props: {
  selectedValue?: string;
  onSelect?: (year: string) => void;
  className?: string;
  disabled?: boolean;
}) => {
  const { selectedValue, onSelect, disabled, className } = props;
  return (
    <Combobox
      options={yearValues}
      defaultValue={selectedValue ?? ""}
      onSelect={(e) => onSelect?.(e)}
      placeholder="Select month"
      className={className}
      disabled={disabled}
    />
  );
};
