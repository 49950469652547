import {
  useAllCompanyOfficesQuery,
  useCreateCompanyOfficeMutation,
} from "@codegen/index";
import { CompanyOffice, CreateCompanyOfficeInput } from "@codegen/schema";
import { Button } from "@components/button";
import { FormControl, FormField, FormItem, FormLabel } from "@components/form";
import CountrySelect from "@components/formCountrySelect";
import { Input } from "@components/input";
import { useReadOnlyMode } from "@components/readOnlyModeProvider";
import { ErrorMessage } from "@hookform/error-message";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormLoading } from "@pages/onboarding/beneficiary/forms/loading";
import { useEmployerOnboarding } from "@pages/onboarding/employer-onboarding";
import { EmployerOnboardingContainer } from "@pages/onboarding/employer/forms/employer-onboarding-container";
import { CTAContainer } from "@pages/onboarding/shared/cta-container";
import { FormErrorMessage } from "@pages/onboarding/shared/error";
import { FieldsContainer } from "@pages/onboarding/shared/fields-container";
import { X } from "@phosphor-icons/react";
import { useDebounceCallback } from "@react-hook/debounce";
import { UNIT_TYPES, unitTypeOptions } from "@utils/address";
import { useLogError } from "@utils/error";
import * as React from "react";
import { useCallback, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { z } from "zod";

const companyAddressSchema = z
  .object({
    street: z.string().min(1, { message: "Required" }),
    unitType: z.string().optional(),
    unitNumber: z.string().optional(),
    city: z.string().min(1, { message: "Required" }),
    state: z.string().min(1, { message: "Required" }),
    country: z.string().min(1, { message: "Required" }),
    zip: z.string().min(1, { message: "Required" }),
    label: z.string().min(1, { message: "Required" }),
  })
  .refine((input) => {
    if (!input.unitType) return true;
    if (
      input.unitType &&
      UNIT_TYPES.includes(input.unitType) &&
      input.unitNumber &&
      input.unitNumber?.length > 0
    ) {
      return true;
    }
    return Promise.reject({
      message: "Unit number is required",
      path: ["unitNumber"],
    });
  });

export type CompanyAddressValues = z.infer<typeof companyAddressSchema>;
export type OfficeAddress = Pick<
  CompanyOffice,
  | "id"
  | "label"
  | "postalCode"
  | "state"
  | "street"
  | "street2"
  | "country"
  | "city"
  | "isEmployeeWorksite"
>;
export const AddBeneficiaryWorksiteForm = () => {
  const { onSubmit, company, onBack, onSkip, onSave } = useEmployerOnboarding();
  const [status, setStatus] = useState<"idle" | "loading" | "error">("idle");
  const [showForm, setShowForm] = useState(false);

  const { isReadOnly } = useReadOnlyMode();

  const [createOffice] = useCreateCompanyOfficeMutation();
  const {
    data: officeData,
    loading: officeLoading,
    refetch,
  } = useAllCompanyOfficesQuery({
    variables: {
      id: company.value,
    },
  });
  const existingWorksites =
    officeData?.companyById?.companyOfficesByCompanyId?.nodes;
  const hasExistingWorksites =
    existingWorksites && existingWorksites.length > 0;
  const form = useForm<CompanyAddressValues>({
    defaultValues: {
      country: "US",
    },
    resolver: zodResolver(companyAddressSchema),
  });
  const { formState } = form;
  const logError = useLogError();
  const doSubmitCompanyAddress = useCallback(
    async (data: CompanyAddressValues) => {
      if (company === null) {
        throw "Company is not provided";
      }
      return await createOffice({
        variables: {
          input: {
            clientMutationId: "",
            companyOffice: {
              companyId: parseInt(company.value),
              city: data.city,
              country: data.country,
              state: data.state,
              street: data.street,
              unitType: data.unitType ? data.unitType : null,
              unitNumber: data.unitNumber ? data.unitNumber : null,
              postalCode: data.zip,
              label: data.label,
              isEmployeeWorksite: true,
            } as CreateCompanyOfficeInput["companyOffice"],
          },
        },
      });
    },
    [company]
  );

  const watchedValues = useWatch({
    control: form.control,
  });

  const handleSubmit = async () => {
    try {
      try {
        await form.trigger();
      } catch (e: any) {
        if (e?.path && e.path?.length > 0) {
          form.setError(e.path[0], {
            message: e.message,
          });
        }
        setStatus("error");
        return;
      }

      const values = form.getValues();
      if (showForm) {
        setStatus("loading");
        const { errors } = await doSubmitCompanyAddress(values);
        if (errors) {
          throw errors[0];
        }
        refetch();
        onSubmit({ data: values, key: "newWorksiteAddress" });
      } else if (
        !showForm &&
        existingWorksites &&
        existingWorksites.length > 0
      ) {
        onSubmit({ data: existingWorksites[0], key: "newWorksiteAddress" });
      } else {
        throw new Error("Invalid form");
      }
    } catch (e) {
      const values = form.getValues();
      setStatus("error");
      logError(e, { values });
    }
  };

  const handleSave = async () => {
    try {
      try {
        await form.trigger();
      } catch (e: any) {
        if (e?.path && e.path?.length > 0) {
          form.setError(e.path[0], {
            message: e.message,
          });
        }
        setStatus("error");
        return;
      }

      const values = form.getValues();
      if (showForm) {
        setStatus("loading");
        const { errors } = await doSubmitCompanyAddress(values);
        if (errors) {
          throw errors[0];
        }
        // refetch();
        onSave({ data: values, key: "newWorksiteAddress" });
        setStatus("idle");
      } else if (
        !showForm &&
        existingWorksites &&
        existingWorksites.length > 0
      ) {
        setStatus("loading");
        onSave({ data: existingWorksites[0], key: "newWorksiteAddress" });
        setStatus("idle");
      } else {
        throw new Error("Invalid form");
      }
    } catch (e) {
      const values = form.getValues();
      setStatus("error");
      logError(e, { values });
    }
  };

  const debouncedSave = useDebounceCallback(handleSave, 300);

  React.useEffect(() => {
    debouncedSave(watchedValues);
  }, [watchedValues, debouncedSave]);

  React.useEffect(() => {
    if (!officeLoading && !hasExistingWorksites) {
      setShowForm(true);
    }
  }, [officeLoading, hasExistingWorksites, setShowForm]);

  if (officeLoading) {
    return <FormLoading />;
  }

  return (
    <EmployerOnboardingContainer
      title="Company and Worksite Address"
      cardTitle={
        "Please add the worksite address for the beneficiary — you cannot include a PO Box for the address."
      }
      progress={0}
      form={form}
      onSubmit={handleSubmit}
      name="add_worksite"
    >
      <div className="text-sm text-gray-500 mb-3">
        <b>
          For Founders and small companies (25 full time employees or less):
        </b>{" "}
        If you have provided a Delaware address or a residential address for the
        company mailing address, please ensure that you have secured an office
        or co-working site (e.g, a WeWork) address{" "}
        <b>prior to the targeted filing date</b> of your visa petition.
      </div>
      {existingWorksites && existingWorksites.length > 0 && (
        <div>
          <FormLabel className="mb-2">Existing worksites</FormLabel>
          <ul>
            {existingWorksites.map((of) => (
              <li
                key={of?.id}
                className="bg-white border rounded px-3 py-2 mb-2"
              >
                <div>
                  <b>{of?.label}</b>
                </div>
                <div>
                  {of?.street}{" "}
                  {of?.unitType ? `${of?.unitType} ${of?.unitNumber}` : ""}{" "}
                  {of?.city}, {of?.state} {of?.postalCode}
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
      {showForm ? (
        <FieldsContainer
          errorMessage={formState.errors.root?.message}
          status={status}
        >
          {existingWorksites && existingWorksites.length > 0 && (
            <div>
              <Button
                variant="link"
                className="text-accent pl-0 hover:text-blue-700"
                onClick={() => setShowForm(false)}
                disabled={isReadOnly}
              >
                <X size={20} />
              </Button>
            </div>
          )}
          <FormField
            control={form.control}
            name="street"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    {...field}
                    disabled={isReadOnly}
                    className="h-11"
                    placeholder="123 Main St."
                  />
                </FormControl>
                <ErrorMessage
                  errors={formState.errors}
                  name="street"
                  render={({ message }) => (
                    <FormErrorMessage>{message}</FormErrorMessage>
                  )}
                />
              </FormItem>
            )}
          />
          <div className="w-full flex flex-row gap-x-2">
            <FormField
              control={form.control}
              name="unitType"
              render={({ field }) => (
                <FormItem className="w-1/2">
                  <FormControl>
                    <select
                      disabled={isReadOnly}
                      {...field}
                      className="h-11 w-full border rounded-md px-2"
                    >
                      <option className="text-gray-300" value={""}>
                        Select Unit Type
                      </option>
                      {unitTypeOptions.map((option) => (
                        <option key={option.label} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </FormControl>
                  <ErrorMessage
                    errors={formState.errors}
                    name="unitType"
                    render={({ message }) => (
                      <FormErrorMessage>{message}</FormErrorMessage>
                    )}
                  />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="unitNumber"
              render={({ field }) => (
                <FormItem className="w-1/2">
                  <FormControl>
                    <Input
                      {...field}
                      disabled={isReadOnly}
                      className="h-11"
                      placeholder="Unit number"
                    />
                  </FormControl>
                  <ErrorMessage
                    errors={formState.errors}
                    name="unitNumber"
                    render={({ message }) => (
                      <FormErrorMessage>{message}</FormErrorMessage>
                    )}
                  />
                </FormItem>
              )}
            />
          </div>
          <FormField
            control={form.control}
            name="city"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    {...field}
                    className="h-11"
                    placeholder="City"
                    disabled={isReadOnly}
                  />
                </FormControl>
                <ErrorMessage
                  errors={formState.errors}
                  name="city"
                  render={({ message }) => (
                    <FormErrorMessage>{message}</FormErrorMessage>
                  )}
                />
              </FormItem>
            )}
          />
          <div className="w-full flex flex-row gap-x-2">
            <FormField
              control={form.control}
              name="state"
              render={({ field }) => (
                <FormItem className="w-1/2">
                  <FormControl>
                    <Input
                      {...field}
                      disabled={isReadOnly}
                      className="h-11 w-full"
                      placeholder="State"
                    />
                  </FormControl>
                  <ErrorMessage
                    errors={formState.errors}
                    name="state"
                    render={({ message }) => (
                      <FormErrorMessage>{message}</FormErrorMessage>
                    )}
                  />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="country"
              render={({ field }) => (
                <FormItem className="w-1/2">
                  <CountrySelect
                    disabled={isReadOnly}
                    field={field}
                    onSelect={(x: string) => form.setValue("country", x)}
                  />
                  <ErrorMessage
                    errors={formState.errors}
                    name="country"
                    render={({ message }) => (
                      <FormErrorMessage>{message}</FormErrorMessage>
                    )}
                  />
                </FormItem>
              )}
            />
          </div>
          <FormField
            control={form.control}
            name="zip"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    {...field}
                    className="h-11"
                    placeholder="Zip code"
                    disabled={isReadOnly}
                  />
                </FormControl>
                <ErrorMessage
                  errors={formState.errors}
                  name="zip"
                  render={({ message }) => (
                    <FormErrorMessage>{message}</FormErrorMessage>
                  )}
                />
              </FormItem>
            )}
          />
          <FormLabel className="mt-3">Worksite name</FormLabel>
          <FormField
            control={form.control}
            name="label"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    {...field}
                    disabled={isReadOnly}
                    className="h-11"
                    placeholder="Building 32"
                  />
                </FormControl>
                <ErrorMessage
                  errors={formState.errors}
                  name="label"
                  render={({ message }) => (
                    <FormErrorMessage>{message}</FormErrorMessage>
                  )}
                />
              </FormItem>
            )}
          />
        </FieldsContainer>
      ) : (
        <div>
          <Button
            variant="link"
            className="text-accent px-0 hover:underline"
            onClick={() => setShowForm(true)}
            disabled={isReadOnly}
          >
            Add worksite
          </Button>
        </div>
      )}
      <CTAContainer onBack={onBack} onSkip={onSkip}>
        <Button
          variant="accent"
          className="ml-auto text-md rounded-sm px-7 py-5"
          type="button"
          onClick={handleSubmit}
        >
          Next
        </Button>
      </CTAContainer>
    </EmployerOnboardingContainer>
  );
};
