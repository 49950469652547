import { ReactNode } from "react";

interface WelcomeSlideProps {
  title: string;
  description: string;
  accessory?: ReactNode;
  children?: ReactNode;
}

export function WelcomeSlide({
  title,
  description,
  accessory,
  children,
}: WelcomeSlideProps) {
  const formattedDescription = description.replace(/\n/g, "<br/>");

  return (
    <div className="space-y-6">
      <h1 className="text-4xl font-bold tracking-tight">{title}</h1>
      <div
        className="text-lg text-gray-600"
        dangerouslySetInnerHTML={{ __html: formattedDescription }}
      />
      {accessory && (
        <div className="mt-8 bg-gray-50 p-4 rounded-lg">{accessory}</div>
      )}
      {children}
    </div>
  );
}
