import {
  useGetUserDatumByIdQuery,
  useUserDatumUpdatesMutation,
} from "@codegen/index";
import { UpdateUserDatumByIdInput } from "@codegen/schema";
import { Button } from "@components/button";
import { FormControl, FormLabel } from "@components/form";
import { Input } from "@components/input";
import Spinner from "@components/spinner";
import { ErrorMessage } from "@hookform/error-message";
import { zodResolver } from "@hookform/resolvers/zod";
import { CTAContainer } from "@pages/onboarding/shared/cta-container";
import { FormErrorMessage } from "@pages/onboarding/shared/error";
import { FieldsContainer } from "@pages/onboarding/shared/fields-container";
import { OnboardingContainer } from "@pages/onboarding/shared/onboarding-container";
import { OnboardingPageType } from "@pages/onboarding/types";
import { X } from "@phosphor-icons/react";
import { useLogError } from "@utils/error";
import React from "react";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import { z } from "zod";
import { FormLoading } from "./loading";
import { useDebounceCallback } from "@react-hook/debounce";
import { useReadOnlyMode } from "@components/readOnlyModeProvider";

const nameSchema = z.object({
  firstName: z.string().min(1, "Please provide a first name"),
  lastName: z.string().min(1, "Please provide a last name"),
  middleName: z.string().optional(),
});
export const formSchema = z.object({
  hasOtherNames: z.literal("yes"),
  previousNames: nameSchema.array().nonempty(),
});
export const previousNamesFormSchemaDetails = z.discriminatedUnion(
  "hasOtherNames",
  [formSchema, z.object({ hasOtherNames: z.literal("no") })]
);

const defaultName = { firstName: "", lastName: "", middleName: "" };
export type PreviousNamesFormType = z.infer<
  typeof previousNamesFormSchemaDetails
>;
export type NameType = {
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
};
type PreviousNamesType = NameType[];
export const PreviousNamesForm: React.FC<
  OnboardingPageType & { otherNames?: PreviousNamesType; refetch: () => void }
> = ({
  contact,
  onSubmit,
  autoSave,
  onBack,
  otherNames,
  refetch,
  onSkipForNow,
}) => {
  const methods = useForm({
    defaultValues: {
      hasOtherNames: otherNames && otherNames.length > 0 ? "yes" : "no",
      previousNames: otherNames as PreviousNamesType,
    },
    resolver: zodResolver(previousNamesFormSchemaDetails),
  });
  const { isReadOnly } = useReadOnlyMode();

  const { register, watch, formState, control } = methods;
  const { fields, append, remove, insert } = useFieldArray<
    PreviousNamesFormType,
    "previousNames",
    "id"
  >({
    // @ts-ignore
    control,
    name: "previousNames",
  });
  const logError = useLogError();
  const [updateUserData, { loading: saving }] = useUserDatumUpdatesMutation();
  const hasOtherNames = watch("hasOtherNames");

  const watchedValues = useWatch({
    control,
  });

  const handleSave = async (data: PreviousNamesFormType) => {
    try {
      if (data.hasOtherNames === "yes") {
        const { errors: updateUserError } = await updateUserData({
          variables: {
            input: {
              id: contact.id,
              userDatumPatch: {
                otherNames: JSON.stringify(data.previousNames),
              },
            } as UpdateUserDatumByIdInput,
          },
        });

        if (updateUserError != null) {
          throw updateUserError;
        }
      }
      refetch();
      autoSave(data);
    } catch (exception) {
      logError(exception, {
        message: "Error saving user data",
      });
    }
  };

  const debouncedSave = useDebounceCallback(handleSave, 300);

  React.useEffect(() => {
    debouncedSave(watchedValues);
  }, [watchedValues, debouncedSave]);

  const doSubmit = async (data: PreviousNamesFormType) => {
    try {
      if (data.hasOtherNames === "yes") {
        const { errors: updateUserError } = await updateUserData({
          variables: {
            input: {
              id: contact.id,
              userDatumPatch: {
                otherNames: JSON.stringify(data.previousNames),
              },
            } as UpdateUserDatumByIdInput,
          },
        });

        if (updateUserError != null) {
          throw updateUserError;
        }
      }
      refetch();
      onSubmit(data);
    } catch (exception) {
      logError(exception, {
        message: "Error saving user data",
      });
    }
  };

  React.useEffect(() => {
    if (hasOtherNames && fields.length < 1) {
      append(defaultName);
    }
  }, [hasOtherNames, fields, insert]);

  return (
    <FormProvider {...methods}>
      <OnboardingContainer
        title={`Have you used any other names?`}
        subtitle={""}
        cardTitle=""
        progress={0}
        onSubmit={doSubmit}
      >
        <FieldsContainer status={""}>
          <div>
            <FormControl className="flex items-center gap-2">
              <FormLabel
                className="text-md text-primary"
                htmlFor="hasOtherNamesYes"
              >
                <input
                  id="hasOtherNamesYes"
                  type="radio"
                  value="yes"
                  {...register("hasOtherNames")}
                  disabled={isReadOnly}
                />{" "}
                Yes
              </FormLabel>
            </FormControl>
          </div>
          <div>
            <FormControl className="flex items-center gap-2">
              <FormLabel
                className="text-md text-primary"
                htmlFor="hasOtherNamesNo"
              >
                <input
                  id="hasOtherNamesNo"
                  type="radio"
                  value="no"
                  {...register("hasOtherNames")}
                  disabled={isReadOnly}
                />{" "}
                No
              </FormLabel>
            </FormControl>
          </div>
          <ErrorMessage
            errors={formState.errors}
            name="hasOtherNames"
            render={({ message }) => (
              <FormErrorMessage>{message}</FormErrorMessage>
            )}
          />
          {hasOtherNames === "yes" && (
            <div className="flex flex-col gap-3">
              <h3 className="font-semibold mt-3">
                Please provide them if <b>yes</b>
              </h3>
              {fields.map((field, index) => (
                <div
                  key={field.id}
                  className="border rounded bg-white py-6 px-3 flex gap-3  relative"
                >
                  <div className="w-1/3">
                    <FormLabel>First Name</FormLabel>
                    <FormControl>
                      <Input
                        {...register(
                          `previousNames.${index}.firstName` as "previousNames"
                        )}
                        disabled={isReadOnly}
                        autoComplete="given-name"
                        type="text"
                        placeholder="First Name"
                      />
                    </FormControl>
                    <ErrorMessage
                      errors={formState.errors}
                      name={`previousNames.${index}.firstName`}
                      render={({ message }) => (
                        <FormErrorMessage>{message}</FormErrorMessage>
                      )}
                    />
                  </div>
                  <div className="w-1/3">
                    <FormLabel>
                      Middle Name <small>(Optional)</small>
                    </FormLabel>
                    <FormControl>
                      <Input
                        {...register(
                          `previousNames.${index}.middleName` as "previousNames"
                        )}
                        disabled={isReadOnly}
                        autoComplete="name"
                        type="text"
                        placeholder="Middle Name"
                      />
                    </FormControl>
                    <ErrorMessage
                      errors={formState.errors}
                      name={`previousNames.${index}.middleName`}
                      render={({ message }) => (
                        <FormErrorMessage>{message}</FormErrorMessage>
                      )}
                    />
                  </div>
                  <div className="w-1/3">
                    <FormLabel>Last Name</FormLabel>
                    <FormControl>
                      <Input
                        {...register(
                          `previousNames.${index}.lastName` as "previousNames"
                        )}
                        disabled={isReadOnly}
                        autoComplete="family-name"
                        type="text"
                        placeholder="Last Name"
                      />
                    </FormControl>
                    <ErrorMessage
                      errors={formState.errors}
                      name={`previousNames.${index}.lastName`}
                      render={({ message }) => (
                        <FormErrorMessage>{message}</FormErrorMessage>
                      )}
                    />
                  </div>

                  <button
                    onClick={() => remove(index)}
                    className="absolute top-0 right-0 p-2"
                  >
                    <X />
                  </button>
                </div>
              ))}
              <Button
                type="button"
                variant={"link"}
                className="self-start text-accent underline px-0"
                onClick={() => append(defaultName)}
              >
                Add more
              </Button>
            </div>
          )}
        </FieldsContainer>

        <CTAContainer onBack={onBack} onSkip={() => onSkipForNow?.()}>
          <Button
            variant="accent"
            className="ml-auto text-md rounded-sm px-7 py-5"
            disabled={saving || formState.isValidating}
            type="submit"
          >
            {saving ? <Spinner /> : "Next"}
          </Button>
        </CTAContainer>
      </OnboardingContainer>
    </FormProvider>
  );
};

export const PreviousNamesFormController: React.FC<OnboardingPageType> = (
  props
) => {
  const {
    data: userData,
    refetch,
    error,
  } = useGetUserDatumByIdQuery({
    variables: {
      id: props.contact.id,
    },
  });
  const logError = useLogError();
  const otherNames = React.useMemo(() => {
    if (userData?.userDatumById?.otherNames) {
      return JSON.parse(userData?.userDatumById?.otherNames);
    }
  }, [userData]);

  if (error) {
    logError(error, { contactId: props.contact.id });
  }

  if (userData) {
    return (
      <PreviousNamesForm
        {...props}
        otherNames={otherNames}
        refetch={refetch}
        onSkip={props.onSkipForNow}
      />
    );
  }

  return <FormLoading />;
};
