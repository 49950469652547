import {
  GetPetitionsByCompanyIdDocument,
  GetUserByIdDocument,
  useUpdateCompanyMutation,
} from "@codegen/index";
import { UpdateCompanyByIdInput } from "@codegen/schema";
import { UsersThree } from "@phosphor-icons/react";
import { cn } from "@utils/cn";
import { useLogError } from "@utils/error";
import { useAsyncClient } from "@vendors/statsig";
import * as React from "react";
import { useCallback, useMemo } from "react";
import ReactConfetti from "react-confetti";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { useEmployerOnboarding } from "../../employer-onboarding";

const Conclusion = () => {
  const { company } = useEmployerOnboarding();
  const { statsigClient } = useAsyncClient();
  const navigate = useNavigate();
  const location = useLocation();
  const logError = useLogError();
  React.useEffect(() => {
    if (statsigClient) {
      statsigClient.logEvent("employer_onboarding_view", "complete");
    }
  }, [statsigClient]);
  const [setPortalOnboardedMutation] = useUpdateCompanyMutation();
  const dosetPortalOnboarded = useCallback(async () => {
    const { errors } = await setPortalOnboardedMutation({
      variables: {
        input: {
          id: company.value,
          companyPatch: {
            portalOnboarded: true,
          },
        } as UpdateCompanyByIdInput,
      },
      refetchQueries: [
        GetPetitionsByCompanyIdDocument,
        GetUserByIdDocument,
        "active",
      ],
    });

    if (errors) {
      logError(errors[0]);
    }
  }, [setPortalOnboardedMutation, company.value]);

  React.useEffect(() => {
    dosetPortalOnboarded();
  }, []);

  const goToHome = useCallback(() => {
    navigate({
      pathname: "/",
      search:
        new URLSearchParams(location.search).get("readOnly") === "true"
          ? createSearchParams({ readOnly: "true" }).toString()
          : "",
    });
  }, [navigate, location.search]);

  const data = useMemo(
    () => [
      {
        title: "Track your Team's Immigration Journeys here",
        icon: <UsersThree size={20} weight="light" />,
        description: `Get visibility into your team's immigration status, and application process.`,
        onClick: goToHome,
      },
    ],
    [goToHome]
  );

  const cards = useMemo(() => {
    return data.map((datum, idx) => (
      <div
        key={idx}
        className="bg-paleblue gap-x-2 shadow-sm text-accent font-light flex flex-row p-3 items-center w-[500px] h-[40px] border border-1 border-accent rounded-md hover:bg-accent hover:text-white hover:cursor-pointer hover:shadow-xl"
        onClick={datum.onClick}
      >
        {datum.icon}
        {datum.title}
      </div>
    ));
  }, [data]);

  return (
    <div className="flex flex-col items-center h-screen w-screen">
      <ReactConfetti />
      <div
        className={cn(
          "flex flex-col items-center h-screen w-screen",
          "bg-[url('/bg-journey.svg')]"
        )}
      >
        {
          <div className="flex flex-col h-full z-1">
            <div className="h-1/3" />
            <div className="w-fit">
              <h1 className="text-3xl text-accent mb-4">
                Thank you for completing employer onboarding!
              </h1>
              <h2 className="text-md font-light text-accent mb-5">
                Next Steps: Our team will begin working on your immigration
                forms now.
              </h2>
            </div>
            <div className="flex flex-col gap-y-2 ">{cards}</div>
          </div>
        }
      </div>
    </div>
  );
};

export default Conclusion;
