import { Check } from "@phosphor-icons/react";
import { cn } from "@utils/cn";
import { ChevronsUpDown } from "lucide-react";
import { Button } from "./button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "./command";
import { FormControl } from "./form";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";

const types = [
  {
    label: "Technology, Software Development",
    value: "technology-software-development",
  },
  { label: "Healthcare Technology", value: "healthcare-technology" },
  { label: "Financial Technology", value: "financial-technology" },
  {
    label: "Artificial Intelligence Software Development",
    value: "ai-software-development",
  },
  { label: "Financial management", value: "financial-management" },
  { label: "Other", value: "other" },
];

export const BusinessTypeSelect = (props: {
  field: {
    value: string;
  };
  onSelect: (x: string) => void;
  disabled?: boolean;
}) => {
  const { field, onSelect, disabled = false } = props;

  return (
    <Popover>
      <PopoverTrigger asChild>
        <FormControl>
          <Button
            variant="outline"
            role="combobox"
            className={cn(
              "w-full h-11 bg-white justify-between",
              !field.value && "text-muted-foreground"
            )}
            disabled={disabled}
          >
            {field.value
              ? types.find((type) => type.value === field.value)?.label
              : "Select business type"}
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </FormControl>
      </PopoverTrigger>
      <PopoverContent className="w-full" side="bottom" align="start">
        <Command>
          <CommandInput placeholder="Search business type..." />
          <CommandEmpty>No types found.</CommandEmpty>
          <CommandGroup>
            {types.map((type) => (
              <CommandItem
                value={type.label}
                key={type.value}
                onSelect={() => onSelect(type.value)}
              >
                <Check
                  size={4}
                  className={cn(
                    "mr-2 h-4 w-4",
                    type.value === field.value ? "opacity-100" : "opacity-0"
                  )}
                />
                {type.label}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
