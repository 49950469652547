import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@radix-ui/react-icons";
import * as Select from "@radix-ui/react-select";
import { cn } from "@utils/cn";
import * as React from "react";

export const PlymouthSelect = ({
  onChange,
  value,
  children,
  placeholder,
  name,
}: {
  onChange: (value: string) => void;
  value: string;
  children: React.ReactNode;
  placeholder: string;
  name: string;
}) => {
  return (
    <Select.Root
      onValueChange={onChange}
      defaultValue={value}
      value={value}
      name={name}
    >
      <Select.Trigger
        className="flex h-10 w-full rounded-sm border border-input px-3 p-2 bg-white text-sm shadow-black/10 outline-none hover:bg-mauve3 focus:shadow-[0_0_0_1px] focus:shadow-accent data-[placeholder]:text-gray-400 shadow-sm disabled:cursor-not-allowed disabled:opacity-50 justify-between"
        aria-label={placeholder}
      >
        <Select.Value placeholder={placeholder} />
        <Select.Icon className="text-gray-400 justify-end self-center">
          <ChevronDownIcon />
        </Select.Icon>
      </Select.Trigger>
      <Select.Portal>
        <Select.Content className="overflow-hidden rounded-md bg-white shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)]">
          <Select.ScrollUpButton className="flex h-[25px] cursor-default items-center justify-center bg-white text-violet11">
            <ChevronUpIcon />
          </Select.ScrollUpButton>
          <Select.Viewport>{children}</Select.Viewport>
          <Select.ScrollDownButton className="flex h-[25px] cursor-default items-center justify-center bg-white text-violet11">
            <ChevronDownIcon />
          </Select.ScrollDownButton>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
};

export const SelectItem = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<typeof Select.Item> & { className?: string }
>(({ children, className, ...props }, forwardedRef) => {
  return (
    <Select.Item
      className={cn(
        "relative flex h-[25px] select-none items-center rounded-[3px] pl-[25px] pr-[35px] text-[13px] leading-none text-violet11 data-[disabled]:pointer-events-none data-[highlighted]:bg-accent data-[disabled]:text-mauve8 data-[highlighted]:text-white data-[highlighted]:outline-none",
        className
      )}
      {...props}
      ref={forwardedRef}
    >
      <Select.ItemText>{children}</Select.ItemText>
      <Select.ItemIndicator className="absolute left-0 inline-flex w-[25px] items-center justify-center">
        <CheckIcon />
      </Select.ItemIndicator>
    </Select.Item>
  );
});
