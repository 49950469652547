import { ImmigrationDocumentType } from "@codegen/schema";
import { OnboardingPageType } from "@pages/onboarding/types";
import React from "react";
import { z } from "zod";
import { ImmigrationDocumentForm } from "./file-form";

export const eadCardFormSchema = z.object({
  eadCardStamps: z.string().min(1, "Please upload your Ead card"),
});

export type EADCardFormType = z.infer<typeof eadCardFormSchema>;
export const EadCardForm: React.FC<OnboardingPageType> = (props) => {
  return (
    <ImmigrationDocumentForm
      {...props}
      title={"Identification information"}
      subtitle={
        "If you have ever worked in the U.S. using an “EAD” card, please upload these here."
      }
      cardTitle={"Upload EAD card"}
      name={`ead-card-form`}
      fileType={ImmigrationDocumentType.EadCard}
    />
  );
};

export default EadCardForm;
