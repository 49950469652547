import ErrorBoundary from "@components/error-boundary";
import ChecklistPage from "@pages/dashboard/checklist";
import { BeneficiaryOnboarding } from "@pages/onboarding/beneficiary/beneficiary-onboarding";
import { OnboardingCompleted } from "@pages/onboarding/beneficiary/forms/completed";
import { OnboardingFollowup } from "@pages/onboarding/beneficiary/forms/followup";
import { OnboardingStateMachineProvider } from "@pages/onboarding/beneficiary/machines/machine-provider";
import { WaitingSplash } from "@pages/onboarding/shared/waiting";
import { useFeatureGate, useStatsigClient } from "@statsig/react-bindings";
import { useContact, usePetitions } from "@utils/hooks";
import * as changeCase from "change-case";
import * as React from "react";
import {
  Link,
  Route,
  Routes,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  BeneficiaryOnboardingWorkflowProvider,
  useBeneficiaryWorkflowContext,
} from "./provider";

const BeneficiaryOnboardingRoot = () => {
  const { contact, team } = useContact();
  const {
    modules,
    currentModuleIndex,
    currentExhibitIndex,
    initializeContext,
    petition,
    onboarding,
  } = useBeneficiaryWorkflowContext();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  React.useEffect(() => {
    const moduleKey = params["module"];
    if (moduleKey && modules && modules.length) {
      const moduleIndex = moduleKey
        ? modules.findIndex((modArr) => {
            return id
              ? modArr.id === id
              : modArr.type === changeCase.constantCase(moduleKey);
          })
        : modules.findIndex((mod) =>
            ["pending", "in_progress"].includes(mod.status)
          );

      const exhibits = modules[moduleIndex].exhibits;
      const exhibitIndex = exhibits.findIndex((ex) =>
        ["pending", "in_progress"].includes(ex.status)
      );
      console.log("Initialize indexes", moduleIndex, exhibitIndex);
      initializeContext({
        moduleIndex: moduleIndex > -1 ? moduleIndex : 0,
        exhibitIndex: exhibitIndex > -1 ? exhibitIndex : 0,
      });
    }
  }, [params, initializeContext, modules]);

  if (
    !contact ||
    !team ||
    !modules ||
    !modules.length ||
    typeof currentModuleIndex !== "number" ||
    typeof currentExhibitIndex !== "number" ||
    !petition ||
    !onboarding
  ) {
    return <WaitingSplash />;
  }

  return (
    <ErrorBoundary>
      <OnboardingStateMachineProvider>
        <BeneficiaryOnboarding
          company={team}
          contact={contact}
          modules={modules}
          currentModuleIndex={currentModuleIndex}
          currentExhibitIndex={currentExhibitIndex}
          petition={petition}
          onboarding={onboarding}
        />
      </OnboardingStateMachineProvider>
    </ErrorBoundary>
  );
};

const OnboardingNotFound = () => {
  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <div>
        <h1>:(</h1>
        <p className="mb-3">Page not found</p>
        <Link to="/" className="text-accent hover:underline">
          Back to home
        </Link>
      </div>
    </div>
  );
};

export const OnboardingWorkflows: React.FC<{}> = () => {
  const { value } = useFeatureGate("beneficiary-onboarding");
  const { loading, team } = useContact();
  const { client } = useStatsigClient();
  const { data: petitionData, loading: petitionLoading } = usePetitions();
  const petitionId = petitionData?.latestPetition?.id;

  React.useEffect(() => {
    client.logEvent(`onboarding_workflows`, "root_view", {
      beneficiaryOnboardingFlag: value.toString(),
      path: location.pathname,
    });
  }, []);

  if (!petitionId || loading || petitionLoading || !team) {
    return <WaitingSplash />;
  }

  return (
    <BeneficiaryOnboardingWorkflowProvider petitionId={petitionId}>
      <Routes>
        {/* <Route path="/employer/:team?" element={<EmployerOnboardingRoot />} /> */}
        {
          <>
            <Route path="/complete" element={<OnboardingCompleted />} />
            <Route path="/:module" element={<BeneficiaryOnboardingRoot />} />
            <Route path="/followup" element={<OnboardingFollowup />} />
            <Route path="/*" element={<ChecklistPage />} />
          </>
        }

        {/* <Route path="*" element={<OnboardingNotFound />} /> */}
      </Routes>
    </BeneficiaryOnboardingWorkflowProvider>
  );
};
