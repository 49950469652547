import { useAllModuleTypesQuery } from "@codegen/index";
import React, { useMemo } from "react";
import { Combobox } from "./combobox";

const ModuleTypeSelector = (props: {
  selectedType?: string;
  onSelect?: (type: string) => void;
  className?: string;
}) => {
  const { selectedType, onSelect } = props;
  const { data } = useAllModuleTypesQuery();

  const opts = useMemo(() => {
    const opts = [];

    for (const type of data?.allModuleTypes?.nodes ?? []) {
      if (type == null) continue;
      opts.push({
        value: type.id ?? "",
        label: type.name,
      });
    }
    return opts;
  }, [data?.allModuleTypes?.nodes]);
  return (
    <Combobox
      options={opts}
      defaultValue={(selectedType as string) ?? ""}
      onSelect={(e) => onSelect?.(e)}
      placeholder="Module Type"
      className={props.className}
    />
  );
};

const ModuleTypeSelectorMemo = React.memo(ModuleTypeSelector);
export default ModuleTypeSelectorMemo;
