import { ModuleType, WorkflowExpertLetterExhibit } from "@codegen/enums";
import { Button } from "@components/button";
import { FormControl, FormItem, FormLabel } from "@components/form";
import { Input } from "@components/input";
import { MonthSelector, YearSelector } from "@components/monthSelector";
import { useReadOnlyMode } from "@components/readOnlyModeProvider";
import Spinner from "@components/spinner";
import { Textarea } from "@components/textArea";
import { ErrorMessage } from "@hookform/error-message";
import { zodResolver } from "@hookform/resolvers/zod";
import { CTAContainer } from "@pages/onboarding/shared/cta-container";
import { FormErrorMessage } from "@pages/onboarding/shared/error";
import { FieldsContainer } from "@pages/onboarding/shared/fields-container";
import { SignatoryFootnote } from "@pages/onboarding/shared/footnotes";
import { OnboardingContainer } from "@pages/onboarding/shared/onboarding-container";
import { UnexpectedErrorPage } from "@pages/onboarding/shared/unexpected-error";
import { OnboardingPageType } from "@pages/onboarding/types";
import { useDebounceCallback } from "@react-hook/debounce";
import { StrategyModule } from "@utils/types";
import * as changeCase from "change-case";
import * as React from "react";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { z } from "zod";

export const expertLetterFormSchema = z.object({
  fullName: z.string().min(2, "Required"),
  email: z.string().email("Required"),
  title: z.string().min(2, "Required"),
  currentCompanyName: z.string().min(1, "Required"),
  linkedin: z.string().url("Please provide a valid LinkedIn URL").optional(),
  description: z
    .string()
    .min(100, "Please provide more context in 100+ characters.")
    .optional(),
  meetDescription: z
    .string()
    .min(20, "Please provide more context in 20+ characters"),
  phoneNumber: z
    .string()
    .refine((val) => val === "" || val.length >= 10, {
      message: "Please enter a 10 digit phone number",
    })
    .optional(),
  monthMet: z.string().min(2, "Required"),
  yearMet: z.string().min(4, "Required"),
});

export type ExpertLetterFormType = z.infer<typeof expertLetterFormSchema>;
export const ExpertLetterForm: React.FC<
  OnboardingPageType & {
    title: string;
    subtitle: React.ReactNode;
    cardTitle: React.ReactNode;
    descriptionLabel?: React.ReactNode;
    includeDescription?: boolean;
  }
> = ({
  module,
  exhibit,
  onBack,
  onSubmit,
  onSkip,
  isSaving,
  title,
  subtitle,
  cardTitle,
  includeDescription,
  descriptionLabel,
  footnote,
  saveData,
  onSkipForNow,
}) => {
  const { required, data } = exhibit;

  const { isReadOnly } = useReadOnlyMode();

  const methods = useForm({
    defaultValues: {
      ...(data as ExpertLetterFormType),
    },
    resolver: zodResolver(expertLetterFormSchema),
  });
  const { register, formState, control } = methods;
  const handleSubmit = (data: ExpertLetterFormType) => {
    onSubmit(data);
  };

  // Watch form values
  const watchedValues = useWatch({
    control,
  });

  const saveForm = (values: any) => {
    if (values) {
      saveData(module, exhibit, values);
    }
  };

  const debouncedSave = useDebounceCallback(saveForm, 300);

  React.useEffect(() => {
    debouncedSave(watchedValues);
  }, [watchedValues, debouncedSave]);

  return (
    <FormProvider {...methods}>
      <OnboardingContainer
        title={title}
        subtitle={subtitle}
        cardTitle={cardTitle}
        progress={0}
        onSubmit={handleSubmit}
        exhibit={exhibit}
        name={`expert-letter-form`}
        footnote={footnote}
      >
        <FieldsContainer status={""}>
          <div className="flex flex-col gap-3">
            <FormItem className="w-full">
              <FormLabel>Signatory Full Name</FormLabel>
              <Input
                {...register("fullName")}
                disabled={isReadOnly}
                placeholder="Signatory Full Name"
              />
              <ErrorMessage
                errors={formState.errors}
                name="fullName"
                render={({ message }) => (
                  <FormErrorMessage>{message}</FormErrorMessage>
                )}
              />
            </FormItem>
            <div className="flex gap-3 justify-between">
              <FormItem className="w-1/2">
                <FormLabel>Expert Current Title</FormLabel>
                <Input
                  {...register("title")}
                  disabled={isReadOnly}
                  placeholder="Chief Technology Officer"
                />
                <ErrorMessage
                  errors={formState.errors}
                  name="title"
                  render={({ message }) => (
                    <FormErrorMessage>{message}</FormErrorMessage>
                  )}
                />
              </FormItem>
              <FormItem className="w-1/2">
                <FormLabel>Expert Current Company Name</FormLabel>
                <Input
                  {...register("currentCompanyName")}
                  disabled={isReadOnly}
                  placeholder="Company Name"
                />
                <ErrorMessage
                  errors={formState.errors}
                  name="currentCompanyName"
                  render={({ message }) => (
                    <FormErrorMessage>{message}</FormErrorMessage>
                  )}
                />
              </FormItem>
            </div>
            <div className="flex gap-3 justify-between">
              <FormItem className="w-1/2">
                <FormLabel>Email</FormLabel>
                <Input
                  {...register("email")}
                  disabled={isReadOnly}
                  placeholder="john@doe.com"
                />
                <ErrorMessage
                  errors={formState.errors}
                  name="email"
                  render={({ message }) => (
                    <FormErrorMessage>{message}</FormErrorMessage>
                  )}
                />
              </FormItem>
              <FormItem className="w-1/2">
                <FormLabel>
                  Phone number <small>(Optional)</small>
                </FormLabel>
                <Input
                  {...register("phoneNumber")}
                  disabled={isReadOnly}
                  placeholder="222-111-3333"
                />
                <ErrorMessage
                  errors={formState.errors}
                  name="phoneNumber"
                  render={({ message }) => (
                    <FormErrorMessage>{message}</FormErrorMessage>
                  )}
                />
              </FormItem>
            </div>
          </div>
          <FormItem>
            <FormLabel>
              Please share this individual's <b>LinkedIn</b> profile. We only
              need one individual to sign this letter.
            </FormLabel>
            <Input
              {...register("linkedin")}
              disabled={isReadOnly}
              placeholder="https://www.linkedin.com/john-doe"
            />
            <ErrorMessage
              errors={formState.errors}
              name="linkedin"
              render={({ message }) => (
                <FormErrorMessage>{message}</FormErrorMessage>
              )}
            />
          </FormItem>
          <FormItem>
            <FormLabel>
              Please share how and when you met this individual
            </FormLabel>
            <Input
              {...register("meetDescription")}
              disabled={isReadOnly}
              placeholder="We met at..."
            />
            <ErrorMessage
              errors={formState.errors}
              name="meetDescription"
              render={({ message }) => (
                <FormErrorMessage>{message}</FormErrorMessage>
              )}
            />
          </FormItem>
          <div className="flex gap-3 items-center justify-between">
            <div className="w-1/2">
              <FormLabel>Month Met</FormLabel>
              <div>
                <Controller
                  control={control}
                  name="monthMet"
                  render={({ field }) => (
                    <MonthSelector
                      disabled={isReadOnly}
                      selectedValue={field.value}
                      onSelect={(month) => field.onChange(month)}
                      className="bg-white"
                    />
                  )}
                />
              </div>
              <ErrorMessage
                errors={formState.errors}
                name="monthMet"
                render={({ message }) => (
                  <FormErrorMessage>{message}</FormErrorMessage>
                )}
              />
            </div>
            <div className="w-1/2">
              <FormLabel>Year Met</FormLabel>
              <div>
                <Controller
                  control={control}
                  name="yearMet"
                  render={({ field }) => (
                    <YearSelector
                      disabled={isReadOnly}
                      selectedValue={field.value}
                      onSelect={(year) => field.onChange(year)}
                      className="bg-white"
                    />
                  )}
                />
              </div>
              <ErrorMessage
                errors={formState.errors}
                name="yearMet"
                render={({ message }) => (
                  <FormErrorMessage>{message}</FormErrorMessage>
                )}
              />
            </div>
          </div>
          {includeDescription && (
            <FormItem className="mt-3">
              <FormLabel>{descriptionLabel ?? "Description of role"}</FormLabel>
              <FormControl>
                <Textarea
                  {...register("description")}
                  disabled={isReadOnly}
                  className="bg-white"
                  placeholder="Enter text here..."
                  rows={6}
                />
              </FormControl>
              <ErrorMessage
                errors={formState.errors}
                name="description"
                render={({ message }) => (
                  <FormErrorMessage>{message}</FormErrorMessage>
                )}
              />
            </FormItem>
          )}
        </FieldsContainer>
        <CTAContainer
          onBack={onBack}
          onSkip={required ? () => onSkipForNow?.() : onSkip}
        >
          <Button
            variant="accent"
            className="ml-auto text-md rounded-sm px-7 py-5"
            // disabled={!formState.isValid || formState.isValidating}
            type="submit"
          >
            {isSaving ? <Spinner /> : "Next"}
          </Button>
        </CTAContainer>
      </OnboardingContainer>
    </FormProvider>
  );
};

export const ExpertLetterCriticalRoleForm: React.FC<OnboardingPageType> = (
  props
) => {
  const { exhibit } = props;
  const { moduleType, name, company } = exhibit as WorkflowExpertLetterExhibit;
  const subtitle = (
    <div>
      <p>
        This should be someone from <b>{company.companyName}</b> who can attest
        to your critical role work and who will credit you with company wins.
      </p>
    </div>
  );
  const label = (
    <div className="flex flex-col gap-2">
      <p className="font-semibold">
        Please provide 3-4 responsibilities for your role:
      </p>
      <ol className="list-decimal list-inside mb-3 flex flex-col gap-2">
        <li className="">
          Do you have control or authority over any budgets or project
          timelines?
        </li>
        <li className="">
          If you are technical, did you develop novel technology for the
          company?
        </li>
        <li className="leading-4">
          What was the impact of your work for the company? For example, did you
          help the company increase earnings by $X? Secure new clients or new
          investors?
        </li>
      </ol>
    </div>
  );
  return (
    <ExpertLetterForm
      {...props}
      subtitle={subtitle}
      cardTitle={name}
      title={`Expert Letter - ${changeCase.capitalCase(moduleType)}`}
      includeDescription
      descriptionLabel={label}
      footnote={SignatoryFootnote}
    />
  );
};

export const ExpertLetterMembershipForm: React.FC<OnboardingPageType> = (
  props
) => {
  const { exhibit } = props;
  const { moduleType, name } = exhibit as WorkflowExpertLetterExhibit;

  const subtitle = (
    <div>
      <p>
        The best signatory for this letter is an organizer, leader, or
        admissions director for the membership. Our team will use this
        information to draft a letter on their behalf and will send it to you
        for review.
      </p>
    </div>
  );
  return (
    <ExpertLetterForm
      {...props}
      subtitle={subtitle}
      cardTitle={name}
      title={`Expert Letter - ${changeCase.capitalCase(moduleType)}`}
      footnote={SignatoryFootnote}
    />
  );
};

export const ExpertLetterJudgingForm: React.FC<OnboardingPageType> = (
  props
) => {
  const { exhibit } = props;
  const { moduleType, name } = exhibit as WorkflowExpertLetterExhibit;
  const subtitle = (
    <div>
      <p>
        The best signatory for this letter is an organizer, leader, or
        admissions director for the Judging event. Our team will use this
        information to draft a letter on their behalf and will send it to you
        for review.
      </p>
    </div>
  );
  return (
    <ExpertLetterForm
      {...props}
      subtitle={subtitle}
      cardTitle={name}
      title={`Expert Letter - ${changeCase.capitalCase(moduleType)}`}
      footnote={SignatoryFootnote}
    />
  );
};

export const ExpertLetterAwardsForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit } = props;
  const { moduleType, name } = exhibit as WorkflowExpertLetterExhibit;
  const subtitle = (
    <div>
      <p>Ideally an individual from the awarding organization.</p>
    </div>
  );
  return (
    <ExpertLetterForm
      {...props}
      subtitle={subtitle}
      cardTitle={name}
      title={`Expert Letter - ${changeCase.capitalCase(moduleType)}`}
      footnote={SignatoryFootnote}
    />
  );
};

export const ExpertLetterOriginalContributionForm: React.FC<
  OnboardingPageType
> = (props) => {
  const { exhibit } = props;
  const { moduleType, name } = exhibit as WorkflowExpertLetterExhibit;
  const subtitle = (
    <div>
      <p>
        This could be someone who knows about what you’ve created and who will
        credit you with its major impact in the field. This impact should extend
        beyond a single company (unless the company is a global leader, think
        Google, Meta, or OpenAI, etc.). The signatory maybe someone you work
        with or a fellow expert in the field.
      </p>
      <p></p>
    </div>
  );
  return (
    <ExpertLetterForm
      {...props}
      subtitle={subtitle}
      cardTitle={name}
      title={`Expert Letter - ${changeCase.capitalCase(moduleType)}`}
      includeDescription
      descriptionLabel={
        <div>
          <p className="font-semibold mb-2">
            Please complete the following sentence
          </p>
          <p className="leading-5">
            "I am the creator of an original X. My creation is called Z. I
            leveraged knowledge of _____________ to design Z. Z is considered
            totally original because _______. My work is considered remarkable
            in the field because_________."
          </p>
        </div>
      }
      footnote={SignatoryFootnote}
    />
  );
};

export const ExpertLetterAOLetterForm: React.FC<OnboardingPageType> = (
  props
) => {
  const { petition } = props;
  const { companyByPetitionerId } = petition;
  const subtitle = (
    <div className="flex flex-col gap-2">
      <p>Please provide us with the name of an individual who</p>
      <ol className="flex flex-col gap-1 list-decimal pl-4">
        <li>Is based in the U.S.</li>
        <li>Who has 5-10 years of experience in your field</li>
        <li>Who is familiar with your work</li>
        <li>Who has no financial ties to {companyByPetitionerId?.dbaName}.</li>
        <li>
          And who will happily sign a letter that says "I support{" "}
          {petition.userByBeneficiaryId?.fullName}'s petition for a U.S. work
          visa."
        </li>
      </ol>
    </div>
  );
  return (
    <ExpertLetterForm
      {...props}
      subtitle={subtitle}
      cardTitle={""}
      title={`Advisory Opinion Letter`}
      footnote={SignatoryFootnote}
    />
  );
};

const letterMap: Partial<{
  [key in StrategyModule["type"]]: typeof ExpertLetterCriticalRoleForm;
}> = {
  CRITICAL_ROLE: ExpertLetterCriticalRoleForm,
  ORIGINAL_CONTRIBUTION: ExpertLetterOriginalContributionForm,
  JUDGING: ExpertLetterJudgingForm,
  MEMBERSHIP: ExpertLetterMembershipForm,
  AWARDS: ExpertLetterAwardsForm,
  MEMO_STARTER: ExpertLetterAOLetterForm,
};

export const ExpertLetterRenderer: React.FC<OnboardingPageType> = (props) => {
  const { exhibit } = props;
  const { moduleType } = exhibit as WorkflowExpertLetterExhibit;
  const ExpertLetter = letterMap[moduleType as ModuleType];

  if (!ExpertLetter) {
    return (
      <UnexpectedErrorPage
        {...props}
        error={`Expert letter type ${moduleType} not found`}
      />
    );
  }

  return <ExpertLetter {...props} />;
};
