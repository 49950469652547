export const HighEnumerationDescription = (
  <div>
    <p className="mb-3">
      We aim to show you're highly compensated compared to peers in your role
      and location. Your case may involve past, present, or future high
      remuneration.
    </p>
  </div>
);

export const CriticalRoleDescription = (
  <div>
    <p className="mb-3">
      As you complete this section, provide clear documentation of your key
      contributions—leading projects, driving initiatives, owning a key
      function, or developing impactful work. Ensure your name is visible to
      show ownership. We look forward to seeing your evidence!
    </p>
  </div>
);

export const CriticalRoleCodeContribution = (
  <div>
    <p className="mb-3">
      Code contributor page which clearly shows your name — the higher the code
      contributions, the better (the one with those cool graphs!)
    </p>
    <p className="">
      GitHub profile page and any pages showing your code project ownership — we
      want to see you’re an admin / and your pulls and commits
    </p>
  </div>
);

export const CriticalRoleScholarlyArticle = (
  <div>
    <p className="mb-3">
      Scholarly articles or papers published in academic journals/conferences
    </p>
    <p className="text-sm">
      *Please upload the publication files if it is not publicly available
      (behind a paywall, etc.)
    </p>
  </div>
);

export const CriticalRolePatents = (
  <div>
    <p className="mb-3">
      Any patents you’ve filed in this role where you’re listed as inventor
      (preferably a Google Patent URL)
    </p>
  </div>
);

export const CriticalRoleCustomerContracts = (
  <div>
    <p className="mb-3">Contracts you’ve signed on behalf of the company</p>
  </div>
);

export const CriticalRoleInternalDocuments = (
  <div>
    <p className="mb-3">
      <b>Presentations</b> you've created (from Notion, slide decks, fundraising
      decks etc.) where you are listed as the owner/author/ or as leading the
      initiative or project.
    </p>
    <p className="mb-3">
      <b>Dashboards</b> illustrating your achievements in real numbers (e.g. a
      dashboard showing how many users you’ve gained, how many downloads you’ve
      received, how many customers you’ve served, how many people have
      interacted with your technology, how much revenue you’ve generated, etc.)
    </p>
    <p>
      Please ensure that your name is clearly visible on these documents,
      showing you as the author or project lead. Please ensure your name is
      clearly listed as an author or key contributor. This could be internal
      technical memos, product roadmaps, strategic documents.
    </p>
  </div>
);

export const CriticalRoleAcademicContractProposal = (
  <div>
    <p className="mb-3">
      Contracts / Grant Proposals / or Budget Allocations you were involved in
      as a researcher
    </p>
  </div>
);

export const CriticalRoleAcademicPapers = (
  <div>
    <p className="mb-3">Papers you’ve published during your academic role </p>
  </div>
);

export const CriticalRoleDistinguishedReputation = (
  <div>
    <p className="mb-3">
      Please help us to show that the organization where you held/hold a
      critical role has a "distinguished reputation." This means that your
      organization is recognized by third parties as being reputable. Please
      attach documents such as:
    </p>
    <ul className="list-disc pl-5 mb-3">
      <li>
        <b>User stats</b> (like Google Analytics or waitlist numbers)
      </li>
      <li>
        <b>Impressive customer contracts</b> (high revenue contracts or
        contracts with important players in your field)
      </li>
      <li>
        <b>Company revenue info</b> (Stripe Dashboard or customer contracts){" "}
      </li>
      <li>
        <b>Funding documentation</b> to show funds raised from well-regarded
        sources (prominent VCs, Angels, or government grants)
      </li>
    </ul>
    <div className="text-sm">
      <p className="mb-2">
        Don't worry if you don't have all of these – even a few strong pieces
        can paint a picture of your critical role and organization’s
        distinguished reputation. Upload what you can, and let your achievements
        shine!
      </p>
      <p>
        We’ll gather publicly available information to show the company’s
        reputation (press, awards).
      </p>
    </div>
  </div>
);

export const MembershipDocumentationDescription = (
  <div>
    <p className="mb-3">
      Upload proof of your exclusive professional membership, such as a member
      portal screenshot, acceptance email, and any other verification. Please
      make sure the source URLs are visible, or if an email, that all email
      addresses are clearly visible. Congrats—we’re excited to include this in
      your petition!
    </p>
  </div>
);
// export const AuthorshipJournalArticleDescription = (
//   <div>
//     <p className=""></p>
//   </div>
// )
// export const AuthorshipGoogleScholarPageDescription = (
//   <div>
//     <p className=""></p>
//   </div>
// )
// export const AuthorshipConferenceDescription = (
//   <div>
//     <p className=""></p>
//   </div>
// )
export const AuthorshipMajorPublicationDescription = (
  <div className="flex flex-col gap-3">
    <p>
      Upload any scholarly articles not on your public Google Scholar page,
      including a direct link and full-page screenshot with the URL. Articles on
      your Google Scholar page will be included automatically — no further
      action needed.
    </p>
  </div>
);

export const JudgingDescription = (
  <div className="flex flex-col gap-3">
    <p>
      This module showcases your expertise in evaluating others' work, such as
      judging hackathons, pitch competitions, or peer-reviewing academic
      journals — proof of your exceptional knowledge.
    </p>
  </div>
);

export const AwardDescription = (
  <div className="flex flex-col gap-3">
    <p>
      Congratulations on your award! In the following sections, please upload
      evidence that demonstrates your receipt of this award and its selection
      process.
    </p>
  </div>
);

export const OriginalContributionEvidenceDescription = (
  <div className="flex flex-col gap-3">
    <p>
      We'll argue your original contribution by proving its novelty and
      widespread use and/or that it’s received widespread recognition. Provide
      1-2 documents showing you as the author or main contributor and 1-2 pieces
      of evidence of adoption, press, or commercial impact (e.g., user metrics,
      awards, or media coverage).
    </p>
  </div>
);

export const PressArticleDescription = (
  <div>
    <p className="">
      We’ve got this one covered! We’ll find all publicly available press links
      about you. If there’s a publication or print press you’d like to include,
      please share the URL or upload a document below.{" "}
    </p>
  </div>
);
