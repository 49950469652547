import { useAllExhibitTypesQuery } from "@codegen/index";

export const moduleDefaultNotes: {
  [key: string]: string;
} = {
  "memo-starter": `Field of endeavor:
Filing Type  — Consular or Change of Status:
Business License or Tax Return for companies over a year old:
U.S. Role:
Salary for U.S. Role:
Advisory Opinion Letter Signatory:
Current Status:
Expiry for Current Status:
`,
};

export const useAllExhibitTypesMap = () => {
  const { data, loading } = useAllExhibitTypesQuery();
  return {
    data:
      data?.allExhibitTypes?.nodes?.reduce(
        (acc: Record<string, string>, exhibit) => {
          acc[exhibit?.id ?? ""] = exhibit?.name ?? "";
          return acc;
        },
        {}
      ) ?? {},
    loading,
  };
};

export const defaultHeaders = {
  "memo-starter": {
    title: "Memo Starter",
    exhibits: {
      "employer-documents": {
        title: "Petitioner's Corporate Documents",
        subheader: {
          default:
            "Employer's Corporate Documents.[SHORTBLURBABOUTEMPLOYER — 1-2 lines about the company, any notable information e.g., funding, investors, and valuation). As evidence, we include the following:",
        },
      },
      "ev-letter": {
        title: "Job Offer Letter",
        subheader: {
          default:
            "Employment Offer Letter for the role of [INSERTROLE] at [PETITIONER] in [CITY], [STATE].",
        },
      },
      "beneficiary-cv": {
        title: "Beneficiary's CV",
        subheader: {
          default:
            "The Beneficiary is a nationally and internationally renowned individual in the field of [FIELD]. Most notably, the Beneficiary has [insert three to four sentences describing the Beneficiary's career highlights, leveraging the criteria from the Petition—aim to showcase them in the brightest light as this is typically the first module the reviewer sees]. Please see the Beneficiary's profile attached.",
        },
      },
      "expert-letter": {
        title: "Advisory Opinion Letter",
        subheader: {
          default:
            "Mr. [FIRSTNAME] [LASTNAME], a highly skilled U.S.-based expert with over [X] years of experience in [FIELDOFENDEAVOUR].",
        },
      },
    },
  },
  "critical-role": {
    title: "Critical Role",
    exhibits: {
      "offer-letter": {
        title: "Original Offer Letter",
        subheader: {
          default:
            "Evidence of the Beneficiary's Critical Role. The Beneficiary's job offer letter, demonstrating the Beneficiary's critical role as [Role] at [Company].",
          tech: "Evidence of the Beneficiary's Critical Role. The Beneficiary's job offer letter, demonstrating the Beneficiary's critical role as [Role] at [Company].",
          bizops:
            "The original job offer letter confirms the Beneficiary commenced in the role in [Month Year] at [Company Name].",
          researcher:
            "Evidence of the Beneficiary's Critical Role. The Beneficiary's offer letter, demonstrating the Beneficiary's critical role as [Role] at [Institute or University].",
        },
      },
      "ev-letter": {
        title: "Employment Verification Letter",
        subheader: {
          default:
            "Evidence of the Beneficiary's Critical Role. The Beneficiary's employment verification letter, signed by [INSERT SIGNATORY NAME & TITLE]. The Beneficiary's job duties in the critical role of [INSERTROLETITLE] include the following:",
          tech: "The role of [JOBTITLE] at [COMPANY] is critical because the Beneficiary exercises a broad degree of decision-making authority with little oversight. In this role, the Beneficiary reports only to the [INSERTROLETITLEONORGCHART] and makes key decisions regarding the development and maintenance of the company's technology products. The  Beneficiary manages a team of [NUMBER] of [LISTALLROLETITLES], and further oversees a budget of $[INSERTBUDGETAMOUNT], which further confirms the critical nature of the role. The Beneficiary's role is critical for driving the company's technological advancements and maintaining its competitive edge in the industry.",
          bizops:
            "The role of [JOBTITLE] at [COMPANY] is critical because the Beneficiary exercises a broad degree of decision-making authority with little oversight. In this role, the Beneficiary reports only to the [INSERTROLETITLEONORGCHART] and makes key decisions regarding the company's operations, product commercialization strategies, and business development. The Beneficiary further oversees a budget of $[INSERTBUDGETAMOUNT], which further confirms the critical nature of the role. The Beneficiary's role is critical for driving the company's competitive edge in the industry [or The Beneficiary's role is critical for driving the commercialization of the organization's flagship technology products], and the company's commercial successes are attributed to their impact in the role.",
          researcher:
            "The role of [JOBTITLE] at [INSTITUTE/UNIVERSITY] was critical as the Beneficiary independently developed [DESCRIBE RESEARCH PROJECT/FOCUS]. Reporting directly to [INSERT SUPERVISOR], the Beneficiary's research led to [DESCRIBE IMPACT OF RESEARCH].  The Beneficiary's role is critical in advancing cutting-edge [INSERT INDUSTRY] research and strengthening [INSTITUTE/UNIVERSITY]'s leadership in the field.",
        },
      },
      "org-chart": {
        title: "Org chart showing seniority or managerial responsibility",
        subheader: {
          default:
            "The Beneficiary's role is critical because it is a key position in the organization's structure, and the Beneficiary's role is critical for driving the company's competitive edge in the industry.",
          tech: "Evidence of the Beneficiary's Critical Role. COMPANY NAME's organizational chart illustrating the Beneficiary's role within the staffing hierarchy. The Beneficiary's role is that of [INSERTBENEFICIARYROLETITLE] and they report to the [INSERTROLETITLEONORGCHART]. The Beneficiary's role is therefore one of significant decision-making authority and little oversight. The Beneficiary is responsible for overseeing the overall technology strategy of the organization. They directly manage the [INSERTTEAMNAME], which includes [NUMBER] of [INSERTROLETITLES]. As the [INSERTBENEFICIARYROLETITLE], they are accountable for ensuring the successful development, deployment, and maintenance of the company's technology products and services.",
          bizops:
            "Evidence of the Beneficiary's Critical Role. COMPANY NAME's organizational chart illustrating the Beneficiary's role within the staffing hierarchy. The Beneficiary's role is that of [INSERTBENEFICIARYROLETITLE] and they report to the [INSERTROLETITLEONORGCHART]. The Beneficiary is responsible for overseeing the [business development function or the product commercialization function] of the organization. They directly manage the [INSERTTEAM], which includes [NUMBER] of [INSERTROLETITLES]. As the [INSERTBENEFICIARYROLETITLE], they are accountable for ensuring the successful growth of the company's products and services in the market.",
          researcher:
            "Evidence of the Beneficiary's Critical Role. [INSTITUTE/UNIVERSITY/PROGRAM]'s organizational chart showing that the Beneficiary's role is that of [INSERTBENEFICIARYROLETITLE] and that they report to the [INSERTROLETITLEONORGCHART]. The Beneficiary is responsible for overseeing the [describe the research project or laboratory, etc] of the [university or institute]. They directly manage the [INSERTTEAM], which includes [NUMBER] of [INSERTROLETITLES]. As the [INSERTBENEFICIARYROLETITLE], they are accountable for overseeing the research activities related to [INSERTRESEARCHTOPIC].",
        },
      },
      "internal-docs": {
        title:
          "Internal company documents or presentations authored by you or featuring you",
        subheader: {
          default:
            "Evidence of the Beneficiary's Critical Role. The Beneficiary's internal documents, demonstrating the Beneficiary's critical role as [Role] at [Company].",
          tech: "Evidence of the Beneficiary's Critical Role. We submit documentation [authored by OR featuring] the Beneficiary in their role of [ROLETITLE]. This documentation clearly shows the Beneficiary's authority and ownership over the [PROJECTNAME or COMPANY FUNCTION] at [COMPANYNAME], and confirms their development of the company's critical software systems, databases, and integrations.",
          bizops:
            "Evidence of the Beneficiary's Critical Role. We submit documentation [authored by OR featuring] the Beneficiary in their role of [ROLETITLE].This documentation clearly shows the Beneficiary's authority and ownership over the [PROJECTNAME or COMPANY FUNCTION] at [COMPANYNAME], and confirms their development of the company's critical operations and growth strategies.",
          researcher: `Evidence of the Beneficiary's Critical Role. Published Scholarly Articles highlighting the Beneficiary's authorship of groundbreaking research and innovative findings in [FIELD] during her tenure at [UNIVERSITY OR INSTITUTE]. Some of her published articles include the following: 
          
          -
          -
          -

          The Beneficiary was a primary contributor and leading expert on these studies, directing research objectives and outcomes. That the Beneficiary is the first or key author of multiple groundbreaking research articles published in leading international research journals, such as the [NAME JOURNALS], is undeniable proof of her critical role in advancing the [UNIVERSITY OR INSTITUTE]'s objectives to advance innovation in [FIELD].`,
        },
      },
      "expert-testimonial": {
        title: "Expert Testimonial Letter",
        subheader: {
          default:
            "Evidence of the Beneficiary's Critical Role. The Beneficiary's expert testimonial letter, demonstrating the Beneficiary's critical role as [Role] at [Company].",
        },
      },
      "pitch-deck": {
        title: "Pitch deck",
        subheader: {
          default:
            "Evidence of the Beneficiary's Critical Role. The Beneficiary's pitch deck, demonstrating the Beneficiary's critical role as [Role] at [Company].",
        },
      },
      "original-code": {
        title: "Screenshot of top code contributions page",
        subheader: {
          default:
            "Evidence of the Beneficiary's Critical Role. The Beneficiary's excerpt of original code, demonstrating the Beneficiary's critical role as [Role] at [Company].",
        },
      },
      "code-project-ownership": {
        title:
          "Screenshot of top code contributions page / Screenshot of code project ownership",
        subheader: {
          default:
            "Evidence of the Beneficiary's Critical Role. The Beneficiary is the [CODERANKING e..g, TOP / SECOND] contributor for [COMPANY] 's private platform repository. Please note that since [DATE], the Beneficiary (listed as 'GITHUBTITLE') has contributed a grand total of [NUMBEROFCOMMITS] commits to [REPOSITORYNAME]. This significant contribution underscores their pivotal role as [ROLETITLE], highlighting their critical impact on the company's proprietary technology development.",
        },
      },
      press: {
        title: "Press about the Beneficiary's work",
        subheader: {
          default:
            "Evidence of the Beneficiary's Critical Role. The Beneficiary's press about the Beneficiary's work, demonstrating the Beneficiary's critical role as [Role] at [Company].",
        },
      },
      "beneficiary-acting-as-face-of-company": {
        title: "Beneficiary acting as 'the Face of the Company'",
        subheader: {
          default:
            "Evidence of the Beneficiary's Critical Role. The Beneficiary's role as 'the Face of the Company', demonstrating the Beneficiary's critical role as [Role] at [Company].",
        },
      },
      "company-go-to-market-strategy": {
        title: "Company's Go-To-Market Strategy",
        subheader: {
          default:
            "Evidence of the Beneficiary's Critical Role. The Beneficiary's role in the Company's Go-To-Market Strategy, demonstrating the Beneficiary's critical role as [Role] at [Company].",
        },
      },
      "press-about-company-university": {
        title: "Press about the company/ university",
        subheader: {
          default:
            "Evidence of the Beneficiary's Critical Role. The Beneficiary's press about the company/ university, demonstrating the Beneficiary's critical role as [Role] at [Company].",
        },
      },
      "university-rankings": {
        title: "University Rankings",
        subheader: {
          default:
            "Evidence of the Beneficiary's Critical Role. The Beneficiary's university rankings, demonstrating the Beneficiary's critical role as [Role] at [Company].",
        },
      },
      "vc-funding": {
        title: "VC Funding",
        subheader: {
          default:
            "Evidence of the Beneficiary's Critical Role. The Beneficiary's VC funding, demonstrating the Beneficiary's critical role as [Role] at [Company].",
        },
      },
      "user-count-or-revenue": {
        title: "Subscribers/ waitlist",
        subheader: {
          default:
            "Evidence of the Beneficiary's Critical Role. The Beneficiary's subscribers/ waitlist, demonstrating the Beneficiary's critical role as [Role] at [Company].",
        },
      },
      "crunchbase-profile": {
        title: "Crunchbase profile",
        subheader: {
          default:
            "Evidence of the Beneficiary's Critical Role. The Beneficiary's Crunchbase profile, demonstrating the Beneficiary's critical role as [Role] at [Company].",
        },
      },
      "customer-contract": {
        title: "Contracts with companies or organizations",
        subheader: {
          default:
            "Evidence of the Beneficiary's Critical Role. The Beneficiary's contracts with companies or organizations, demonstrating the Beneficiary's critical role as [Role] at [Company].",
        },
      },
      "google-stock-profile": {
        title: "Google Stock Profile for Larger Companies",
        subheader: {
          default:
            "Evidence of [COMPANY NAME]'s Distinguished Reputation, including documentation of [COMPANY NAME]'s Google Stock profile, demonstrating its market capitalization of $XX trillion USD at a stock price of $XX per share. [COMPANY NAME] is a [DESCRIPTION, e.g., major multinational technology conglomerate and has been in operation since 2004, formerly known as 'Facebook'.]",
        },
      },
      "app-downloads": {
        title: "App Downloads for Larger Companies",
        subheader: {
          default:
            "Evidence of the Beneficiary's Critical Role. The Beneficiary's App Downloads for Larger Companies, demonstrating the Beneficiary's critical role as [Role] at [Company].",
        },
      },
      "company-awards": {
        title: "Company Awards in the Field",
        subheader: {
          default: `Evidence of [COMPANY NAME]'S Distinguished Reputation. Documentation that [COMPANY NAME] was awarded [AWARD NAME] by [AWARD ORGANIZATION], [DESCRIPTION OF ORGANIZATION]. Winners are honored [by a prestigious panel of expert judges] and receive [benefits of award], with thousands of daily visitors to [ORGANIZATION WEBSITE] appreciating and discovering [field of endeavor] work. 

NOTE: This evidence can also be used to show critical role if the award was granted to the beneficiary as well as the company.`,
        },
      },
      "rank-of-university-or-research-institution": {
        title: "Rank of University or Research Institution",
        subheader: {
          default:
            "Evidence of the Beneficiary's Critical Role. The Beneficiary's Rank of University or Research Institution, demonstrating the Beneficiary's critical role as [Role] at [Company].",
        },
      },
      "excerpt-of-uscis-policy": {
        title: "Excerpt of USCIS Policy",
        subheader: {
          default:
            "Evidence of the Beneficiary's Critical Role. The Beneficiary's Excerpt of USCIS Policy, demonstrating the Beneficiary's critical role as [Role] at [Company].",
        },
      },
      "distinguished-reputation": {
        title: "Company Awards, app downloads, or Google Stock Profile",
        subheader: {
          default:
            "Evidence of the Beneficiary's Critical Role. The Beneficiary's Distinguished Reputation, demonstrating the Beneficiary's critical role as [Role] at [Company].",
        },
      },
      "expert-letter": {
        title: "Expert Letter",
        subheader: {
          default: "",
        },
      },
      "code-contributor-page": {
        title: "Code Contributor Page",
        subheader: {
          default: "",
        },
      },
      patent: {
        title: "Patent",
        subheader: {
          default: "",
        },
      },
    },
  },
  "high-remuneration": {
    title: "High Remuneration",
    exhibits: {
      "ev-letter": {
        title: "Employment Verification Letter",
        subheader: {
          default:
            "Evidence of the Beneficiary's Critical Role. The Beneficiary's Employment Verification Letter, demonstrating the Beneficiary's critical role as [Role] at [Company].",
        },
      },
      "offer-letter": {
        title: "Original Offer Letter",
        subheader: {
          default:
            "Evidence of the Beneficiary's Critical Role. The Beneficiary's Original Offer Letter, demonstrating the Beneficiary's critical role as [Role] at [Company].",
        },
      },
      "tax-return": {
        title: "Tax Return",
        subheader: {
          default:
            "Evidence of the Beneficiary's Critical Role. The Beneficiary's Tax Return, demonstrating the Beneficiary's critical role as [Role] at [Company].",
        },
      },
      "shares-and-fmv": {
        title: "Shares and their FMV",
        subheader: {
          default:
            "Evidence of the Beneficiary's Critical Role. The Beneficiary's Shares and their FMV, demonstrating the Beneficiary's critical role as [Role] at [Company].",
        },
      },
      "percentage-ownership": {
        title: "Percentage Ownership",
        subheader: {
          default:
            "Evidence of the Beneficiary's Critical Role. The Beneficiary's Percentage Ownership, demonstrating the Beneficiary's critical role as [Role] at [Company].",
        },
      },
      "total-equity-value": {
        title: "Total Equity Value",
        subheader: {
          default:
            "Evidence of the Beneficiary's Critical Role. The Beneficiary's Total Equity Value, demonstrating the Beneficiary's critical role as [Role] at [Company].",
        },
      },
      "compensation-benchmarks-for-us-role": {
        title: "Compensation Benchmarks for U.S. Role",
        subheader: {
          default:
            "Evidence of the Beneficiary's Critical Role. The Beneficiary's Compensation Benchmarks for U.S. Role, demonstrating the Beneficiary's critical role as [Role] at [Company].",
        },
      },
      "compensation-benchmarks-for-foreign-role": {
        title: "Compensation Benchmarks for Foreign Role",
        subheader: {
          default:
            "Evidence of the Beneficiary's Critical Role. The Beneficiary's Compensation Benchmarks for Foreign Role, demonstrating the Beneficiary's critical role as [Role] at [Company].",
        },
      },
      "uscis-policy-alert-on-equity-compensation": {
        title: "USCIS Policy Alert on Equity Compensation",
        subheader: {
          default:
            "Evidence of the Beneficiary's Critical Role. The Beneficiary's USCIS Policy Alert on Equity Compensation, demonstrating the Beneficiary's critical role as [Role] at [Company].",
        },
      },
      "receipt-of-high-salary-not-applicable": {
        title:
          "Receipt of a high salary is not applicable to the Beneficiary's position as [an entrepreneur / OR / founding startup team member]",
        subheader: {
          default: `Evidence of High Remuneration. Articles demonstrating that a receipt of a high salary is not typical for startup executives and founding employees in the greater field of technology entrepreneurship.
Harvard Business Review published an article titled "How to Make Startup Stock Options a Better Deal for Employees" on April 3, 2019 authored by Steve Blank, an academic at Stanford University, Columbia University, and University of California, Berkeley. The article details how startup compensation has changed stating that stock options have taken the place of high salaries at early stage companies.

- Harvard Business Review published an article titled "Compensation and Benefits for Startup Companies" in January of 1989 by Joseph S. Tibbetts, Jr. and Edmund T. Donovan. The article states that in order for startups to avoid turning off potential investors by offering early employees unrealistically high cash compensation levels they keep cash compensation of early employees lower and offer high stakes of equity.

- Investopedia published an article titled "Equity vs. Salary in Tech: What's the Difference?" on July 30, 2021 by Pete Rathburn. Here the article states equity compensation often goes hand-in-hand with a below-market salary.`,
        },
      },
    },
  },
  "original-contribution": {
    title: "Original Contribution",
    exhibits: {
      "original-contributions-summary": {
        title:
          "Original Contribution of Major Significnace Summary (for a TECH ROLE)",
        subheader: {
          default:
            "The Beneficiary is the originator of a novel technology which is of major significance in the field. The Beneficiary has revolutionized the [INSERT FIELD] field by inventing a novel technology which allows users to [INSERT NOVELTY]. This is in contrast to existing solutions that are currently only capable of [INSERT CURRENT STATE OF THE MARKET]. The Beneficiary engineered and developed a first-of-its-kind software product which is called [INSERT NAME]. This technology is unique because [INSERT ONE TO THREE LINES].",
        },
      },
      "pitch-deck": {
        title: "Company Pitch Deck Excerpt",
        subheader: {
          default:
            "This should only be 2-3 slides maximum, which speak directly to the originality of the product, if the pitch deck doesn't explain why the tech is original, then do not attach it",
        },
      },
      "original-code": {
        title: "Original Code",
        subheader: {
          default:
            "Evidence of the Beneficiary's Critical Role. The Beneficiary's Original Code, demonstrating the Beneficiary's critical role as [Role] at [Company].",
        },
      },
      "technical-doc": {
        title: "Code base details",
        subheader: {
          default:
            "Evidence of the Beneficiary's Critical Role. The Beneficiary's Code base details, demonstrating the Beneficiary's critical role as [Role] at [Company].",
        },
      },
      "product-roadmap": {
        title: "Product Strategy Document",
        subheader: {
          default:
            "Evidence of the Beneficiary's Critical Role. The Beneficiary's Product Strategy Document, demonstrating the Beneficiary's critical role as [Role] at [Company].",
        },
      },
      "evidence-of-citation": {
        title: "Citations evidence",
        subheader: {
          default:
            "Evidence of the Beneficiary's Critical Role. The Beneficiary's Citations evidence, demonstrating the Beneficiary's critical role as [Role] at [Company].",
        },
      },
    },
  },
  authorship: {
    title: "Authorship",
    exhibits: {
      "google-scholar-profile": {
        title: "Google Scholar Profile",
        subheader: {
          default:
            "Evidence of the Beneficiary's Critical Role. The Beneficiary's Google Scholar Profile, demonstrating the Beneficiary's critical role as [Role] at [Company].",
        },
      },
    },
    "journal-article": {
      title:
        "Beneficiary's article, and information about the publishing journal",
      subheader: {
        default:
          "Evidence of the Beneficiary's Critical Role. The Beneficiary's Journal Article, demonstrating the Beneficiary's critical role as [Role] at [Company].",
      },
    },
  },
};
