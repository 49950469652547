interface ContactCardProps {
  name: string;
  email: string;
  image: string;
  onExit: () => void;
}

export function ContactCard({ name, email, image, onExit }: ContactCardProps) {
  return (
    <div className="relative w-full h-full">
      {/* Close button in top-right corner */}
      <button
        onClick={onExit}
        className="absolute p-4 top-8 right-8 text-gray-400 hover:text-gray-600 rounded-[29px] bg-white shadow-[0px_1px_3.4px_rgba(87,126,196,0.25),0px_6px_15.3px_rgba(87,126,196,0.10)] hover:shadow-[0px_2px_6.8px_rgba(87,126,196,0.25),0px_12px_30.6px_rgba(87,126,196,0.10)]"
      >
        <span className="sr-only">Close</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.9005 2.51331C21.291 2.12279 21.291 1.48962 20.9005 1.0991C20.51 0.708575 19.8768 0.708575 19.4863 1.0991L10.9996 9.5858L2.51288 1.0991C2.12236 0.708575 1.4892 0.708575 1.09867 1.0991C0.708147 1.48962 0.708147 2.12279 1.09867 2.51331L9.58537 11L1.09856 19.4868C0.708033 19.8773 0.708033 20.5105 1.09856 20.901C1.48908 21.2916 2.12225 21.2916 2.51277 20.901L10.9996 12.4142L19.4864 20.901C19.8769 21.2916 20.5101 21.2916 20.9006 20.901C21.2911 20.5105 21.2911 19.8773 20.9006 19.4868L12.4138 11L20.9005 2.51331Z"
            fill="#5B7ED7"
          />
        </svg>
      </button>

      <div className="h-full flex items-center justify-center gap-16">
        {/* Profile image */}
        <img
          src={image}
          alt={name}
          className="w-[350px] h-[450px] rounded-2xl object-cover"
        />

        {/* Content */}
        <div className="max-w-md space-y-6">
          <h2 className="text-2xl font-medium text-left">
            Questions? Email {name}, your Plymouth contact.
          </h2>

          <div className="bg-blue-50 rounded-lg p-4 flex items-center justify-between">
            <span className="text-blue-600">{email}</span>
            <button
              onClick={() => navigator.clipboard.writeText(email)}
              className="text-blue-600 hover:text-blue-700 transition-colors"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="32"
                viewBox="0 0 33 32"
                fill="none"
              >
                <path
                  d="M24.5 12H14.5C13.3954 12 12.5 12.8954 12.5 14V24C12.5 25.1046 13.3954 26 14.5 26H24.5C25.6046 26 26.5 25.1046 26.5 24V14C26.5 12.8954 25.6046 12 24.5 12Z"
                  stroke="currentColor"
                  strokeWidth="1.33"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.5 20C7.4 20 6.5 19.1 6.5 18V8C6.5 6.9 7.4 6 8.5 6H18.5C19.6 6 20.5 6.9 20.5 8"
                  stroke="currentColor"
                  strokeWidth="1.33"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>

          <button
            onClick={onExit}
            className="w-full bg-blue-600 text-white py-3 px-6 rounded-lg hover:bg-blue-700 transition-colors font-medium"
          >
            EXIT TUTORIAL
          </button>
        </div>
      </div>
    </div>
  );
}
