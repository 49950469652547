import { Button } from "@components/button";
import Spinner from "@components/spinner";
import { useLogError } from "@utils/error";
import React from "react";
import { CTAContainer } from "./cta-container";
import { OnboardingContainer } from "./onboarding-container";

type WaitingSplashProps =
  | {
      timeout: true;
      onSkip: () => void;
      exitPath: string;
      onBack: () => void;
    }
  | {
      timeout?: false;
      onSkip?: () => void;
      exitPath?: string;
      onBack?: () => void;
    };

export const WaitingSplash = ({
  onSkip,
  exitPath,
  onBack,
}: WaitingSplashProps) => {
  const logError = useLogError();
  const [isStuck, setIsStuck] = React.useState(false);
  React.useEffect(() => {
    const TIMEOUT = 12000;
    const timer = setTimeout(() => {
      logError(new Error("Waiting Splash is timing out"), {
        timeout: TIMEOUT,
        exitPath,
        currentPath: window.location.pathname,
      });
      setIsStuck(true);
    }, TIMEOUT);
    return () => clearTimeout(timer);
  }, [setIsStuck]);

  if (isStuck) {
    return (
      <OnboardingContainer
        title={`:(`}
        subtitle={`This is taking longer than expected.`}
        progress={0}
        exitPath={exitPath}
      >
        <div className="">
          <div className="flex flex-col gap-3">
            <p className="">Don't worry, engineering is on it!</p>
            <p>Pray for them &#128591;</p>
            <p>
              Please try refreshing the page or clicking <b>Next</b> below
            </p>
          </div>
        </div>
        <CTAContainer onBack={onBack}>
          <Button
            variant="accent"
            className="ml-auto text-md rounded-sm px-7 py-5"
            type="button"
            onClick={onSkip}
          >
            Next
          </Button>
        </CTAContainer>
      </OnboardingContainer>
    );
  }

  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <Spinner />
    </div>
  );
};
