import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@components/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, useWatch } from "react-hook-form";

import {
  useCompanyMemberByCompanyIdAndUserIdQuery,
  useUserDatumUpdatesMutation,
  useUserUpdatesMutation,
} from "@codegen/index";
import { UpdateUserByIdInput, UpdateUserDatumInput } from "@codegen/schema";
import { Button } from "@components/button";
import { Input } from "@components/input";
import { EmployerOnboardingContainer } from "@pages/onboarding/employer/forms/employer-onboarding-container";
import { CTAContainer } from "@pages/onboarding/shared/cta-container";
import { FieldsContainer } from "@pages/onboarding/shared/fields-container";
import { useLogError } from "@utils/error";
import { useCallback, useEffect, useState } from "react";
import { z } from "zod";
import { useEmployerOnboarding } from "../../employer-onboarding";
import { useDebounceCallback } from "@react-hook/debounce";
import { useReadOnlyMode } from "@components/readOnlyModeProvider";

const personalSchema = z.object({
  firstName: z.string().min(1, { message: "Required" }),
  lastName: z.string().min(1, { message: "Required" }),
  email: z.string().min(1, { message: "Required" }),
  phone: z.string().min(1, { message: "Required" }),
  role: z.string().min(1, { message: "Required" }),
});

type ConfirmContactFormValues = z.infer<typeof personalSchema>;

export const ConfirmContactForm = () => {
  const { onSubmit, company, contact, onBack, onSkip, onSave } =
    useEmployerOnboarding();
  const { isReadOnly } = useReadOnlyMode();
  const [status, setStatus] = useState<"idle" | "loading" | "error">("idle");
  const [userUpdatesMutation] = useUserUpdatesMutation();
  const [userDatumUpdatesMutation] = useUserDatumUpdatesMutation();
  const logError = useLogError();
  const { data: companyMemberData, refetch: refreshCompanyMember } =
    useCompanyMemberByCompanyIdAndUserIdQuery({
      variables: {
        userId: contact?.id ?? "",
        companyId: company?.value ?? "",
      },
      skip: !company?.value || !contact?.id,
    });

  const form = useForm<ConfirmContactFormValues>({
    resolver: zodResolver(personalSchema),
    defaultValues: {
      firstName: contact?.name?.split(" ")[0],
      lastName: contact?.name?.split(" ")[1],
      email: contact?.email,
      phone: contact?.phoneNumber,
      role: contact?.role,
    },
  });

  const fields: {
    name: keyof ConfirmContactFormValues;
    label: string;
    placeholder?: string;
    type?: string;
  }[] = [
    {
      name: "firstName",
      label: "First name",
    },
    {
      name: "lastName",
      label: "Last name",
    },
    {
      name: "role",
      label: "Title",
      placeholder: "Chief Technology Officer",
    },
    {
      name: "email",
      label: "Email address",
      placeholder: "hi@google.com",
    },
    {
      name: "phone",
      label: "Phone number",
      placeholder: "+16504438260",
    },
  ];

  const watchedValues = useWatch({
    control: form.control,
  });

  const doSubmitPersonal = useCallback(
    async (data: ConfirmContactFormValues) => {
      if (contact == null) return;
      try {
        setStatus("loading");
        const { errors: updateUserError } = await userUpdatesMutation({
          variables: {
            input: {
              clientMutationId: "",
              id: contact?.id ?? "",
              userPatch: {
                fullName: `${data.firstName} ${data.lastName}`,
                email: data.email,
              } as UpdateUserByIdInput["userPatch"],
            },
          },
        });

        const { errors: upateUserDatumError } = await userDatumUpdatesMutation({
          variables: {
            input: {
              clientMutationId: "",
              id: contact?.id ?? "",
              userDatumPatch: {
                phoneNumber: data.phone,
                role: data.role,
              } as UpdateUserDatumInput["userDatumPatch"],
            },
          },
        });

        if (updateUserError || upateUserDatumError) {
          throw {
            updateUserError,
            upateUserDatumError,
          };
        }

        onSubmit({ data, key: "currentContact" });
      } catch (exception) {
        setStatus("error");
        logError(exception, {
          message: "Error saving contact",
        });
      }
    },

    [
      onSubmit,
      company,
      contact,
      userDatumUpdatesMutation,
      userUpdatesMutation,
      refreshCompanyMember,
    ]
  );

  const handleSave = useCallback(
    async (data: ConfirmContactFormValues) => {
      if (contact == null) return;
      try {
        setStatus("loading");
        const { errors: updateUserError } = await userUpdatesMutation({
          variables: {
            input: {
              clientMutationId: "",
              id: contact?.id ?? "",
              userPatch: {
                fullName: `${data.firstName} ${data.lastName}`,
                email: data.email,
              } as UpdateUserByIdInput["userPatch"],
            },
          },
        });

        const { errors: upateUserDatumError } = await userDatumUpdatesMutation({
          variables: {
            input: {
              clientMutationId: "",
              id: contact?.id ?? "",
              userDatumPatch: {
                phoneNumber: data.phone,
                role: data.role,
              } as UpdateUserDatumInput["userDatumPatch"],
            },
          },
        });

        if (updateUserError || upateUserDatumError) {
          throw {
            updateUserError,
            upateUserDatumError,
          };
        }

        onSave({ data, key: "currentContact" });
        setStatus("idle");
      } catch (exception) {
        setStatus("error");
        logError(exception, {
          message: "Error saving contact",
        });
      }
    },

    [
      onSave,
      company,
      contact,
      userDatumUpdatesMutation,
      userUpdatesMutation,
      refreshCompanyMember,
    ]
  );

  const debouncedSave = useDebounceCallback(handleSave, 300);

  useEffect(() => {
    debouncedSave(watchedValues);
  }, [watchedValues, debouncedSave]);

  return (
    <EmployerOnboardingContainer
      title="Your details"
      subtitle={<div></div>}
      cardTitle={"Please confirm your personal details"}
      progress={0}
      form={form}
      onSubmit={doSubmitPersonal}
      name="confirm_contact"
    >
      <FieldsContainer status={status} form={form}>
        {fields.map((x, i) => (
          <FormField
            key={i}
            control={form.control}
            name={x.name}
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-md">{x.label}</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    disabled={isReadOnly}
                    className="h-11"
                    placeholder={x.placeholder}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        ))}
      </FieldsContainer>
      <CTAContainer onBack={onBack} onSkip={onSkip}>
        <Button
          variant="accent"
          className="ml-auto text-md rounded-sm px-7 py-5"
          disabled={false}
          type="submit"
        >
          Next
        </Button>
      </CTAContainer>
    </EmployerOnboardingContainer>
  );
};
