import { useExhibitTypesQuery } from "@codegen/index";
import { CreatorModuleType, moduleExhibitTypeMap } from "@utils/parsers";
import React, { useMemo } from "react";
import { Combobox } from "./combobox";

const ExhibitTypeSelector = (props: {
  selectedType?: string;
  moduleType: string;
  onSelect?: (type: string) => void;
  className?: string;
}) => {
  const { selectedType, onSelect } = props;
  const { data } = useExhibitTypesQuery();

  const opts = useMemo(() => {
    const exhibitTypes = data?.allExhibitTypes?.nodes ?? [];

    const validExhibitTypes =
      moduleExhibitTypeMap[props.moduleType as CreatorModuleType] ?? [];

    if (validExhibitTypes.length === 0) {
      // If we don't have a valid list of exhibit types, just return all exhibit types
      for (const exhibitType of exhibitTypes) {
        if (exhibitType == null) continue;
        if (exhibitType.id == null) continue;

        validExhibitTypes.push(exhibitType.id);
      }
    }

    const options = [];

    for (const exhibitType of exhibitTypes) {
      if (exhibitType == null) continue;
      if (exhibitType.id == null) continue;

      if (!validExhibitTypes.includes(exhibitType.id)) continue;

      options.push({
        value: exhibitType.id,
        label: exhibitType.name,
      });
    }

    return options;
  }, [data?.allExhibitTypes?.nodes, props.moduleType]);

  return (
    <Combobox
      placeholder="Exhibit Type"
      defaultValue={selectedType}
      options={opts}
      onSelect={(value) => onSelect?.(value)}
      className={props.className}
    />
  );
};

const ExhibitTypeSelectorMemo = React.memo(ExhibitTypeSelector);
export default ExhibitTypeSelectorMemo;
