import { Check } from "@phosphor-icons/react";
import { cn } from "@utils/cn";
import { countries } from "countries-list";
import { ChevronsUpDown } from "lucide-react";
import { Button } from "./button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "./command";
import { FormControl } from "./form";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
const CountrySelect = (props: {
  field: {
    value: string;
  };
  onSelect: (x: string) => void;
  disabled?: boolean;
}) => {
  const { field, onSelect, disabled = false } = props;
  const countryValues = Object.entries(countries).map(([key, value]) => ({
    label: value.name,
    value: key,
  }));

  return (
    <Popover>
      <PopoverTrigger asChild>
        <FormControl>
          <Button
            type="button"
            variant="outline"
            role="combobox"
            className={cn(
              "w-full h-11 bg-white justify-between",
              !field.value && "text-muted-foreground"
            )}
            disabled={disabled}
          >
            {field.value
              ? countryValues.find((country) => country.value === field.value)
                  ?.label
              : "Select Country"}
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </FormControl>
      </PopoverTrigger>
      <PopoverContent className="w-full h-[250px]" side="bottom">
        <Command>
          <CommandInput placeholder="Search country..." />
          <CommandEmpty>No country found.</CommandEmpty>
          <CommandGroup>
            {countryValues.map((country) => (
              <CommandItem
                value={country.label}
                key={country.value}
                onSelect={() => onSelect(country.value)}
              >
                <Check
                  size={4}
                  className={cn(
                    "mr-2 h-4 w-4",
                    country.value === field.value ? "opacity-100" : "opacity-0"
                  )}
                />
                {country.label}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export default CountrySelect;
