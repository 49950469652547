import { Button } from "@components/button";
import PlymouthIcon from "@components/icons/plymouth";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MainNav, PageOptions } from "./main";
import TeamSwitcher from "./teamSwitcher";

const Navbar = () => {
  const location = useLocation();
  const nav = useNavigate();
  const currentPage = useMemo(() => {
    const page = location.pathname.split("/")[1];
    if (page === "") {
      return "home";
    }
    return page as PageOptions;
  }, [location]);

  return (
    <div className="relative z-10 bg-paleblue flex flex-col items-center gap-y-4 h-full py-5 px-4 border shadow-md">
      <Button
        variant="ghost"
        size="icon"
        className="w-[44px] h-[44px] hover:bg-transparent mb-8"
        onClick={() => nav("/")}
      >
        <div className="h-[20px] w-[20px]">
          <PlymouthIcon />
        </div>
      </Button>

      <MainNav selectedPage={currentPage} />
      <div className="mt-auto flex flex-row gap-x-2">
        <TeamSwitcher />
      </div>
    </div>
  );
};

export default Navbar;
