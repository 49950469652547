import Confetti from "react-confetti";

export const PlymouthConfetti = ({
  width = window.outerWidth,
  height = window.outerHeight,
}: {
  width?: number;
  height?: number;
}) => {
  return <Confetti width={width} height={height} />;
};
