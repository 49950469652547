import { cn } from "@utils/cn";

export const LoadingTasks = ({
  count,
  widths = [
    "w-1/2",
    "w-1/3",
    "w-2/3",
    "w-1/4",
    "w-2/4",
    "w-3/4",
    "w-1/5",
    "w-2/5",
    "w-3/5",
    "w-4/5",
    "w-1/6",
    "w-2/6",
    "w-3/6",
    "w-4/6",
    "w-5/6",
    "w-1/12",
    "w-2/12",
    "w-3/12",
    "w-4/12",
    "w-5/12",
    "w-6/12",
    "w-7/12",
    "w-8/12",
    "w-9/12",
    "w-10/12",
    "w-11/12",
  ],
}: {
  count: number;
  widths?: string[];
}) => (
  <div className="flex flex-col gap-5">
    {Array.from({ length: count }).map((_, index) => (
      <div
        key={index}
        className="flex gap-3 items-center border rounded-sm p-3 bg-card"
      >
        <div className="w-4 h-4 bg-slate-100 animate-pulse" />
        <div className="w-11 h-10 bg-slate-100 animate-pulse rounded-full" />
        <div className="w-full">
          <div
            className={cn(
              "w-3/4 mb-3 h-4 bg-slate-200 animate-pulse rounded",
              widths[Math.floor(Math.random() * 10)]
            )}
          />
          <div
            className={cn(
              "w-full h-4 bg-slate-100 animate-pulse rounded",
              widths[Math.floor(Math.random() * 10)]
            )}
          />
        </div>
      </div>
    ))}
  </div>
);
