import { Form } from "@components/form";
import { Progress } from "@components/progress";
import { ErrorBoundary } from "@sentry/react";
import { useStatsigClient } from "@statsig/react-bindings";
import React from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";

type OnboardingContainerProps<TFields extends FieldValues> = {
  children: React.ReactNode;
  progress: number;
  title: string;
  subtitle?: React.ReactNode;
  cardTitle?: React.ReactNode;
  form?: UseFormReturn<TFields>;
  onSubmit?: any;
  name?: string;
};

export const EmployerOnboardingContainer = <TFields extends FieldValues>(
  props: OnboardingContainerProps<TFields>
) => {
  const {
    children,
    title,
    subtitle,
    cardTitle,
    progress,
    form,
    onSubmit,
    name,
  } = props;
  const { client } = useStatsigClient();
  React.useEffect(() => {
    if (client && name) {
      client.logEvent("employer_onboarding_view", name);
    }
  }, [client, name]);

  return (
    <ErrorBoundary>
      <div className="w-screen min-h-screen overflow-scroll bg-[url(/bg-journey.svg)] translate-x-[-140px]">
        <div className="h-screen w-full">
          <div className="w-full h-full flex flex-col relative">
            <Progress value={progress} className="w-full" />
            <div className="flex flex-col gap-y-2 m-auto w-[571px] h-full overflow-y-scroll pt-12 pb-24 justify-center">
              <div className="flex flex-col ">
                <div className="space-y-2">
                  <h1 className="text-2xl font-semibold">{title}</h1>
                  {subtitle && <h2 className="text-md">{subtitle}</h2>}
                </div>
                {cardTitle && (
                  <h3 className="text-md font-semibold mt-4">{cardTitle}</h3>
                )}
              </div>
              {form ? (
                <Form {...form}>
                  <form
                    data-testid="employer-onboarding-form"
                    onSubmit={form?.handleSubmit(onSubmit)}
                    className="flex flex-col basis-3/4 rr-mask"
                  >
                    {children}
                  </form>
                </Form>
              ) : (
                <div className="flex flex-col justify-between basis-3/4">
                  {children}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};
