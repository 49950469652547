import React from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { CTAContainer } from "@pages/onboarding/shared/cta-container";
import { OnboardingContainer } from "@pages/onboarding/shared/onboarding-container";
import { FieldsContainer } from "@pages/onboarding/shared/fields-container";
import { FormErrorMessage } from "@pages/onboarding/shared/error";
import { OnboardingPageType } from "@pages/onboarding/types";
import { z } from "zod";
import { Textarea } from "@components/textArea";
import { ErrorMessage } from "@hookform/error-message";
import { zodResolver } from "@hookform/resolvers/zod";
import { WorkflowAwardExhibit } from "@codegen/enums";
import { Button } from "@components/button";
import { FormControl, FormItem } from "@components/form";
import {
  HoverCard,
  HoverCardArrow,
  HoverCardContent,
  HoverCardTrigger,
} from "@components/hoverCard";
import { useDebounceCallback } from "@react-hook/debounce";
import Spinner from "@components/spinner";
import { useReadOnlyMode } from "@components/readOnlyModeProvider";

const DescriptionFormSchema = z.object({
  description: z.string().min(1, "Required"),
});
type DescriptionFormValues = z.infer<typeof DescriptionFormSchema>;

export const DescriptionForm: React.FC<
  OnboardingPageType & {
    title: string;
    subtitle: React.ReactNode;
    cardTitle: React.ReactNode;
  }
> = ({
  onSubmit,
  onBack,
  onSkip,
  autoSave,
  isSaving,
  exhibit,
  title,
  subtitle,
  cardTitle,
  onSkipForNow,
}) => {
  const { required, data } = exhibit;
  const { description }: { description?: string } = data ?? {};
  const methods = useForm({
    defaultValues: {
      description: description,
    },
    resolver: zodResolver(DescriptionFormSchema),
  });
  const { register, formState, control } = methods;

  const { isReadOnly } = useReadOnlyMode();

  const watchedValues = useWatch({
    control,
  });

  const handleSave = (data: DescriptionFormValues) => {
    autoSave(data);
  };
  const handleSubmit = (data: DescriptionFormValues) => {
    onSubmit(data);
  };

  const debouncedSave = useDebounceCallback(handleSave, 300);

  React.useEffect(() => {
    debouncedSave(watchedValues);
  }, [watchedValues, debouncedSave]);

  return (
    <FormProvider {...methods}>
      <OnboardingContainer
        title={title}
        subtitle={subtitle}
        cardTitle={cardTitle}
        progress={0}
        onSubmit={handleSubmit}
        exhibit={exhibit}
        name={`description-form`}
      >
        <FieldsContainer status={""}>
          <FormItem className="mt-3">
            <FormControl>
              <Textarea
                {...register("description")}
                disabled={isReadOnly}
                className="bg-white"
                placeholder="Enter text here..."
                rows={6}
              />
            </FormControl>
            <ErrorMessage
              errors={formState.errors}
              name="description"
              render={({ message }) => (
                <FormErrorMessage>{message}</FormErrorMessage>
              )}
            />
          </FormItem>
        </FieldsContainer>

        <CTAContainer
          onBack={onBack}
          onSkip={required ? () => onSkipForNow?.() : onSkip}
        >
          <Button
            variant="accent"
            className="ml-auto text-md rounded-sm px-7 py-5"
            // disabled={!formState.isValid}
            type="submit"
          >
            {isSaving ? <Spinner /> : "Next"}
          </Button>
        </CTAContainer>
      </OnboardingContainer>
    </FormProvider>
  );
};

export const AwardDescriptionForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit } = props;
  const { name, award } = exhibit as WorkflowAwardExhibit;

  const title = name ? name : `Awards - ${award.awardName}`;
  const subtitle = `Have you received recognition for your outstanding work? Whether it's an industryaccolade, a professional honor, or a notable prize, we're excited to learn about it!`;
  const cardTitle = `Can you tell us about the award and why it’s a big deal in your field?`;
  return (
    <DescriptionForm
      {...props}
      title={title}
      subtitle={subtitle}
      cardTitle={cardTitle}
    />
  );
};

export const PressSummaryForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit } = props;
  const { name } = exhibit as WorkflowAwardExhibit;

  const title = name;
  const subtitle = `Provide a summary for the press you received`;
  const cardTitle = `Summary`;
  return (
    <DescriptionForm
      {...props}
      title={title}
      subtitle={subtitle}
      cardTitle={cardTitle}
    />
  );
};

export const OriginalContributionDescriptionForm: React.FC<
  OnboardingPageType
> = (props) => {
  const { exhibit } = props;
  const { name } = exhibit;
  const title = name ? name : `Original Contributions of Major Significance`;
  const subtitle = (
    <div className="flex flex-col gap-3">
      <p>
        Have you created something innovative that's made waves in your
        industry? Maybe you've developed a groundbreaking technology, proposed a
        new theory, or pioneered a novel business approach? We're looking for
        evidence that your work has truly moved the needle.
      </p>
      <p className="font-semibold">Please finish this sentence:</p>
      <p>
        <HoverCard>
          <HoverCardTrigger>
            I designed an original _____________________. My novel design is
            called _____________. This is considered original because
            _____________________. An expert in the field would describe my
            novel design as remarkable because___________________________.
          </HoverCardTrigger>
          <HoverCardContent className="w-[450px]">
            <div className="text-sm">
              <p className="mb-2">
                I designed an original{" "}
                <em>
                  machine learning algorithm for predictive maintenance in
                  industrial equipment
                </em>
                . My novel design is called <em>"CascadePredict"</em>. This is
                considered original because{" "}
                <em>
                  it combines elements of reinforcement learning and anomaly
                  detection to create a more adaptive and precise system for
                  predicting equipment failures.
                </em>
              </p>
              <p>
                An expert in the field would describe my novel design as
                remarkable because{" "}
                <em>
                  it significantly reduces false positives while improving early
                  detection rates, saving companies like Tesla millions in
                  unnecessary downtime and maintenance costs.
                </em>
              </p>
            </div>
            <HoverCardArrow className="fill-white" />
          </HoverCardContent>
        </HoverCard>
      </p>
    </div>
  );

  return (
    <DescriptionForm
      {...props}
      title={title}
      subtitle={subtitle}
      cardTitle={`Your input`}
    />
  );
};
