import { useAllCompanyDocsQuery } from "@codegen/index";
import { useEmployerOnboarding } from "@pages/onboarding/employer-onboarding";
import { assign, setup, Snapshot } from "xstate";

interface OtherCompensation {
  equityAndStandardBenefits: boolean;
  otherGuaranteedPay: boolean;
  other: string;
}

interface USRoleOffer {
  roleTitle: string;
  proposedSalary: number;
  salaryFrequency: "annual" | "monthly" | "weekly" | "hourly";
  otherCompensation: OtherCompensation;
  startDate: string;
  isFullTime: boolean;
  hoursPerWeek?: number;
  employs25FullTimeOrEquivalent: boolean;
}

interface ImmigrationQuestions {
  filedImmigrantPetition: boolean;
  basisForClassification: "newEmployer" | "changeOfEmployer";
}

interface BeneficiaryInfo {
  usRoleOffer: USRoleOffer | null;
  immigrationQuestions: ImmigrationQuestions | null;
}

export interface OnboardingContext {
  corporateDocsFileIds: string[];
  foreignDocsFileIds: string[];
  employerFundingDocsFileIds: string[];
  currentContact: {
    title?: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    isAuthorizedSignatory: "yes" | "no";
  } | null;
  newContact?: {
    title: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    isAuthorizedSignatory: "yes";
  } | null;
  companyHQAddress: {
    street: string;
    city: string;
    state: string;
    country: string;
    zip: string;
    isBeneficiaryWorksite: "yes" | "no";
  } | null;
  newWorksiteAddress?: {
    street: string;
    city: string;
    state: string;
    country: string;
    zip: string;
    isBeneficiaryWorksite: "yes";
  } | null;
  employerHistory: {
    companyTypeOfBusiness: string;
    currentNumberOfEmployees: number;
    companyGrossAnnualIncome: number;
    companyNetAnnualIncome: number;
  } | null;
  currentPetitionIndex: number;
  beneficiaries: BeneficiaryInfo[];
}

type OnboardingEvent =
  | { type: "NEXT" }
  | { type: "BACK" }
  | { type: "UPDATE_CONTEXT"; data: Partial<OnboardingContext> }
  | { type: "SET_DATA"; key: keyof OnboardingContext; value: any };

export const usePersistedOnboardingMachine = (companyId: string) => {
  const getOnboardingState = (): Snapshot<OnboardingContext> | undefined => {
    const storage = localStorage.getItem(
      `employer-onboarding-state-${companyId}`
    );
    const restoredState = storage ? JSON.parse(storage) : undefined;
    return restoredState;
  };

  const setOnboardingState = (state: Snapshot<OnboardingContext>) => {
    localStorage.setItem(
      `employer-onboarding-state-${companyId}`,
      JSON.stringify(state)
    );
  };

  return {
    getOnboardingState,
    setOnboardingState,
  };
};

export const onboardingMachine = setup({
  types: {
    context: {} as OnboardingContext,
    events: {} as OnboardingEvent,
  },
  actions: {
    assignContext: ({ context, event }) => {
      if (event.type === "UPDATE_CONTEXT") {
        return {
          ...context,
          ...event.data,
        };
      }
      return context;
    },
    setData: assign(({ context, event }) => {
      if (event.type === "SET_DATA") {
        let status = "complete";
        for (const value of Object.values(event.value)) {
          if (!value || (Array.isArray(value) && value.length === 0)) {
            status = "incomplete";
          }
        }

        const newValue = { ...event.value, status };

        return {
          ...context,
          [event.key]: newValue,
        };
      }
      return context;
    }),
  },
  guards: {
    isContactNotAuthorizedSignatory: ({ context }) => {
      return context.currentContact?.isAuthorizedSignatory === "no";
    },
    isHQNotBeneficiaryWorksite: ({ context }) =>
      context.companyHQAddress?.isBeneficiaryWorksite === "no",
    isFormCompleted: ({ context }) => {
      const incomplete = Object.values(context).filter(
        (form) => form.status === "incomplete"
      );
      return incomplete.length === 0;
    },
  },
}).createMachine({
  id: "onboarding",
  initial: "welcome",
  context: ({ input }) => ({
    corporateDocsFileIds: [],
    foreignDocsFileIds: [],
    employerFundingDocsFileIds: [],
    currentContact: null,
    companyHQAddress: null,
    employerHistory: null,
    currentPetitionIndex: 0,
    beneficiaries: [],
    ...input,
  }),
  on: {
    SET_DATA: {
      actions: "setData",
    },
  },
  states: {
    welcome: {
      on: {
        NEXT: "confirmContact",
      },
    },
    confirmContact: {
      on: {
        NEXT: [{ target: "addContact" }],
        BACK: "welcome",
      },
    },
    addContact: {
      on: {
        NEXT: "confirmAddress",
        BACK: "confirmContact",
      },
    },
    confirmAddress: {
      on: {
        NEXT: [
          { target: "addWorksite", guard: "isHQNotBeneficiaryWorksite" },
          { target: "beneficiary" },
        ],
        BACK: [{ target: "addContact" }],
      },
    },
    addWorksite: {
      on: {
        NEXT: "beneficiary",
        BACK: "confirmAddress",
      },
    },
    beneficiary: {
      on: {
        NEXT: "employerHistory",
        BACK: [
          { target: "addWorksite", guard: "isHQNotBeneficiaryWorksite" },
          { target: "confirmAddress" },
        ],
      },
    },
    employerHistory: {
      on: {
        NEXT: "corporateDocs",
        BACK: "beneficiary",
      },
    },
    corporateDocs: {
      on: {
        NEXT: "foreignDocs",
        BACK: "employerHistory",
      },
    },
    foreignDocs: {
      on: {
        NEXT: "employerFundingDocs",
        BACK: "corporateDocs",
      },
    },
    employerFundingDocs: {
      on: {
        NEXT: [{ target: "final", guard: "isFormCompleted" }],
        BACK: "foreignDocs",
      },
    },
    final: {
      type: "final",
    },
  },
});
