import { WorkflowExhibit, WorkflowModule } from "@codegen/enums";
import {
  useCreateExhibitFileMutation,
  useUpdateExhibitByIdMutation,
  useUpdateOnboardingByIdMutation,
} from "@codegen/index";
import {
  CaseStrategy,
  CreateExhibitFileInput,
  UpdateExhibitByIdInput,
  UpdateOnboardingByIdInput,
} from "@codegen/schema";
import { WaitingSplash } from "@pages/onboarding/shared/waiting";
import { OnboardingPageType } from "@pages/onboarding/types";
import { SupportProvider } from "@providers/support";
import { useLogError } from "@utils/error";
import { PlymouthUser, StrategyModule, Team } from "@utils/types";
import * as React from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { ForeignAddressForm, USAddressForm } from "./forms/address-form";
import { OnboardingControllerCover } from "./forms/cover-page";
import {
  OriginalContributionDescriptionForm,
  PressSummaryForm,
} from "./forms/description-form";
import { EADCardFormType, EadCardForm } from "./forms/ead-card-form";
import { EmploymentHistoryFormController } from "./forms/employment-history";
import {
  ExpertLetterFormType,
  ExpertLetterRenderer,
} from "./forms/expert-letters-form";
import {
  AcademicContractProposalDocuments,
  AcademicPapersDocuments,
  AwardCapTableForm,
  AwardEvidenceOfSelectivityForm,
  CalendarForm,
  CodeContributionDocumentsForm,
  CodeProjectOwnershipForm,
  ConferencePresentationForm,
  CustomerContractDocuments,
  DistinguishedReputationDocuments,
  EVLetterForm,
  EquityStatementForm,
  EvidenceIndustryAwardsForm,
  EvidenceOfCommercialAdoptionForm,
  EvidenceOfRegconitionForm,
  EvidenceOfSignificantUsageForm,
  FileFormType,
  FundingDocuments,
  InternalDocuments,
  JudgesProfileForm,
  JudgingInviteOrConfirmationForm,
  JudgingScreenshotOfEventOrPrizesForm,
  JudgingSelectionCriteriaForm,
  MembershipLetterOrMailForm,
  MembershipProfilePagelForm,
  OCOriginalCodeForm,
  OCProductRoadmapForm,
  OCTechnicalDocumentsForm,
  OCUserCountOrRevenueForm,
  OfferLetterForm,
  OrgChartForm,
  PayBonusForm,
  PayRaiseEvidenceForm,
  PayStubsForm,
  PitchDeckForm,
  PromotionDocumentForm,
  TaxReturnForm,
  VCEvidenceForm,
} from "./forms/file-form";
import {
  AwardDocumentsForm,
  FileFormWithDescriptionType,
} from "./forms/file-form-with-description";
import {
  EvidenceOfCitationForm,
  FileFormType as FileFormWithLinksType,
  GoogleScholarArticleForm,
  JournalArticleWithLinksForm,
  JudgingDocumentForm,
  MembershipDocuments,
  OriginalContributionEvidenceForm,
  PatentForm,
  PressArticleForm,
  PublicationProminenceForm,
} from "./forms/file-form-with-links";
import FilingTypeForm, { FilingTypeFormType } from "./forms/filing-type-form";
import { ImmigrationQuestionsFormController } from "./forms/immigration-questions-form";
import LegalNameForm, { LegalNameFormType } from "./forms/legal-name-form";
import {
  LinkToEventForm,
  FileFormType as LinksFormType,
  MajorPublicationWorkLinks,
} from "./forms/links-form";
import { NotFoundPage } from "./forms/not-found";
import PassportForm, { PassportFormType } from "./forms/passport-form";
import {
  PreviousNamesFormController,
  PreviousNamesFormType,
} from "./forms/previous-names-form";
import {
  StudentVisaForm,
  StudentVisaFormType,
} from "./forms/student-visa-form";
import VisaForm, { VisaFormType } from "./forms/visa-stamp-form";
import { OnboardingContext } from "./machines";
import { BeneficiaryOnboardingContext } from "./machines/machine-provider";
import { ReadOnlyModeProvider } from "@components/readOnlyModeProvider";

// Helper type to ensure files property exists on form data
type FormDataWithFiles = {
  files?: string[];
};

type OnboardingFormDataType =
  | FileFormType
  | FileFormWithLinksType
  | LinksFormType
  | VisaFormType
  | EADCardFormType
  | PassportFormType
  | LegalNameFormType
  | FilingTypeFormType
  | StudentVisaFormType
  | ExpertLetterFormType
  | PreviousNamesFormType
  | FileFormWithDescriptionType;

type BeneficiaryOnboardingType = {
  caseStrategy?: Pick<
    CaseStrategy,
    "id" | "nodeId" | "strategy" | "label"
  > | null;
  company: Team;
  contact: PlymouthUser;
  modules: StrategyModule[];
  currentModuleIndex: number;
  currentExhibitIndex: number;
  petition?: OnboardingPageType["petition"];
  onboarding?: OnboardingPageType["onboarding"];
};

export const BeneficiaryOnboarding: React.FC<BeneficiaryOnboardingType> = ({
  petition,
  company,
  contact,
  modules,
  currentModuleIndex: defaultModuleIndex,
  currentExhibitIndex: defaultExhibitIndex,
  onboarding,
}) => {
  const actor = BeneficiaryOnboardingContext.useActorRef();
  const [currentContext, setCurrentContext] = React.useState<
    OnboardingContext | undefined
  >();
  const [currentState, setCurrentState] = React.useState<string | undefined>();
  const logError = useLogError();
  const currentModuleIndex = currentContext?.currentModuleIndex;
  const currentExhibitIndex = currentContext?.currentExhibitIndex;
  const machineModules = currentContext?.modules;
  const currentModule = machineModules?.[currentModuleIndex ?? 0];
  const currentExhibit = currentModule?.exhibits[currentExhibitIndex ?? 0];
  const [saveOnboarding] = useUpdateOnboardingByIdMutation();
  const [saveExhibit] = useUpdateExhibitByIdMutation();
  const [addFileToExhibit] = useCreateExhibitFileMutation();

  const [isSaving, setIsSaving] = React.useState(false);

  React.useEffect(() => {
    if (modules) {
      actor.send({
        type: "INIT_MODULES",
        modules,
        defaultModuleIndex,
        defaultExhibitIndex,
      });

      const subscription = actor.subscribe((snapshot) => {
        console.log("snapshot", snapshot);
        setCurrentContext(snapshot.context);
        setCurrentState(snapshot.value);
        savePayload(snapshot);
      });

      return () => {
        subscription.unsubscribe();
      };
    }
  }, [
    modules,
    actor,
    defaultModuleIndex,
    defaultModuleIndex,
    setCurrentContext,
  ]);

  const savePayload = (snapshot?: ReturnType<typeof actor.getSnapshot>) => {
    const contextSnapshot = snapshot ?? actor.getSnapshot();
    if (
      contextSnapshot.context?.modules &&
      contextSnapshot.context?.modules?.length > 0
    ) {
      try {
        // Try to save onboarding payload
        saveOnboarding({
          variables: {
            input: {
              id: onboarding?.id,
              onboardingPatch: {
                modules: JSON.stringify(contextSnapshot.context.modules),
              },
            } as UpdateOnboardingByIdInput,
          },
        });
      } catch (error) {
        logError(error, {
          petitionId: petition?.id,
          onboardingId: onboarding?.id,
          contactId: contact.id,
          companyId: company.value,
        });
      }
    }
  };

  const saveExhibitData = (
    module: WorkflowModule,
    exhibit: WorkflowExhibit,
    data: OnboardingFormDataType
  ) => {
    actor.send({
      type: "SET_DATA",
      moduleId: module.id,
      exhibitId: exhibit?.id,
      data,
    });
  };

  /**
   * Handles saving exhibit metadata and uploading associated files
   * @param exhibitId The ID of the exhibit
   * @param data Form data containing metadata and possibly files
   */
  const saveExhibitMetadataAndFiles = async (
    exhibitId: number,
    data: OnboardingFormDataType
  ) => {
    try {
      // Save exhibit metadata
      await saveExhibit({
        variables: {
          input: {
            clientMutationId: "",
            id: exhibitId.toString(),
            exhibitPatch: {
              metadata: JSON.stringify(data),
            },
          } as UpdateExhibitByIdInput,
        },
      });

      // Handle file uploads if present
      const filesData = data as FormDataWithFiles;
      if (
        filesData.files &&
        Array.isArray(filesData.files) &&
        filesData.files.length > 0
      ) {
        for (const fileId of filesData.files) {
          try {
            await addFileToExhibit({
              variables: {
                input: {
                  clientMutationId: "",
                  exhibitFile: {
                    fileId: fileId,
                    exhibitId: exhibitId,
                  },
                } as unknown as CreateExhibitFileInput, // Use unknown to fix type error
              },
            });
          } catch (error) {
            logError(error, {
              message: "Error adding file to exhibit",
              exhibitId: exhibitId,
              fileId: fileId,
            });
          }
        }
      }
    } catch (error) {
      logError(error, {
        message: "Error saving exhibit metadata or files",
        exhibitId: exhibitId,
      });
    }
  };

  const handleSave = (data?: OnboardingFormDataType) => {
    if (data && currentModule && currentExhibit) {
      setIsSaving(true);
      actor.send({
        type: "SET_DATA",
        moduleId: currentModule?.id,
        exhibitId: currentExhibit?.id,
        data,
        status: "completed",
      });
      try {
        // Try to save exhibit metadata and upload files
        if (currentExhibit.exhibitId) {
          saveExhibitMetadataAndFiles(currentExhibit.exhibitId, data).then(
            () => {
              setIsSaving(false);
            }
          );
        } else {
          setIsSaving(false);
        }
      } catch (error) {
        logError(error, {
          petitionId: petition?.id,
          onboardingId: onboarding?.id,
          contactId: contact.id,
          companyId: company.value,
          exhibitId: currentExhibit.id,
          moduleId: currentModule.id,
        });
      }
    }
  };

  const handleNext = (data?: OnboardingFormDataType) => {
    if (data && currentModule && currentExhibit) {
      setIsSaving(true);
      actor.send({
        type: "SET_DATA",
        moduleId: currentModule?.id,
        exhibitId: currentExhibit?.id,
        data,
        status: "completed",
      });

      try {
        // Try to save exhibit metadata and upload files
        if (currentExhibit.exhibitId) {
          saveExhibitMetadataAndFiles(currentExhibit.exhibitId, data).then(
            () => {
              setIsSaving(false);
            }
          );
        } else {
          setIsSaving(false);
        }
      } catch (error) {
        logError(error, {
          petitionId: petition?.id,
          onboardingId: onboarding?.id,
          contactId: contact.id,
          companyId: company.value,
          exhibitId: currentExhibit.id,
          moduleId: currentModule.id,
        });
      }
    }

    actor.send({ type: "NEXT_EXHIBIT" });
  };

  const handleSkip = () => {
    if (currentExhibit && currentModule) {
      actor.send({
        type: "SET_EXHIBIT_STATUS",
        moduleId: currentModule?.id,
        exhibitId: currentExhibit?.id,
        status: "skipped",
      });
    }

    actor.send({ type: "NEXT_EXHIBIT" });
  };

  const handleSkipForNow = (data?: OnboardingFormDataType) => {
    if (currentExhibit && currentModule) {
      if (data) {
        actor.send({
          type: "SET_DATA",
          moduleId: currentModule?.id,
          exhibitId: currentExhibit?.id,
          data,
          status: "pending",
        });
      } else {
        actor.send({
          type: "SET_EXHIBIT_STATUS",
          moduleId: currentModule?.id,
          exhibitId: currentExhibit?.id,
          status: "pending",
        });
      }

      // Try to save exhibit metadata and upload files
      if (data && currentExhibit.exhibitId) {
        saveExhibitMetadataAndFiles(currentExhibit.exhibitId, data);
      }
    }

    actor.send({ type: "NEXT_EXHIBIT" });
  };

  const handlePrev = () => {
    if (currentExhibitIndex && currentExhibitIndex > 0) {
      actor.send({ type: "PREV_EXHIBIT" });
    } else if (currentExhibitIndex === 0) {
      actor.send({ type: "PREV_MODULE" });
    } else if (currentExhibitIndex === 0 && currentModuleIndex === 0) {
      navigate({
        pathname: "/onboarding/beneficiary",
        search:
          new URLSearchParams(location.search).get("readOnly") === "true"
            ? createSearchParams({ readOnly: "true" }).toString()
            : "",
      });
    }
  };

  const Form = React.useMemo(() => {
    switch (currentExhibit?.type) {
      case "LEGAL_NAME":
        return LegalNameForm;
      case "OTHER_NAMES":
        return PreviousNamesFormController;
      case "US_ADDRESS":
        return USAddressForm;
      case "FOREIGN_ADDRESS":
        return ForeignAddressForm;
      case "IMMIGRATION_QUESTIONS":
        return ImmigrationQuestionsFormController;
      case "PASSPORT":
        return PassportForm;
      case "VISA_STAMP":
        return VisaForm;
      case "STUDENT_VISA":
        return StudentVisaForm;
      case "EAD":
        return EadCardForm;
      case "EMPLOYMENT_HISTORY":
        return EmploymentHistoryFormController;
      case "OFFER_LETTER":
        return OfferLetterForm;
      case "EV_LETTER":
        return EVLetterForm;
      case "ORG_CHART":
        return OrgChartForm;
      case "CALENDAR":
        return CalendarForm;
      case "PROOF_OF_MEMBERSHIP_LETTER_OR_EMAIL":
        return MembershipLetterOrMailForm;
      case "PROOF_OF_MEMBERSHIP_PROFILE_PAGE":
        return MembershipProfilePagelForm;
      case "MEMBERSHIP_DOCUMENTATION":
        return MembershipDocuments;
      case "PROFILE_OF_JUDGES":
      case "JUDGES":
        return JudgesProfileForm;
      case "SCREENSHOT_OF_EVENT_OR_PRIZES":
        return JudgingScreenshotOfEventOrPrizesForm;
      case "LINK_TO_EVENT":
        return LinkToEventForm;
      case "PROMOTION_EVIDENCE":
        return PromotionDocumentForm;
      case "PAY_RAISE_EVIDENCE":
        return PayRaiseEvidenceForm;
      case "CONFERENCE_PRESENTATION":
        return ConferencePresentationForm;
      case "JUDGING_INVITE_OR_CONFIRMATION":
        return JudgingInviteOrConfirmationForm;
      case "EVIDENCE_OF_SELECTION_CRITERIA":
        return JudgingSelectionCriteriaForm;
      case "PRODUCT_ROADMAP":
        return OCProductRoadmapForm;
      case "TECHNICAL_DOC":
        return OCTechnicalDocumentsForm;
      case "TAX_RETURN":
        return TaxReturnForm;
      case "PAY_STUBS":
        return PayStubsForm;
      case "PAY_BONUS":
        return PayBonusForm;
      case "EQUITY_STATEMENT":
        return EquityStatementForm;
      case "EVIDENCE_OF_RECOGNITION":
        return EvidenceOfRegconitionForm;
      case "CODE_CONTRIBUTION":
        return CodeContributionDocumentsForm;
      case "CODE_CONTRIBUTOR_PAGE":
        return CodeContributionDocumentsForm;
      case "CODE_PROJECT_OWNERSHIP":
        return CodeProjectOwnershipForm;
      case "ORIGINAL_CODE":
        return OCOriginalCodeForm;
      case "EVIDENCE_OF_CITATION":
        return EvidenceOfCitationForm;
      case "EVIDENCE_OF_COMMERCIAL_ADOPTION":
        return EvidenceOfCommercialAdoptionForm;
      case "EVIDENCE_OF_SIGNIFICANT_USAGE":
        return EvidenceOfSignificantUsageForm;
      case "EVIDENCE_OF_INDUSTRY_AWARDS":
        return EvidenceIndustryAwardsForm;
      case "USER_COUNT_OR_REVENUE":
        return OCUserCountOrRevenueForm;
      case "PITCH_DECK":
        return PitchDeckForm;
      case "VC_EVIDENCE":
        return VCEvidenceForm;
      case "CAP_TABLE":
        return AwardCapTableForm;
      case "JOURNAL_ARTICLE":
        return JournalArticleWithLinksForm;
      case "PATENT":
        return PatentForm;
      case "INTERNAL_DOCS":
        return InternalDocuments;
      case "CUSTOMER_CONTRACT":
        return CustomerContractDocuments;
      case "ACADEMIC_PAPER":
        return AcademicPapersDocuments;
      case "ACADEMIC_CONTRACT_PROPOSAL":
        return AcademicContractProposalDocuments;
      case "FUNDING_SIGNATURE":
        return FundingDocuments;
      case "DISTINGUISHED_REPUTATION":
        return DistinguishedReputationDocuments;
      case "MAJOR_PUBLICATION_WORK":
        return MajorPublicationWorkLinks;
      case "PUBLICATION_PROMINENCE":
        return PublicationProminenceForm;
      case "JUDGING_DOCUMENTATION":
        return JudgingDocumentForm;
      case "AWARD_EVIDENCE":
        return AwardDocumentsForm;
      case "EVIDENCE_OF_SELECTIVITY":
        return AwardEvidenceOfSelectivityForm;
      case "ORIGINAL_CONTRIBUTIONS_SUMMARY":
        return OriginalContributionDescriptionForm;
      case "ORIGINAL_CONTRIBUTION_EVIDENCE":
        return OriginalContributionEvidenceForm;
      case "PRESS_SUMMARY":
        return PressSummaryForm;
      case "PRESS_ARTICLE":
        return PressArticleForm;
      case "GOOGLE_SCHOLAR_PAGE":
        return GoogleScholarArticleForm;
      case "EXPERT_LETTER":
        return ExpertLetterRenderer;
      case "AO_LETTER":
        return ExpertLetterRenderer;
      case "FILING_TYPE":
        return FilingTypeForm;
      case "COVER_PAGE":
        return OnboardingControllerCover;
      default:
        return NotFoundPage;
    }
  }, [currentExhibit]);
  const navigate = useNavigate();
  const completeWorkflow = async (nextPath: string) => {
    await savePayload();
    navigate(nextPath);
  };
  React.useEffect(() => {
    if (currentState === "completed") {
      completeWorkflow("/onboarding/beneficiary/complete");
    } else if (currentState === "followup") {
      completeWorkflow("/onboarding/beneficiary/followup");
    }
  }, [currentState, completeWorkflow]);

  if (!currentExhibit || !petition || !onboarding) {
    return (
      <WaitingSplash
        timeout
        onSkip={handleSkip}
        exitPath={"/onboarding/beneficiary"}
        onBack={handlePrev}
      />
    );
  }

  return (
    <SupportProvider
      screen={`Beneficiary Onboarding: ${currentModule?.type} - ${currentExhibit?.type}`}
    >
      <ReadOnlyModeProvider>
        <Form
          isSaving={isSaving}
          key={currentExhibit.id}
          onSkip={handleSkip}
          onSkipForNow={handleSkipForNow}
          onBack={handlePrev}
          onSubmit={handleNext}
          autoSave={handleSave}
          saveData={saveExhibitData}
          company={company}
          contact={contact}
          exhibit={currentExhibit}
          module={currentModule}
          petition={petition}
          onboarding={onboarding}
        />
      </ReadOnlyModeProvider>
    </SupportProvider>
  );
};
