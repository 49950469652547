import { useUpdatePetitionMutation } from "@codegen/index";
import { FilingType, UpdatePetitionByIdInput } from "@codegen/schema";
import { Button } from "@components/button";
import { useReadOnlyMode } from "@components/readOnlyModeProvider";
import Spinner from "@components/spinner";
import { zodResolver } from "@hookform/resolvers/zod";
import { CTAContainer } from "@pages/onboarding/shared/cta-container";
import { FieldsContainer } from "@pages/onboarding/shared/fields-container";
import { OnboardingContainer } from "@pages/onboarding/shared/onboarding-container";
import { OnboardingPageType } from "@pages/onboarding/types";
import { useDebounceCallback } from "@react-hook/debounce";
import { useLogError } from "@utils/error";
import React from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { z } from "zod";

export const FilingTypeFormSchema = z.object({
  filingType: z.string().min(1, "Please select a filing type"),
});

export type FilingTypeFormType = z.infer<typeof FilingTypeFormSchema>;
const changeOfStatusDescription = [
  "This process is for individuals already in the United States in a valid nonimmigrant status.",
  "It allows them to switch from one nonimmigrant visa category (e.g. H-1B visa to O-1 visa)  to another without leaving the country.",
  "If approved, the person's status changes seamlessly.",
  "Once you leave the U.S., you will have to apply for a visa stamp to enter the country (unless you’re Canadian! Canadians are visa-exempt.).",
];
const consularProcessDescription = [
  "This is typically for individuals outside the United States.",
  "Do you have upcoming travel? If you are currently in the U.S. but are planning to leave the U.S. during your visa process, you should select this option.",
  "The applicant must schedule an interview at a U.S. consulate in their home country or country of residence to apply for a visa.",
  "If you have been issued a U.S. visa recently, your consulate may waive the interview requirement, but you will need to send your passport for processing.",
  "If approved, you will receive a visa stamp in your passport, allowing you to enter the U.S. in the new visa category.",
];

const descriptionArray =
  consularProcessDescription.length >= changeOfStatusDescription.length
    ? consularProcessDescription
    : changeOfStatusDescription;
export const FilingTypeForm: React.FC<OnboardingPageType> = ({
  petition,
  onSubmit,
  autoSave,
  onBack,
  exhibit,
  onSkipForNow,
}) => {
  const methods = useForm({
    defaultValues: {
      filingType: petition.filingType,
    },
    resolver: zodResolver(FilingTypeFormSchema),
  });
  const { register, control } = methods;

  const { isReadOnly } = useReadOnlyMode();

  const watchedValues = useWatch({
    control,
  });

  const [updateFilingType, { loading: saving }] = useUpdatePetitionMutation();
  const logError = useLogError();

  const handleSave = async (data: FilingTypeFormType) => {
    try {
      const { errors: updateFilingTypeError } = await updateFilingType({
        variables: {
          input: {
            id: petition.id,
            petitionPatch: {
              filingType: data.filingType as FilingType,
            },
          } as UpdatePetitionByIdInput,
        },
      });

      if (updateFilingTypeError != null) {
        throw updateFilingTypeError;
      }

      autoSave(data);
    } catch (exception) {
      logError(exception, {
        message: "Error saving contact",
        data,
      });
    }
  };

  const debouncedSave = useDebounceCallback(handleSave, 300);

  React.useEffect(() => {
    debouncedSave(watchedValues);
  }, [watchedValues, debouncedSave]);

  const handleSubmit = async (data: FilingTypeFormType) => {
    try {
      const { errors: updateFilingTypeError } = await updateFilingType({
        variables: {
          input: {
            id: petition.id,
            petitionPatch: {
              filingType: data.filingType as FilingType,
            },
          } as UpdatePetitionByIdInput,
        },
      });

      if (updateFilingTypeError != null) {
        throw updateFilingTypeError;
      }

      onSubmit(data);
    } catch (exception) {
      logError(exception, {
        message: "Error saving contact",
        data,
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <OnboardingContainer
        title={`Filing Type`}
        subtitle="The main difference between change of status and consular processing for U.S. work visas lies in where and how your visa is obtained."
        cardTitle="Please select a filing type"
        progress={0}
        onSubmit={handleSubmit}
        exhibit={exhibit}
        name={`filing-type-form`}
      >
        <FieldsContainer status={""}>
          <table className="shadow-sm rounded text-sm mt-3 w-full text-left rtl:text-right text-gray-500 dark:text-gray-400 bg-white">
            <thead className=" text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400 bg-gray-50">
              <tr className="">
                <th className="p-3 border">
                  <label
                    className="flex items-center gap-2"
                    htmlFor="changeOfStatusRadio"
                  >
                    <input
                      type="radio"
                      id="changeOfStatusRadio"
                      value={FilingType.ChangeOfStatus}
                      {...register("filingType")}
                      disabled={isReadOnly}
                      title="Change of Status filing type"
                    />
                    Change of Status
                  </label>
                </th>
                <th className="p-3 border">
                  <label
                    className="flex items-center gap-2"
                    htmlFor="consularProcessingRadio"
                  >
                    <input
                      type="radio"
                      id="consularProcessingRadio"
                      value={FilingType.ConsularProcessing}
                      {...register("filingType")}
                      disabled={isReadOnly}
                      title="Consular Processing filing type"
                    />
                    Consular Processing
                  </label>
                </th>
              </tr>
            </thead>
            <tbody className="">
              {descriptionArray.map((_, index) => (
                <tr key={`file-type-${index}`}>
                  <td className="p-3 border align-top">
                    {changeOfStatusDescription[index]}
                  </td>
                  <td className="p-3 border align-top">
                    {consularProcessDescription[index]}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </FieldsContainer>
        <CTAContainer onBack={onBack} onSkip={() => onSkipForNow?.()}>
          <Button
            variant="accent"
            className="ml-auto text-md rounded-sm px-7 py-5"
            disabled={saving}
            type="submit"
          >
            {saving ? <Spinner /> : "Next"}
          </Button>
        </CTAContainer>
      </OnboardingContainer>
    </FormProvider>
  );
};

export default FilingTypeForm;
