import { useAllCompanyDocsQuery } from "@codegen/index";
import { CompanyDocumentType } from "@codegen/schema";
import { Button } from "@components/button";
import Spinner from "@components/spinner";
import { EmployerOnboardingContainer } from "@pages/onboarding/employer/forms/employer-onboarding-container";
import { CompanyDocumentUploader } from "@pages/onboarding/shared/company-document-upload";
import { CTAContainer } from "@pages/onboarding/shared/cta-container";
import { useLogError } from "@utils/error";
import { useJwt } from "@utils/hooks";
import { useEffect, useMemo, useState } from "react";
import { useEmployerOnboarding } from "../../employer-onboarding";
import { useReadOnlyMode } from "@components/readOnlyModeProvider";

export const CorporateDocumentsForm = () => {
  const { onSubmit, company, onBack, onSkip, onSave } = useEmployerOnboarding();
  const { isReadOnly } = useReadOnlyMode();
  const [aoiUploaded, setAOIUploaded] = useState(false);
  const [taxIdUploaded, setTaxIdUploaded] = useState(false);
  const logError = useLogError();

  const token = useJwt();

  const { data, loading, refetch } = useAllCompanyDocsQuery({
    variables: {
      companyId: company?.value ?? "",
    },
  });

  const articlesOfIncorporation = useMemo(() => {
    if (data == null || data.allCompanyDocuments?.nodes == null) {
      return;
    }

    const res = [];

    for (const doc of data.allCompanyDocuments.nodes) {
      if (doc?.fileByFileId?.id == null || doc?.fileByFileId?.deleted) {
        continue;
      }

      if (doc.type !== CompanyDocumentType.ArticlesOfIncorporation) continue;
      res.push({
        id: doc.fileByFileId.id ?? "",
        name: doc.fileByFileId.name,
      });
    }
    return res;
  }, [data]);

  const taxIdEINDocument = useMemo(() => {
    if (data == null || data.allCompanyDocuments?.nodes == null) {
      return;
    }

    const res = [];

    for (const doc of data.allCompanyDocuments.nodes) {
      if (doc?.fileByFileId?.id == null || doc?.fileByFileId?.deleted) {
        continue;
      }

      if (doc.type !== CompanyDocumentType.TaxIdFeinDocument) continue;
      res.push({
        id: doc.fileByFileId.id ?? "",
        name: doc.fileByFileId.name,
      });
    }
    return res;
  }, [data]);

  useEffect(() => {
    if (
      taxIdEINDocument &&
      articlesOfIncorporation &&
      data !== null &&
      data?.allCompanyDocuments?.nodes !== null
    )
      onSave({
        data: articlesOfIncorporation
          ?.map((d) => d.id)
          .concat((taxIdEINDocument ?? [])?.map((d) => d.id)),
        key: "corporateDocsFileIds",
      });
  }, [taxIdEINDocument, articlesOfIncorporation, onSave, data]);

  useEffect(() => {
    if (taxIdEINDocument == null) return;
    if (taxIdEINDocument.length > 0) setTaxIdUploaded(true);
  }, [taxIdEINDocument]);

  useEffect(() => {
    if (articlesOfIncorporation == null) return;
    if (articlesOfIncorporation.length > 0) setAOIUploaded(true);
  }, [articlesOfIncorporation]);

  const isValid =
    articlesOfIncorporation != null &&
    articlesOfIncorporation.length > 0 &&
    taxIdEINDocument != null &&
    taxIdEINDocument.length > 0;

  return (
    <EmployerOnboardingContainer
      title="Corporate Documents: The Building Blocks"
      subtitle=""
      cardTitle={"Please upload the following:"}
      progress={0}
      name="corporate_docs"
    >
      <div className="flex flex-col gap-y-4 w-[500px]">
        <div className="flex flex-col gap-y-4 w-[500px]">
          <div>
            <Button variant="link" className="pl-0">
              Articles of Incorporation
            </Button>
            {articlesOfIncorporation == null && <Spinner />}
            {articlesOfIncorporation != null && !loading && (
              <CompanyDocumentUploader
                key={`articles-of-incorporation`}
                fileType={CompanyDocumentType.ArticlesOfIncorporation}
                data={articlesOfIncorporation}
                loading={loading}
                disabled={isReadOnly}
                refetch={refetch}
                company={company}
              />
            )}
          </div>

          <div>
            <Button variant="link" className="pl-0">
              EIN Assignment Letter
            </Button>

            {taxIdEINDocument == null && <Spinner />}
            {taxIdEINDocument != null && !loading && (
              <CompanyDocumentUploader
                key={`ein-document`}
                fileType={CompanyDocumentType.TaxIdFeinDocument}
                data={taxIdEINDocument}
                loading={loading}
                disabled={isReadOnly}
                refetch={refetch}
                company={company}
              />
            )}
          </div>
        </div>
      </div>
      <CTAContainer onBack={onBack} onSkip={onSkip}>
        <Button
          variant="accent"
          className="ml-auto text-md rounded-sm px-7 py-5"
          disabled={!isValid || loading}
          onClick={() =>
            onSubmit({
              data: articlesOfIncorporation
                ?.map((d) => d.id)
                .concat((taxIdEINDocument ?? [])?.map((d) => d.id)),
              key: "corporateDocsFileIds",
            })
          }
        >
          Next
        </Button>
      </CTAContainer>
    </EmployerOnboardingContainer>
  );
};
