import * as Dropdown from "@radix-ui/react-dropdown-menu";
import { DropdownButton } from "../../../ui/Dropdown";
import { Icon } from "../../../ui/Icon";
import { Surface } from "../../../ui/Surface";
import { Toolbar } from "../../../ui/Toolbar";

export type AIDropdownProps = {
  onGenerateDescription: () => void;
};

export const AIDropdown = ({ onGenerateDescription }: AIDropdownProps) => {
  return (
    <Dropdown.Root>
      <Dropdown.Trigger asChild>
        <Toolbar.Button
          className="text-purple-500 hover:text-purple-600 active:text-purple-600 dark:text-purple-400 dark:hover:text-purple-300 dark:active:text-purple-400"
          activeClassname="text-purple-600 hover:text-purple-600 dark:text-purple-400 dark:hover:text-purple-200"
        >
          <Icon name="Sparkles" className="mr-1" />
          AI Tools
          <Icon name="ChevronDown" className="w-2 h-2 ml-1" />
        </Toolbar.Button>
      </Dropdown.Trigger>
      <Dropdown.Content asChild>
        <Surface className="p-2 min-w-[10rem] z-10">
          <Dropdown.Item onClick={onGenerateDescription}>
            <DropdownButton>
              <Icon name="CircleSlash" />
              Generate Description
            </DropdownButton>
          </Dropdown.Item>
        </Surface>
      </Dropdown.Content>
    </Dropdown.Root>
  );
};
