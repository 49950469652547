import { onboardingMachine } from "@pages/onboarding/beneficiary/machines";
import { createActorContext } from "@xstate/react";
import * as React from "react";

export const BeneficiaryOnboardingContext =
  createActorContext(onboardingMachine);
export const OnboardingStateMachineProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <BeneficiaryOnboardingContext.Provider>
      {children}
    </BeneficiaryOnboardingContext.Provider>
  );
};

export const useStateMachine = () => BeneficiaryOnboardingContext;
