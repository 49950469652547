import { useAllCompaniesQuery } from "@codegen/index";
import { useMemo } from "react";
import { Combobox } from "./combobox";

const CompanySelector = (props: {
  selectedId?: string;
  onSelect?: (type: string) => void;
  className?: string;
  disabled?: boolean;
}) => {
  const { selectedId, onSelect, disabled } = props;
  const { data } = useAllCompaniesQuery({ pollInterval: 10000 });

  const opts = useMemo(() => {
    const opts = [];

    for (const contact of data?.allCompanies?.nodes ?? []) {
      if (contact == null) continue;
      opts.push({
        value: contact.id ?? "",
        label: contact.dbaName,
      });
    }
    return opts;
  }, [data?.allCompanies?.nodes]);

  return (
    <Combobox
      options={opts}
      defaultValue={(selectedId as string) ?? ""}
      onSelect={(e) => onSelect?.(e)}
      placeholder="Search company"
      className={props.className}
      disabled={disabled}
    />
  );
};

export default CompanySelector;
