import Spinner from "@components/spinner";
import { cn } from "@utils/cn";

export const FieldsContainer = (props: {
  children: React.ReactNode;
  status: string;
  errorMessage?: string;
  form?: any;
  className?: string;
}) => {
  const { children, status, className, errorMessage } = props;

  return (
    <div className={cn("flex flex-col gap-3 overflow-x-visible", className)}>
      {children}
      <div className="pt-5 gap-x-2 flex flex-row items-center">
        {status === "error" && (
          <p className="text-sm text-red-500">
            {errorMessage || "There was an error submitting your information."}
          </p>
        )}
        {status === "loading" && <Spinner />}
      </div>
    </div>
  );
};
