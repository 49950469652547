import { useOnboardingByPetitionIdQuery } from "@codegen/index";
import { Onboarding, PetitionFragment } from "@codegen/schema";
import { useLogError } from "@utils/error";
import { usePetitions } from "@utils/hooks";
import { StrategyModule } from "@utils/types";
import * as React from "react";
import { useNavigate } from "react-router-dom";

export const BeneficiaryOnboardingWorkflowContext = React.createContext<{
  modules: StrategyModule[];
  currentModuleIndex?: number;
  currentExhibitIndex?: number;
  loading: boolean;
  onCurrentModuleComplete: () => void;
  onPrevious: () => void;
  refetchOnboarding: () => void;
  initializeContext: (params: {
    modules?: StrategyModule[];
    moduleIndex?: number;
    exhibitIndex?: number;
  }) => void;
  petition?: PetitionFragment | null;
  onboarding?: Pick<Onboarding, "id" | "modules" | "status"> | null;
}>({
  modules: [],
  currentModuleIndex: 0,
  currentExhibitIndex: 0,
  onCurrentModuleComplete: () => {
    throw "Not implemented";
  },
  onPrevious: () => {
    throw "Not implemented";
  },
  initializeContext: () => {
    throw "Not implemented";
  },
  refetchOnboarding: () => {
    throw "Not implemented ";
  },
  loading: false,
});

export const useBeneficiaryWorkflowContext = () => {
  return React.useContext(BeneficiaryOnboardingWorkflowContext);
};

export const BeneficiaryOnboardingWorkflowProvider: React.FC<{
  children: React.ReactNode;
  petitionId: string;
}> = ({ children, petitionId }) => {
  const [currentModuleIndex, setCurrentModuleIndex] = React.useState<
    number | undefined
  >();
  const [currentExhibitIndex, setCurrentExhibitIndex] = React.useState<
    number | undefined
  >();
  const [allModules, setAllModules] = React.useState<StrategyModule[]>([]);
  const navigate = useNavigate();
  const {
    data: petitionData,
    error,
    loading: petitionLoading,
  } = usePetitions();
  const handleNext = React.useCallback(() => {}, [
    currentModuleIndex,
    allModules.length,
    setCurrentModuleIndex,
    navigate,
  ]);

  const handlePrev = React.useCallback(() => {}, [
    navigate,
    currentModuleIndex,
    setCurrentModuleIndex,
  ]);

  const onCurrentModuleComplete = React.useCallback(() => {
    handleNext();
  }, [handleNext]);

  const initializeContext = React.useCallback(
    ({
      modules,
      moduleIndex,
      exhibitIndex,
    }: {
      modules?: StrategyModule[];
      moduleIndex?: number;
      exhibitIndex?: number;
    }) => {
      if (modules) {
        setAllModules(modules);
      }
      if (typeof moduleIndex === "number") {
        setCurrentModuleIndex(moduleIndex);
      }
      if (typeof exhibitIndex === "number") {
        setCurrentExhibitIndex(exhibitIndex);
      }
    },
    [setAllModules, setCurrentModuleIndex, setCurrentExhibitIndex]
  );

  const {
    data: onboardingData,
    error: onboardingDataError,
    refetch,
    loading: onboardingLoading,
  } = useOnboardingByPetitionIdQuery({
    variables: {
      petitionId,
    },
  });

  const logError = useLogError();
  React.useEffect(() => {
    if (onboardingDataError) {
      logError(onboardingDataError);
    }
  }, [onboardingDataError]);

  React.useEffect(() => {
    if (onboardingDataError) {
      logError(error);
    }
  }, [error]);

  React.useEffect(() => {
    const moduleJSON = onboardingData?.allOnboardings?.nodes?.[0]?.modules;
    const modules: StrategyModule[] = moduleJSON
      ? JSON.parse(moduleJSON)
      : null;
    if (modules) {
      initializeContext({ modules: modules });
    }
  }, [onboardingData, initializeContext]);

  const refetchOnboarding = React.useCallback(() => {
    refetch();
  }, [refetch]);

  return (
    <BeneficiaryOnboardingWorkflowContext.Provider
      value={{
        modules: allModules,
        currentModuleIndex,
        currentExhibitIndex,
        onCurrentModuleComplete,
        onPrevious: handlePrev,
        initializeContext,
        petition: petitionData?.petitions.find((p) => p.id === petitionId),
        onboarding: onboardingData?.allOnboardings?.nodes?.[0],
        refetchOnboarding,
        loading: petitionLoading || onboardingLoading,
      }}
    >
      {children}
    </BeneficiaryOnboardingWorkflowContext.Provider>
  );
};
