import {
  useCreateCompanyMutation,
  useCreateEntityMutation,
  useCreateUserMutation,
} from "@codegen/index";
import { EntityTypes } from "@codegen/schema";
import { X } from "@phosphor-icons/react";
import { entityOptions } from "@utils/entities";
import { useCallback, useMemo, useState } from "react";
import ArchetypeSelectorMemo from "./archetypeSelector";
import { Button } from "./button";
import { Combobox } from "./combobox";
import { Input } from "./input";
import Modal from "./modal";
import Spinner from "./spinner";

const EntitiesCreatorModal = (props: {
  onClose: () => void;
  onSubmit: (entity: { id: string; type: EntityTypes; name: string }) => void;
  entityType?: EntityTypes;
}) => {
  const [selectedEntityType, setSelectedEntityType] = useState<EntityTypes>(
    props.entityType ?? EntityTypes.User
  );
  const [createStatus, setCreateStatus] = useState<
    "idle" | "creating" | "error"
  >("idle");

  const [name, setName] = useState<string>("");
  const [userArchetype, setUserArchetype] = useState<string>("");

  const entityTypeSelector = useMemo(
    () => (
      <Combobox
        placeholder="Select entity type"
        options={
          entityOptions as {
            label: string;
            value: string;
          }[]
        }
        defaultValue={selectedEntityType}
        onSelect={(x: string) => setSelectedEntityType(x as EntityTypes)}
        disabled={props.entityType != null}
      />
    ),

    [props.entityType, selectedEntityType]
  );

  const [createUserMutation] = useCreateUserMutation();
  const [createCompanyMutation] = useCreateCompanyMutation();
  const [createEntityMutation] = useCreateEntityMutation();

  const doCreateEntity = useCallback(async () => {
    if (name.trim() === "") return;
    setCreateStatus("creating");

    if (selectedEntityType === EntityTypes.User) {
      const { data, errors } = await createUserMutation({
        variables: {
          input: {
            user: {
              fullName: name,
              archetype: userArchetype,
            },
          },
        },
        refetchQueries: ["AllContacts"],
      });

      if (errors != null) {
        console.error(errors);
        setCreateStatus("error");
        return;
      }

      if (
        (data?.createUser?.user?.additionalEntitiesByUserId.nodes ?? [])
          .length > 0
      ) {
        props.onSubmit({
          id:
            data?.createUser?.user?.additionalEntitiesByUserId.nodes[0]?.id ??
            "",
          type: EntityTypes.User,
          name: name,
        });
        return;
      }

      setCreateStatus("idle");
      props.onClose();
      return;
    }

    if (selectedEntityType === EntityTypes.Company) {
      const { data, errors } = await createCompanyMutation({
        variables: {
          input: {
            company: {
              dbaName: name,
            },
          },
        },
        refetchQueries: ["AllCompanies"],
      });

      if (errors != null) {
        console.error(errors);
        setCreateStatus("error");
        return;
      }

      if (
        (
          data?.createCompany?.company?.additionalEntitiesByCompanyId.nodes ??
          []
        ).length > 0
      ) {
        props.onSubmit({
          id:
            data?.createCompany?.company?.additionalEntitiesByCompanyId.nodes[0]
              ?.id ?? "",
          type: EntityTypes.Company,
          name: name,
        });
        return;
      }

      setCreateStatus("idle");
      props.onClose();
      return;
    }

    const { data, errors } = await createEntityMutation({
      variables: {
        input: {
          additionalEntity: {
            name: name,
            type: selectedEntityType,
          },
        },
      },
    });

    if (errors != null) {
      console.error(errors);
      setCreateStatus("error");
      return;
    }

    setCreateStatus("idle");
    return props.onSubmit({
      id: data?.createAdditionalEntity?.additionalEntity?.id ?? "",
      type: selectedEntityType,
      name: name,
    });
  }, [
    createCompanyMutation,
    createEntityMutation,
    createUserMutation,
    name,
    props,
    selectedEntityType,
    userArchetype,
  ]);

  return (
    <Modal>
      <div className="w-[700px] flex flex-col justify-center">
        <div className="flex flex-col gap-y-4">
          <div className="flex flex-row items-center">
            <h1 className="text-xl">New Entity</h1>
            <Button
              className="ml-auto"
              variant={"link"}
              size={"sm"}
              onClick={props.onClose}
            >
              <X size={16} />
            </Button>
          </div>
          <div className="flex flex-row gap-x-3 items-center">
            <h2 className="font-semibold">Type</h2>
            {entityTypeSelector}
          </div>

          <div className="flex flex-col gap-y-1">
            <h2 className="font-semibold">
              {selectedEntityType === EntityTypes.User
                ? "Full Name"
                : selectedEntityType === EntityTypes.Company
                ? "DBA Name"
                : "Name"}
            </h2>
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder={
                selectedEntityType === EntityTypes.User
                  ? "John Doe"
                  : selectedEntityType === EntityTypes.Company
                  ? "Acme Inc"
                  : "Name"
              }
            />
          </div>

          {selectedEntityType === EntityTypes.User && (
            <div className="flex flex-col gap-y-1">
              <h2 className="font-semibold">Archetype</h2>
              <ArchetypeSelectorMemo
                selectedType={userArchetype}
                onSelect={setUserArchetype}
                className="bg-white"
              />
            </div>
          )}

          <div className="w-full flex flex-row gap-x-2">
            <Button
              variant={"accent"}
              disabled={name.trim() === ""}
              onClick={doCreateEntity}
              className="ml-auto"
            >
              Create Entity
            </Button>
            {createStatus === "creating" && <Spinner />}
            {createStatus === "error" && (
              <div className="text-red-500">Error creating entity</div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EntitiesCreatorModal;
