import { usePetitionSelectorQuery } from "@codegen/index";
import { PetitionStatus } from "@codegen/schema";
import { CaretDown } from "@phosphor-icons/react";
import { formatVisaClass } from "@utils/parsers";
import { Command } from "cmdk";
import React, { useMemo } from "react";
import { Input } from "./input";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";

export const PetitionSelectAutoComplete = (props: {
  onSelect: (petitionId: string) => void;
  value: string;
  filters?: {
    statuses?: PetitionStatus[];
  };
}) => {
  const { onSelect, value } = props;
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const [showActiveOnly, setShowActiveOnly] = React.useState(true);
  const listRef = React.useRef(null);

  const [open, setOpen] = React.useState(false);

  const { data, loading } = usePetitionSelectorQuery();
  const activeStatuses = [
    PetitionStatus.PendingDocs,
    PetitionStatus.InAttorneyReview,
    PetitionStatus.Submitted,
    PetitionStatus.PrintShipRequested,
    PetitionStatus.Shipped,
  ];
  const options = useMemo(() => {
    if (loading) return [];
    if (data == null) return [];
    if (data.allPetitions?.nodes == null) return [];

    const res = [];

    for (const p of data.allPetitions.nodes) {
      if (p == null) continue;
      if (p.id == null) continue;
      if (showActiveOnly && !activeStatuses.includes(p.status)) continue;

      res.push({
        value: p.id,
        label: {
          id: p.id,
          dbaName: p.companyByPetitionerId?.dbaName ?? "",
          fullName: p.userByBeneficiaryId?.fullName ?? "",
          visaClass: formatVisaClass(p.visaClass),
        },
      });
    }

    return res;
  }, [data, loading, showActiveOnly]);

  const selectedLabel = useMemo(
    () => options.find((option) => option.value === value)?.label,
    [options, value]
  );

  React.useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  return (
    <div>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <div
            role="combobox"
            aria-expanded={open}
            className="w-[500px] justify-between items-center border border-gray-300 rounded-md p-2 flex flex-row gap-x-2 cursor-pointer"
          >
            {selectedLabel != null ? (
              <div className="w-full flex flex-row items-center">
                <div className="font-semibold">
                  {selectedLabel?.fullName} [{selectedLabel?.id}]
                </div>
                <div className="flex items-center ml-auto gap-x-3">
                  <div className="font-semibold text-gray-400 ml-auto">
                    {selectedLabel?.dbaName}
                  </div>
                  <CaretDown
                    size={12}
                    weight="bold"
                    className="ml-auto text-gray-400"
                  />
                </div>
              </div>
            ) : (
              "Select a petition..."
            )}
          </div>
        </PopoverTrigger>
        <PopoverContent className="flex flex-col w-[500px] p-2 gap-y-3 overflow-scroll max-h-[200px]">
          <Command
            value={value}
            className="w-full flex flex-col gap-y-1"
            filter={(v, search) => {
              const label = options.find((o) => o.value === v)?.label;
              if (label == null) return 0;

              if (
                label.visaClass.toLowerCase().includes(search.toLowerCase())
              ) {
                return 1;
              }

              if (label.fullName.toLowerCase().includes(search.toLowerCase())) {
                return 1;
              }

              if (label.dbaName.toLowerCase().includes(search.toLowerCase())) {
                return 1;
              }

              if (v.toLowerCase().includes(search.toLowerCase())) {
                return 0.5;
              }

              return 0;
            }}
          >
            <Command.Input
              ref={inputRef}
              autoFocus
              placeholder="Select a petition..."
            />

            <Command.List ref={listRef}>
              <Command.Empty>No results found.</Command.Empty>
              {options.map((v, idx) => (
                <Command.Item
                  key={idx}
                  value={v.value}
                  onSelect={(currentValue) => {
                    onSelect(currentValue === value ? "" : currentValue);
                    setOpen(false);
                  }}
                  className="hover:bg-gray-100 hover:cursor-pointer"
                >
                  <div className="w-full flex flex-row items-center gap-x-2">
                    <div className="text-gray-400 font-semibold">
                      {v.label.visaClass}
                    </div>
                    <div className="font-semibold">
                      {v.label.fullName} [{v.label.id}]
                    </div>
                    <div className="text-gray-400 font-semibold ml-auto">
                      {v.label.dbaName}
                    </div>
                  </div>
                </Command.Item>
              ))}
            </Command.List>
          </Command>
        </PopoverContent>
      </Popover>
      <label
        htmlFor="activePetitionOnly"
        className="flex justify-start items-center"
      >
        <Input
          id="activePetitionOnly"
          className="h-3 w-auto mr-2"
          checked={showActiveOnly}
          name="activePetitionOnly"
          type="checkbox"
          onChange={(e) => setShowActiveOnly(e.target.checked)}
        />
        <span>Show only active petitions</span>
      </label>
    </div>
  );
};
